// mutation types
export const SET_PREVIEW = "device/setPreview";
export const SET_TEMPLATE_PREVIEW = "device/setTemplatePreview";

const state = {
  preview: true,
  templatePreview: true,
};

const getters = {
  currentPreview(state) {
    return state.preview;
  },
  currentTemplatePreview(state) {
    return state.templatePreview;
  },
};

const mutations = {
  [SET_PREVIEW](state, payload) {
    if (payload == "show") {
      state.preview = true;
      return;
    }
    state.preview = !state.preview;
  },
  [SET_TEMPLATE_PREVIEW](state) {
    state.templatePreview = !state.templatePreview;
  },
};

export default {
  state,
  getters,
  mutations,
};
