<template>
  <v-app id="inspire">
    <router-view />
    <!-- <v-snackbars :objects.sync="snackList" bottom right /> -->

    <v-snackbar v-model="snackbarModel" :color="snackbar.color" right :timeout="5000">
      {{ snackbar.text }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import VSnackbars from "v-snackbars";
import { mapState, mapGetters } from "vuex";

export default {
  name: "qrcodemactic",
  components: {
    "v-snackbars": VSnackbars,
  },
  data() {
    return {
      snackbarModel: false,
      snackQueue: [],
      snackbar: {
        text: "",
        color: "green",
      },
    };
  },
  computed: {
    ...mapState({
      snackState: (state) => state.snackbar.snackList,
      loginState: (state) => state.auth.isAuthenticated,
    }),
    ...mapGetters(["currentLeaveConfirmed", "currentSnackList"]),
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
  },
  watch: {
    breakpoint(val) {},
  },
  mounted() {
    this.snackList = this.snackState;
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    this.$router.beforeEach((to, from, next) => {
      this.$store.commit("auth/setRouteTo", to.path);
      if (!this.currentLeaveConfirmed) {
        this.$store.commit("auth/setLeaveDialog", true);
        next(from);
      } else {
        next();
      }
    });

    window.addNewSnack = this.addNewSnack;
    this.addHotjarScript();
    this.onError();

    const { name, os, version } = window.platform;
    this.$sendMetricaEvent("USER_AGENT", {
      name: name,
      os: os,
      version: version,
    });
  },
  methods: {
    addNewSnack(snack) {
      this.snackbar.text = snack.message;
      this.snackbar.color = snack.color;
      this.snackbarModel = true;
      // this.snackList.push(snack);
      // setTimeout(() => {
      //   this.snackList.unshift();
      // }, snack.timeout);
    },
    addHotjarScript() {
      if (!window.location.href.includes("panel.qrcodematic.com")) return;

      const newScript = document.createElement("script");
      newScript.innerHTML = `
      (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
        h._hjSettings = { hjid: 3603596, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script'); r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
      `;
      document.head.append(newScript);
    },
    onError() {
      window.addEventListener("error", (event) => {
        this.$sendMetricaEvent("js_error", {
          message: event.message,
        });
      });
    },
  },
};
</script>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
// @import "~@fortawesome/fontawesome-free/css/all.css";
// @import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/fontawesome-6.5.2/css/all.css";
// @import "assets/plugins/fontawesome6/css/brands.css";
// @import "assets/plugins/fontawesome6/css/duotone.css";
// @import "assets/plugins/fontawesome6/css/fontawesome.css";
// @import "assets/plugins/fontawesome6/css/light.css";
// @import "assets/plugins/fontawesome6/css/regular.css";
// @import "assets/plugins/fontawesome6/css/sharp-solid.css";
// @import "assets/plugins/fontawesome6/css/solid.css";
// @import "assets/plugins/fontawesome6/css/svg-with-js.css";
// @import "assets/plugins/fontawesome6/css/thin.css";
// @import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.main-sheet.v-sheet.theme--light.elevation-1 {
  border: 1px solid #e7e7e7 !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  box-shadow: 1px 1px 9px #e9e9e9 !important;
}
#kt_content {
  background: #f2f6f9 !important;
}
.d-flex.flex-column.align-start.v-sheet.theme--light.elevation-1 {
  border-radius: 10px !important;
  box-shadow: 1px 1px 9px #e9e9e9 !important;
}
.d-flex.flex-column.v-card.v-card--link.v-sheet.theme--light {
  box-shadow: 1px 1px 9px #e9e9e9 !important;
}
// .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
// .btn.btn-primary:focus:not(.btn-text),
// .btn.btn-primary.focus:not(.btn-text) {
//     background-color: #3699ff !important;
// }
.v-application .error--text {
  color: #3699ff !important;
  caret-color: #3699ff !important;
}
</style>
