const ID_TOKEN_KEY = "id_token"
const ACCESS_TOKEN = "accessToken"
const USER = "user"

// Token //
export const getToken = () => {
	return window.localStorage.getItem(ID_TOKEN_KEY)
}

export const saveToken = (token) => {
	window.localStorage.setItem(ID_TOKEN_KEY, token)
}

// User //
export const getUser = () => {
	return window.localStorage.getItem(USER)
}
export const saveUser = (user) => {
	window.localStorage.setItem(USER, user)
}

// Access Token için //
export const getAccessToken = () => {
	return window.localStorage.getItem(ACCESS_TOKEN)
}

export const saveAccessToken = (token) => {
	window.localStorage.setItem(ACCESS_TOKEN, token)
}
// Access Token için //

export const destroyToken = () => {
	window.localStorage.removeItem(ID_TOKEN_KEY)
}

export default { getToken, saveToken, destroyToken, getUser, saveUser }
