import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import AssignGlobals from "@/core/config/globals/index";
// import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
// import { VERIFY_AUTH } from "@/core/services/store/auth.module";
// import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
// import "popper.js";
// import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
// import ClipboardJS from "clipboard";
// window.ClipboardJS = ClipboardJS;
import * as VueGoogleMaps from "vue2-google-maps-withscopedautocomp";
import VueGeolocation from "vue-browser-geolocation";

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";

import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "@/core/plugins/prototypes";
import "@/core/plugins/platform";
import VueTour from "vue-tour";
import LottieAnimation from "lottie-web-vue";

require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);
Vue.use(LottieAnimation);

// Globals
AssignGlobals(Vue);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDlwi8gS4LSvYZj7SZa2fWh1sOqh-aNa6Q",
    autobindAllEvents: false,
    libraries: "places, drawing,visualization",
    // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
});
Vue.use(VueGeolocation);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
