import ErrorHandler from "../../services/errorHandle.service";
import store from "@/core/services/store/index.js";

function assignGlobals(Vue) {
  const globalVars = {
    baseApiUrl: process.env.API_BASE_URL,
    baseAmazonUrl: process.env.AMAZON_URL,
    qrcodeBaseUrl: process.env.QRCODE_BASE_URL,
    gLoaderIcon: "https://vue.qrcodematic.com/loadings/azure-round-loader.svg",
    gPlaceholderImage: "https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg",
    errorHandler: ErrorHandler,
    sleep: (ms) => {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    replaceLanguageKey(key, getShort) {
      const keyList = [
        ["en", "english"],
        ["tr", "turkish"],
        ["ge", "german"],
        ["ru", "russian"],
      ];
      const bcpLanguageTags = [
        ["ar-SA", "arabic"],
        ["bn-BD", "bangla"],
        ["bn-IN", "bangla"],
        ["cs-CZ", "czech"],
        ["da-DK", "danish"],
        ["de-AT", "german"],
        ["de-CH", "german"],
        ["de-DE", "german"],
        ["el-GR", "greek"],
        ["en-AU", "english"],
        ["en-CA", "english"],
        ["en-GB", "english"],
        ["en-IE", "english"],
        ["en-IN", "english"],
        ["en-NZ", "english"],
        ["en-US", "english"],
        ["en-ZA", "english"],
        ["es-AR", "spanish"],
        ["es-CL", "spanish"],
        ["es-CO", "spanish"],
        ["es-ES", "spanish"],
        ["es-MX", "spanish"],
        ["es-US", "spanish"],
        ["fi-FI", "finnish"],
        ["fr-BE", "french"],
        ["fr-CA", "french"],
        ["fr-CH", "french"],
        ["fr-FR", "french"],
        ["he-IL", "hebrew"],
        ["hi-IN", "hindi"],
        ["hu-HU", "hungarian"],
        ["id-ID", "indonesian"],
        ["it-CH", "italian"],
        ["it-IT", "italian"],
        ["ja-JP", "japanese"],
        ["ko-KR", "korean"],
        ["nl-BE", "dutch"],
        ["nl-NL", "dutch"],
        ["no-NO", "norwegian"],
        ["pl-PL", "polish"],
        ["pt-BR", "portuguese"],
        ["pt-PT", "portuguese"],
        ["ro-RO", "romanian"],
        ["ru-RU", "russian"],
        ["sk-SK", "slovak"],
        ["sv-SE", "swedish"],
        ["ta-IN", "tamil"],
        ["ta-LK", "tamil"],
        ["th-TH", "thai"],
        ["tr-TR", "turkish"],
        ["zh-CN", "chinese"],
        ["zh-HK", "chinese"],
        ["zh-TW", "chinese"],
      ];

      const val = [...keyList, ...bcpLanguageTags].find((e) => e.some((_key) => _key.includes(key.toLowerCase())));

      if (getShort) return val ? val[0] : key;

      return val ? val[1] : key;
    },
    openVideoTutorial(type, field) {
      const videoTutorial = store.getters.getVideoTutorial;

      const embedUrl = videoTutorial?.[type]?.[field] ?? "https://www.youtube.com/embed/1Wd9hDnkbQM";
      const payload = {
        vHtml: `<div style="width: 800px; border-radius: 10px">
        <section style="height: 50px; width: 100%; position: relative; background: var(--v-primary-base); border-radius: 10px 10px 0 0; padding: 1rem">
          <div style="position: absolute; width: 100%; left: 0; top: -15px" class="d-flex justify-content-center">
            <div style="background: #fff; color: var(--v-primary-base); width: 30px; height: 30px; border-radius: 50%; text-align: center">
              <i class="fa-brands fa-youtube fa-xl" style="margin-top: 14px;font-size: 15px;"></i>
            </div>
          </div>
          <h1 class="text-h5 white--text text-center">${i18n.t("how_it_works")}</h1>
        </section>

        <section class="d-flex flex-column justify-content-center align-items-center" style="background: #fff;padding: 1rem; border-radius: 0 0 10px 10px; position: relative;">
        <iframe width="800" height="500" src="${embedUrl}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <button id="alert-callback" style="width: 100px; height: 25px; background: var(--v-error-base); color: #fff; border-radius: 5px; margin-top: 1rem;">
            ${i18n.t("CLOSE")}
          </button>
        </section>
      </div>`,
        isVCard: false,
        isPersistant: false,
      };
      store.dispatch("util/alert", payload);
    },

    sendMetricaEvent(eventName, eventData) {
      ym(86330776, "reachGoal", eventName, eventData);
      gtag("event", eventName, eventData);
    },
    metrica: {
      qrCode: {
        mountedHook(that) {
          if (!that) return;
          // register expension panel clicks
          that.$refs.expansionPanels.$children.forEach((panel) => {
            panel.$el.addEventListener("CLICK", (event) => {
              const eventData = {
                click: panel.$el.id,
              };
              window.ym(86330776, "reachGoal", that.$route.name, eventData);
              window.gtag("event", that.$route.name, eventData);
            });
          });
          // start page timer
          that.pageStayTimeInSeconds = 0;
          that.pageTimer = setInterval(() => {
            that.pageStayTimeInSeconds += 1;
          }, 1000);
        },
        beforeDestroyHook(that) {
          if (!that) return;
          // send total page stay time
          const eventData = {
            page: that.$route.name,
            totalTimeInSeconds: that.pageStayTimeInSeconds,
          };
          window.ym(86330776, "reachGoal", "PAGE_STAY_TIME", eventData);
          window.gtag("event", "PAGE_STAY_TIME", eventData);
          if (that.pageTimer != undefined || that.pageTimer != null) {
            clearInterval(that.pageTimer);
          }
        },
      },
    },
  };

  for (const [key, value] of Object.entries(globalVars)) {
    Vue.prototype[`$${key}`] = value;
  }
}
export default assignGlobals;
