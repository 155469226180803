import Api from "./Api";
import JwtService from "./jwt.service";

export const token = JwtService.getToken();

export default {
  fetchPacks() {
    return Api().get("api/package", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  fetchPack(id) {
    return Api().get(`api/package/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  updatePack(id) {
    return Api().put(`api/package/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  deletePack(id) {
    return Api().delete(`api/package/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
};
