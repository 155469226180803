//actions
export const ADD_TO_CART = "cart/addToCart";
export const REMOVE_FROM_CART = "cart/removeFromCart";

//mutations
export const SET_CART = "cart/addToCart";

const state = {
  cart: [],
};

const getters = {
  getCartContent(state) {
    return state.cart;
  },
};

const actions = {
  [ADD_TO_CART](context, payload) {
    /* Send data to server */

    /* Fake Add */
    context.commit(SET_CART, { payload: payload, action: "add" });
  },
  [REMOVE_FROM_CART](context, payload) {
    /* Request server */

    /* Fake Delete */
    context.commit(SET_CART, { payload: payload, action: "delete" });
  },
};
const mutations = {
  [SET_CART](state, payload) {
    if (payload.action == "add") {
      state.cart.push(payload.payload);
      return;
    }
    if (payload.action == "delete") {
      const item = state.cart.find((e) => e.id == payload.payload.id);
      state.cart.splice(state.cart.indexOf(item), 1);
      return;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
