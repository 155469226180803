// USA
export const locale = {
  greet: ({ named }) => `Hello <span class="font-weight-bold">${named("userName")}!</span>`,
  turkish: "Turkish",
  english: "English",
  date: "Date",
  time: "Time",
  true: "Yes",
  false: "No",
  dynamic: "Dynamic",
  static: "Static",
  hi: "hi",
  create: "create",
  template: "template",
  tutorial: "Tutorial",
  change: "Change",
  update: "Update",
  qr_list: "qr list",
  QR_TITLE: "QR Code Title",
  qr_code_list: "QR Code list",
  qrcode_document: "Document",
  qrcode_document_subtext: "Create a document viewer",
  qrcode_website: "Link",
  qrcode_website_subtext: "Redirect to your single link",
  qrcode_vcard: "vcard plus",
  qrcode_vcard_subtext: "Create a personelized virtual card",
  qrcode_sms: "sms",
  qrcode_smsdynamic: "sms",
  qrcode_sms_subtext: "Create SMS sender",
  qrcode_smsdynamic_subtext: "Create SMS sender",
  qrcode_business: "business",
  qrcode_business_subtext: "Create your business face",
  qrcode_socialmedia: "social media",
  qrcode_socialmedia_subtext: "Redirect links for your social media accounts",
  qrcode_pdf: "pdf",
  qrcode_pdf_subtext: "Create PDF viewer",
  qrcode_restaurant: "restaurant Menu",
  qrcode_restaurant_subtext: "Create menu for your dining business",
  qrcode_pdfmenu: "pdf Catalog / pdf menu",
  qrcode_pdfmenu_subtext: "Create catalog or menu with your PDF file",
  qrcode_catalog: "catalog",
  qrcode_catalog_subtext: "Create image catalog with your PDF file",
  qrcode_equipment: "equipment",
  qrcode_equipment_subtext: "Keep track of your equipments status",
  qrcode_staffcard: "staff card",
  qrcode_staffcard_subtext: "Staff card for your colleaque",
  qrcode_emergency: "emergency",
  qrcode_emergency_subtext: "Information card for emergency situations",
  qrcode_event: "event",
  qrcode_event_subtext: "Invitation app for events like concerts, expos",
  qrcode_location: "location",
  qrcode_location_subtext: "Show your favorite locations on the Google Map",
  qrcode_music: "music",
  qrcode_music_subtext: "Upload music and listen through stylized player",
  qrcode_pet: "pet",
  qrcode_pet_subtext: "Store your pet's crucial information",
  qrcode_museum: "museum",
  qrcode_museum_subtext: "Informations about spesific historical places and artifacts",
  qrcode_coupon: "coupon",
  qrcode_coupon_subtext: "Distribute your promo codes",
  qrcode_rate: "rate",
  qrcode_rate_subtext: "Let people rate you/your product and leave comment",
  qrcode_store: "store",
  qrcode_store_subtext: "Create app store links for your mobile applications",
  qrcode_call: "call",
  qrcode_call_subtext: "Phone call maker",
  qrcode_text: "text",
  qrcode_text_subtext: "Share your text",
  qrcode_mail: "mail",
  qrcode_mail_subtext: "E-mail contact card",
  qrcode_whatsapp: "whatsapp",
  qrcode_whatsapp_subtext: "Whatsapp contact card",
  qrcode_instagram: "instagram",
  qrcode_instagram_subtext: "Instagram contact card",
  qrcode_facebook: "facebook",
  qrcode_facebook_subtext: "Facebook contact card",
  qrcode_twitter: "X / Twitter",
  qrcode_twitter_subtext: "X / Twitter contact card",
  qrcode_linkedin: "linkedin",
  qrcode_linkedin_subtext: "Linkedin contact card",
  qrcode_youtube: "youtube",
  qrcode_youtube_subtext: "Youtube contact card",
  qrcode_pinterest: "pinterest",
  qrcode_pinterest_subtext: "Pinterest contact card",
  qrcode_paypal: "paypal",
  qrcode_paypal_subtext: "Create a paypal payment or donation link",
  qrcode_wifidynamic: "wifi",
  qrcode_wifidynamic_subtext: "Share your wifi credentials",
  qrcode_websitestatic: "website (static)",
  qrcode_websitestatic_subtext: "Static QR Code that contains your website url",
  qrcode_vcardstatic: "vcard (static)",
  qrcode_vcardstatic_subtext: "Virtual card that you can save as a .vcf format",
  qrcode_phone: "phone",
  qrcode_phonestatic: "phone (static)",
  qrcode_phone_subtext: "Phone QR Code",
  qrcode_textstatic: "text (static)",
  qrcode_textstatic_subtext: "Static text",
  qrcode_smsstatic: "sms (static)",
  qrcode_smsstatic_subtext: "Static SMS sender",
  qrcode_wifistatic: "wifi (static)",
  qrcode_wifistatic_subtext: "Static QR Code for wi-fi credentials",
  qrcode_multiplelink: "multiple link",
  qrcode_multiplelink_subtext: "Multiple link redirection with random option",
  qrcode_gallery: "Picture Gallery",
  qrcode_gallery_subtext: "Picture Gallery",
  qrcode_customerservice: "Department Contact",
  qrcode_customerservice_subtext: "Create department communication links",
  qrcode_all: "- Show All- ",
  page_not_found_text: "Oops! Content was not found",
  go_home: "Go Home",
  this_field_is_required: "This Field Is Required",
  select_pdf_by_clicking: "Click on PDF File to Select",
  select_content_by_clicking: "Click on Media File to Select",
  link_is_copied_to_clipboard: "Link is copied to clipboard!",
  search_type: "Search Type",
  user_folder: "User Folder",
  clear_all_fields: "Reset",
  click_lock_to_unlock: "Click lock icon to to able to change this field",
  empty: "Empty",
  you_dont_have_any_saved_design: "You Don't Have Any Saved Design",
  you_can_save_your_current_design_by_clicking_button_below:
    "You can save your current design by clicking button below",
  you_have_to_update_save_this_qrcode_to_be_able_to_use_this_feature:
    "You can save your current design by clicking button below",
  SAVE_CURRENT_DESIGN: "+ SAVE CURRENT DESIGN",
  you_have_to_update_current_changes_first: "You have to update current changes first",
  LANGUAGE: "Choose Language",
  YES: "YES",
  ACTIVE_LANGUAGE: "Active Language",
  ADD_SOME_INFO: "Add some information",
  CONTACT_INFO: "Contact Info",
  FULL_NAME: "Full Name",
  FIRSTNAME: "First Name",
  LASTNAME: "Last Name",
  COMPANY_NAME: "Company",
  COMPANY_ADDRESS: "Company Address",
  JOB_TITLE: "Job Title",
  JOBTITLE: "Job Title",
  DEPARTMENT: "Department",
  BLOOD_GROUP: "Blood Group",
  FOLDER_NO: "Folder No",
  DATE_OF_START: "Date of Start",
  COMPANY: "Company",
  COUNTRY: "Country",
  STATE: "State",
  MAIL: "Mail",
  WEBSITE: "Website",
  PHONE: "Phone",
  SMS: "SMS",
  HOME: "HOME",
  WHO_ARE_WE: "Who Are We",
  icon: "Icon",
  show_more: "Show more",
  remove_this_song: "Remove This Song",
  select_cover_image: "Select Cover Image",
  select_audio_file: "Select Audio File",
  spotify_settings: "Spotify Settings",
  light_theme: "Light Theme",
  dark_theme: "Dark Theme",
  you_can_paste_single_song_or_playlist:
    "You can paste single song or playlist. The correct link should look like this:",
  add_songs_from_local_files: "Add songs from Local Files",
  add_songs_from_spotify: "Add songs from Spotify",
  SETTINGS: "Settings",
  APPLY: "APPLY",

  crops_image: "Export Image to Selected Card",
  rotate_right: "Rotate Right",
  rotate_left: "Rotate Left",
  upload_image: "Upload Image From Your Computer",
  reset: "Reset",
  upload: "Upload",
  transfer: "Transfer",

  adress_icon: "Adress Icon",
  area_icon: "Area Icon",

  card_owner: "Card Owner",
  expires_at: "Expires",
  mounth: "MM",
  years: "YY",
  full_name: "Full Name",

  type_city_name: "Enter City and Town",
  your_zip_code: "Add Your Zip Code",
  id_number: "Identity Number",
  tax_number: "Tax Number",
  tax_office: "Tax Office",

  qr_code_type: "You can change the QR Code type. (Read Warning Important!)",

  PHONEHOME: "Home phone",
  PHONEMOBILE: "Mobile phone",
  PHONEOFFICE: "Office phone",
  PHONEHOMECOUNTRYCODE: "Country code",
  PHONEMOBILECOUNTRYCODE: "Country code for mobile",
  PHONEOFFICECOUNTRYCODE: "Country code for office",
  ABOUT: "About Us",
  ABOUTUS: "About Us",
  TEAM: "Team",
  CONTACT: "Contact",
  PREVIEW: "Preview",
  SUPPORT: "Support",
  ADDRESS: "Address",
  ZIPCODE: "Zip code",
  FAXCOUNTRYCODE: "Fax Country Code",
  ENTER_LOCATION: "+ Please Write Your Address (You Can Add Multiple Addresses)",
  ENTER_LOCATION_HELP:
    "Please write your address below. Then select your address from the opened window. Afterward, you can mark your more precise location by dragging the red icon",
  PLEASE_CHOOSE_LOCATION: "Please choose location first",
  MANAGER_FULL_NAME: "Manager name",
  MANAGER_PHONE: "Manager phone",
  MANAGER_JOBTITLE: "Manager role",
  MANAGER_MAIL: "Manager E-mail",
  ART_NAME: "Art Name",
  ART_ACCESSION_NUMBER: "Accession Number",
  ART_ERA: "Era",
  ART_MEASUREMENT: "Measurements",
  ART_DISCOVERY_LOCATION: "Discovery Location",
  ART_ACCESSION_DATE: "Accession Date",
  ART_DESCRIPTION: "Art description",
  RESTAURANT_ABOUT_US: "",
  RESTAURANT_ADDRESS: "",
  RESTAURANT_PHONE: "",
  RESTAURANT_EMAIL: "",
  NAME: "Name",
  BLOODGROUP: "Blood Group",
  MEDICATION: "Medication",
  PLATENUMBER: "Plate number",
  ALLERGICTO: "Allergic To",
  DESCRIPTION: "Description",
  MEDICALRECORD: "Medical record",
  NUMBER: "Number",
  SERIALNUMBER: "Serial Number",
  NEXTMAINTENANCE: "Next Maintenance",
  LASTMAINTENANCE: "Last Maintenance",
  REPAIRCOMPANY: "Maintenance Company",
  LOCATION: "Location",
  SHOWREMAINING: "Show remaining days",
  FREQUENCY: "Frequency",
  STARTHOUR: "Start hour",
  ENDHOUR: "End hour",
  ENDDATE: "End date",
  STARTDATE: "Start date",
  TITLE: "Title",
  TITLE1: "First title",
  TITLE2: "Second title",
  SUBTITLE: "Subtitle",
  EMAIL: "Mail",
  YOUTUBE: "Youtube Link",
  GMAP: "Google Maps",
  URL: "Link",
  TEXT2: "Second Text",
  TEXT1: "Text",
  TEXT: "Text",
  BUTTONTEXT: "Button Text",
  BUTTONDESC: "Button ",
  BTNTEXT: "Button text",
  NOPERIOD: "No Period",
  LAST_30_DAYS: "Last 30 Days",
  LAST_90_DAYS: "Last 90 Days",
  LAST_180_DAYS: "Last 180 Days",
  SELECTED_TIME: "ALL Time",
  OWNER: "Owner",
  VETADDRESS: "Vet Address",
  VETPHONE: "Vet Phone",
  VETEMAIL: "Vet Email",
  VETNAME: "Vet Name",
  GOOGLELINK: "Google Link",
  VIDEOLIST: "Multiple Youtube Lists",
  IOSLINK: "App store link",
  HUAWEILINK: "Huawei gallery link",
  COUPONDISCOUNT: "Coupon Discount",
  COUPONTITLE: "Coupon Title",
  COUPONCODE: "Coupon Code",
  PRODUCT: "Product",
  WIFINAME: "Wifi Name",
  CONTENTLIST: "Content List",
  CONTENTIMAGES: "Content Images",
  PASSWORD: "Password",
  USER: "Users",
  FILE: "File",
  ["AD SOYAD"]: "Full Name",
  BÖLÜMÜ: "Division",
  GÖREVI: "Mission",
  TELEFONU: "Phone",
  ["KAN GRUBU"]: "Blood group",
  ["İŞE GIRIŞ TARIHI"]: "Start date of work",
  ["SICIL NO"]: "Registration number",
  RESPONSIBLESTAFFPHONE: "Responsible Staff",
  CITY: "City/region",
  FAX: "Fax",
  ZIP: "Zip Code",
  MESSAGE: "Message",
  SSID: "SSID",
  ISHIDDEN: "Is Hidden",
  NETWORKTYPE: "Network Type",
  PW: "Password",
  SECURITY: "Security",
  TOPICON: "Top Icon",
  MIDDLEICON: "Middle Icon",
  GOOGLE: "Google play link",
  APPLE: "App store link",
  HUAWEI: "Huawei gallery link",
  COUNTRYCODE: "Country code",
  ISWIFIHIDDEN: "Wifi Visibility",
  FIRSTSECTIONTITLE: "Section Title",
  NEW: "New!",
  NO_TITLE: "No Title",
  ACTIVE: "ACTIVE",
  active: "Active",
  PASSIVE: "PASSIVE",
  passive: "Passive",
  status: "Status",
  UNIQUE_SCAN: "Unique Scan",
  DOWNLOAD: "Download",
  SCAN: "Total Scan",
  PRINT: "Print",
  VIEW: "View",
  ISTATISTICS: "You can get detailed statistical information by ACTIVATING statistics.",
  DELETE: "Delete",
  ACTIVE: "You can stop and start your QR Code whenever you want by making it Passive or Active.",
  RENAME: "Rename",
  CANCEL: "Cancel",
  SEE_PACKAGE: "SEE PACKAGE",
  LIVE: "Live",
  ARE_YOU_SURE: "Are you sure?",
  ERROR: "Error",
  ATTENTION: "Attention!",
  OK: "OK",
  CLOSE: "Close",
  SUCCESS: "Success!",
  DOWNLOAD_ERROR: "Error while uploading",
  SORT_BY: "Sort By",
  MOST_RECENT: "Most Recent",
  LEAST_RECENT: "Least Recent",
  RECENTLY_UPDATE: "Recently Updated",
  LEAST_SCANNED: "Least Scanned",
  MOST_SCANNED: "Most Scanned",
  SEARCH: "Search",
  UPLOAD: "File Upload",
  BACK: "Back",
  NEW_FOLDER: "New Folder",
  IMAGE_QUALITY: "QR Code Reading Quality",
  LOW: "Low",
  MEDIUM: "Medium",
  GOOD: "Good",
  HIGH: "High",
  BACK_TO_DEFAULTS: "Back to Defaults",
  SAVE_QR_CODE: "SAVE QR Code",
  SAVE: "Save",
  RESET: "Reset",
  SHORT: "Short",
  URL: "URL",
  SEND: "Save",
  SCAN_QR: "Scan QR",
  SAVED_DESIGNS: "Saved Designs",
  SHOW_ALL: "SHOW ALL",
  EYE_BALL: "Eye Ball",
  EYE_FRAME: "Eye Frame",
  FRAME: "Frame",
  LOGOS: "Logos",
  ANIMATION: "Animation",
  STATIC: "Static",
  BODY: "Body",
  TEMPLATES: "QR Code Templates",
  ADVENCED_LOGO_SETTING: "Advenced Logo Setting",
  GO_BACK: "Go Back",
  SELECT: "Select",
  CUSTOMIZE_INDIVIDUALLY: "Customize Individually",
  SINGLE_COLOR: "Single Color",
  CHANGE_REFERANCE_POINT: "Change Reference Point",
  NEED_HELP: "Need Help ?",
  FRAME_SETTINGS: "Frame Settings",
  DEFAULT_LANGUAGE: "Default Language is",
  DELETE_ALL: "Delete All",
  ACTIVATE_ALL: "Activate All",
  DE_ACTIVATE_ALL: "Pasif All",
  OPTION: "Option",
  DISAGREE: "DISAGREE",
  you_have_unsaved_changes: "You have unsaved changes",
  approve_comment: "Approve Comment",
  disapprove_comment: "Disapprove Comment",
  you_have_unsaved_changes_desc: "Do you still want to leave the page ?",
  warning: "Warning!",
  add_new_languages: "Add new language",
  language_settings: "Language Settings",
  get_more_information_about: "Get more information about",
  pdf_info: "When this option is selected, users will not see any pages and will be directed to the pdf file.",
  pdf_download: "You need to download the pdf for it to be active.",
  copy_content_from_description:
    "This language's content you are selecting above will be applied to new languages that will be added.",
  copy_content_from: "Copy content from",
  you_are_about_to_delete_the_category: "You are about to delete the category",
  are_you_sure_you_want_to_continue:
    "If you proceed you will lose this category data along with the PDF you have selected. Are you sure you want to continue?",
  you_at_least_need_to_have_1_category: "You at least need to have 1 category",
  dark_mode: "Dark mode",
  pdf_is_processing: "Pdf is processing...",
  image: "Image",
  browse_my_media: "browse my media",
  my_media: "My media",
  my_media_subtext: "Manage your media files",
  my_domains: "My domains",
  my_domains_subtext: "Manage your domains",
  default_domain: "DEFAULT DOMAIN ",
  not_verify_domain: "UNVERIFIED DOMAIN ",
  verify_domain: "VERIFY DOMAIN",
  make_deafult_domain: "MAKE DEFAULT DOMAIN ",
  default_status: "Default Status",
  domain_add: "ADD DOMAIN",
  animation: "Animation",
  background_color: "background color",
  choose_design: "Choose Design",
  select_from_my_media: "select from my media",
  select_from_animation: "select from animation",
  age_restriction:
    "You can age restriction for your app to ensure the safety and experience of our users. To access our app, users must verify their age. This means that our app offers content for certain age groups and limits adult features.",
  age_restriction_text: "Age Restriction",
  age_restriction_tooltip: "Set a disclaimer for users below certain age",
  analytics: "analytics",
  expiration_date: "expiration date",
  device: "device",
  personal_info: "Personal Information",
  preparing_template: "",
  RESERVATIONEMAIL: "Reservation email",
  locked: "Locked",
  RESTORE: "Restore",
  current_password: "Current Password",
  new_password: "New Password",
  verify_password: "Verify Password",
  time_limit: "Time Limit",
  pdf_is_processing: "Pdf is processing",
  field_required: "This field is required",
  start_typing: "Start typing...",
  processing_pdf: "Processing PDF file",
  general_settings: "General Settings",
  REMINDER: "Reminder",
  WEBURL: "Website",
  NONE: "None",
  MINUTES: "minutes left",
  HOURS: "hours left",
  DAYS: "days left",
  WEEKS: "weeks left",
  COMPLETE: "Complete",
  type_your_first_name: "Type your first name",
  type_your_last_name: "Type your last name",
  type_your_address: "Type your address",
  your_company_info: "Your company info",
  order_summary: "Order Summary",
  order_desc: "Summary of selected package information",
  annual: "Annual",
  subtotal: "Subtotal",
  tax: "Tax",
  coupon_discount: "Coupon discount",
  total: "Total Price",
  package_price: "Package Price",
  package_discount: "Package Discount",
  type_your_discount_code: "Type your discount code",
  coupon_code: "Coupon code",
  use_coupon_code: "Use coupon code",
  cancel_coupon: "Cancel Coupon",
  i_agree_to: "I agree to the",
  month: "Month",
  year: "Year",
  day: "Day",
  go_back: "Go Back",
  creditcard_number: "Card Number",
  creditcard_name: "Card Name",
  creditcard_expiration_date: "Expiration Date",
  creditcard_cvv: "CVC",
  terms_of_use: "Terms of Use",
  current_plan: "Current Plan",
  active_package: "Active Package",
  started_at: "Started At",
  ends_at: "Ends At",
  remaining_time: "Remaining Time",
  package_content: "Package Content",
  newspaper: "Newspaper",
  campaign_start: "Campaign Start",
  campaign_end: "Campaign End",
  campaign: "Campaign",
  start_date: "Start Date",
  end_date: "End date",
  are_you_sure: "Are you sure",
  campaign_info: "Campaign Info",
  print_style: "Print Style",
  print_amount: "Print Amount",
  advanced_statistics: "Advanced Statistics",
  advanced_statistics_desc: "Advanced statistics is not active. Would you like to activate it ?",
  change_range: "Filter",
  select_start_and_end_dates: "Select start and end dates",
  general_settings_link: "Click here to get more information.",
  email_address_to_be_sent: "Email address to which the contact form will be sent",
  general_settings_desc:
    "QR Code title is shown only to you just to distinguish it from another QR Codes you have. Users will not see this and changing this will not modify your page.",
  submit: "Send",
  invalid_email: "Invalid email",
  new_qr_code: "New QR Code",
  days_remaining: "Day Remaining",
  full_name: "Full Name",
  phone: "Phone",
  email: "Mail",
  text: "Text",
  link: "Link",
  desc_message: "Write a description...",
  how_many_qrcodes_you_need: "How many qrcodes you need",
  request_custom_package: "Request Custom Package",
  communication: "Contact",
  account_privacy: "Delete account",
  deactivate_your_account: "Deactivate your account",
  default_language: "This will be your default language",
  personal_information: "Personal Information",
  update_your_personal_information: "Update your personal information",
  save_changes: "Save Changes",
  cancel: "Cancel",
  avatar: "Avatar",
  account_information: "Account Information",
  allowed_file_types: "Allowed file types",
  change_password: "Change Password",
  account_holder: "Account Holder",
  FIRSTBTNTEXT: "First button text",
  SECONDBTNTEXT: "Second button text",
  request_price: "Request a special offer",
  make_request: "REQUEST A QUOTE",
  gender: "Gender",
  male: "Male",
  female: "Female",
  cell_phone: "Cell phone",
  invalid_phone: "Invalid phone",
  invalid_mail: "Invalid mail",
  invalid_website: "Invalid website",
  invalid_name: "Invalid name",
  dinamik_qr_kod_fiyatları: "Dynamic QR Code Prices",
  restaurant_menü_qr_kod_fiyatları: "Restaurant QR Code Prices",
  note: "Note",
  no_data: "No Data to show",
  user_profile: "User Profile",
  sign_out: "Sign out",
  signing_out: "Signing out",
  account_settings_and_more: "Account settings and more",
  my_profile: "My Profile",
  active_subscriptions: "Active Subscriptions",
  active_subscriptions_subtext: "Your QR Subscriptions",
  SELECT_FROM_THE_LIST: "SELECT FROM THE LIST",
  package_name: "Package Name",
  device_list: "Device List",
  details: "Details",
  detailed_chart: "Detailed Chart",
  browser_list: "Browser List",
  city_list: "City List",
  country_list: "Country List",
  os_list: "Operating System List",
  ip_list: "Ip List",
  age_profile: "Age List",
  gender_profile: "Gender Profile",
  device_resolution_profile: "Device Resolution Profile",
  last_day: "Last Day",
  last_week: "Last Week",
  last_month: "Last Month",
  last_year: "Last Year",
  page_view: "Page View",
  unique_visitor: "Unique Visitors",
  total_view: "Total View",
  total_visit: "Total Visit",
  delete_staticstics: "Are you sure you want to delete statistics data?",
  avarage_duration_visitors_stay: "Avarage duration visitors stay?",
  page_view: "Page View",
  total_number: "Total Number",
  sorry_im_leaving: "Sorry Im Leaving",
  sorry_im_leaving_desc:
    "You are about to delete your account. If you proceed your data will be permanently deleted and you will not be able to recover them back. Do you still want to continue ?",
  last_30_days: "Last 30 days",
  loading: "Loading",
  klasor_name: "Folder Name",
  delete_comment: "Delete Comment",
  are_you_sure_you_want_to_delete_this_comment: " Are you sure you want to delete this comment?",
  if_you_delete_this_comment: " If you delete this comment, you won't be able to recover it afterwards.",
  CREATE: "Create",
  Upgrade: "Upgrade",
  no_content: "No Content",
  size: "Size",
  scan: "Scan",
  limit: "Limit",
  package_limit: "Package Limit",
  coupon_discount: "Coupon Discount",
  rows_per_page: "Rows per page",
  your_subscription_has_expired: "Your subscription has expired",
  two_ways_to_keep_using: "There are two ways to keep using QR Code Matic",
  contact_us: "Contact Us",
  upgrade_now: "Upgrade Now",
  or: "OR",
  WHO_ARE_WE: "Who Are We?",
  list_is_empty: "List is empty",
  recaptcha_error: "Please confirm you are not a robot",
  if_you_delete_this_qr_code: "If you delete this QR Code, you will also lose the data along with it.",
  qr_codes_that_are_not_recovered: "QR Codes that are not recovered within 20 days will be completely deleted.",
  changing_URL_will_create_a_new_qr_code:
    "Changing URL will create a new QR Code and printed version of this pre-change will be unreachable if you proceed.",
  attention: "Attention!",
  valid_in_dynamic: "Valid in Dynamic",
  loading_items: "Loading items",
  go_to_main_page: "Go to main page",
  your_package_will_be_processed_after_your_payment_has_been_received:
    "Your package will be processed after your payment has been received.",
  selected_file: "Selected File",
  no_file: "No File",
  no_link: "No link",
  subject: "Subject",
  message: "Message",
  qrcode: "QR Code",
  allow_this_to_be_shared: "I allow for this message to be shared the other users",
  add_more_button_text: "Add more",
  invalid_url: "Invalid URL",
  continue: "CREATE NOW",
  direct_download: "Direct Download (Downloads vcard to user phone after scanning QR Code)",
  REMAININGCOUNTERVISIBLEFORLASTAMOUNTDAYS: "Start counting before X days left",
  select_pdf_for_each_language:
    "Create a new Folder and upload your file, select the uploaded file. The PDF will be processed. You need to add PDF for each language. Missing in these languages: ",
  scan_result_good: "Readable",
  scan_result_bad: "Unreadable",

  drag_drop: "Change Sort by Drag",
  change_order: "Change Order",
  caption: "Subtitle",
  image: "Photo",
  add_new: "Add New",
  delete_selected: "Delete Selected",

  your_own: "Your Own",
  logo: "Logo",
  add: "Add",

  qrcode_static_subtext: "Select to generate your static code.",

  type_something_here: "Type Something Here",

  start_program: "Start",
  end_program: "End",

  billed_annually: "Billed Annually",
  no_folder: "No Folder",

  I_WANT_TO_GET_INFORMATION_ABOUT_THE_PRODUCTS: "I WANT TO GET INFORMATION ABOUT THE PRODUCTS",

  keep_track:
    "Keep track of how many user scanned this qr code and benchmark your site by using <strong>Advanced Statistics.</strong></br></br> To go to <strong>Advanced Statistics</strong> click the button on top right after you unlock it.</br></br><img src='https://i.imgur.com/fOk5J81.png' /> ",

  view_user_ratings: "View user ratings",

  not_a_valid_linkedin_link: "Not a valid Linkedin link",
  not_a_valid_facebook_link: "Not a valid Facebook link",
  not_a_valid_twitter_link: "Not a valid Twitter link",
  not_a_valid_instagram_link: "Not a valid Instagram link",
  not_a_valid_whatsapp_link: "Not a valid Whatsapp link",
  not_a_valid_youtube_link: "Not a valid Youtube link",
  not_a_valid_reddit_link: "Not a valid Reddit link",
  not_a_valid_telegram_link: "Not a valid Telegram link",
  not_a_valid_snapchat_link: "Not a valid Snapchat link",
  not_a_valid_pinterest_link: "Not a valid Pinterest link",
  not_a_valid_yelp_link: "Not a valid Yelp link",
  not_a_valid_flicker_link: "Not a valid Flicker link",
  not_a_valid_vimeo_link: "Not a valid Vimeo link",
  not_a_valid_vk_link: "Not a valid VK link",
  not_a_valid_soundcloud_link: "Not a valid Soundcloud link",
  not_a_valid_github_link: "Not a valid Github link",
  not_a_valid_tumblr_link: "Not a valid Tumblr link",
  not_a_valid_skype_link: "Not a valid Skype link",

  give_us_feedback: "Give Us Feedback",
  please_do_let_us_know: "Please do let us know:",
  your_feedback_is_important_to_us: "Your feedback is important to us!",
  if_you_would_like_to_let_us_know:
    "if you would like to let us know how we can improve our products or services or perhaps share a compliment, we would love to hear from you!",
  how_you_use_our_products: "How you use our products",
  how_we_can_improve_our_products_or_service: "How we can improve our products or service",
  which_conferences_you_think_we_should_attend: "Which conferences you think we should attend",
  any_publications_citing_the_use_of_our_products: "Any publications citing the use of our products",
  we_look_forward_to_hearing_from_you: "We look forward to hearing from you!",
  feedback_form: "Feedback Form",
  thank_you: "Thank You!",
  rate_us: "Rate Us!",
  we_have_recieved_your_feedback: "We have recieved your feedback",
  i_allow_for_this_message_to_be_shared_the_other_users: "I allow for this message to be shared the other users",

  if_you_delete_this_file:
    "If you delete this file, It might have broke your qrcodes that are actively using this media. Are you sure you want to proceed?",
  if_you_delete_selected_files:
    "If you delete all these selected files, It might have broke your qrcodes that are actively using this media. Are you sure you want to proceed?",
  if_you_delete_this_folder:
    "If you delete this folder, It might have broke your qrcodes that are actively using medias inside this folder. Are you sure you want to proceed?",
  delete_all_childs_alert_text: ({ named }) =>
    `This irreversible process will delete all ${named(
      "amount"
    )} QR Codes under this master. Please make sure you want to delete all of them at once before continue`,
  you_need_to_select_pdf_for_each_language_missing_in:
    "Click the 'Select PDF' button. Create a New Folder and upload your file, click on the uploaded file and the PDF will be processed. You need to add PDF file for each language. No PDF file included in these languages:",
  you_can_not_add_more_than_connections: ({ named }) => `You can not add more than ${named("amount")} connections`,
  If_you_delete_this_language_you_will_lose_all_the:
    "If you delete this language you will lose all the data associated with it. Are you sure you want to continue?",
  you_can_not_delete_default_language: "You can not delete default language",
  you_can_not_delete_active_language: "You can not delete active language",
  "Set as a default": "Set as a default",
  Delete: "Delete",
  Activate: "Activate",
  email_verification: "Email Verification",
  a_new_verification_mail_has_been_sent_to_your_email_address:
    "A new verification mail has been sent to your e-mail address.",
  did_not_recieve_an_email: "Did not recieve an e-mail?",
  re_send_verification_mail: "Resend verification mail",
  just_for_you_to_join_us_one_step_left: "Just for you to join us one step left",
  an_activation_email_has_been_sent:
    "An activation email has been sent to your email address.You can activate your subscription by clicking the link.",
  locks_your_app_and_asks_users_to_enter_this_password:
    "A password system is implemented for security purposes to access your application. Users have to correctly enter a pre-set password to visit the app or access exclusive content. This password provides user authentication, prevents unauthorized access and increases the security of user data.",
  paste_given_code_if_you_would_like_to_track_your_statictics:
    "If you want to keep track of your statistics in detail, paste your Yandex Metric or Google Analytics code in full.",
  if_you_fill_out_the_below_fiels_qr_code_will_be_unavailable_before_start_date_and_after_end_date:
    "If you fill out the below fiels, QR Code will be unavailable before start date and after end date.",
  the_content_will_only_be_avialable_to_the_selected_deviceList:
    "The content will only be avialable to the selected deviceList.",
  your_short_url_will_look_like_this: "Your short url will look like this : q-r.cc/adc256",
  qr_code_warning_text:
    "Note: If you change the name or value of the domain, the content of the QR Code will also change. If you have already printed the code, your QR Code will not work.",
  verify_password_does_not_match: "Verify password does not match",
  please_fill_all_fields: "Please fill all fields",
  text_must_be_less_than_2500_characters: "Text must be less than 2500 characters",
  title_must_be_less_than_80_characters: "Title must be less than 80 characters",
  must_be_less_than_200_characters: "must be less than 200 characters",
  must_be_less_than_500_characters: "must be less than 500 characters",
  you_need_to_chose_a_logo_or_toggle_off_in_media_settings:
    "You need to chose a logo or toggle off in 'Media' settings",
  this_url_is_invalid: "This url is invalid",
  your_form_contains_phishing_links: "Your form contains phishing links",
  is_required: "is required",
  this_is_required: "This is required",
  title_is_required: "Title is required",
  btnText: "Button Text",
  please_input_a_valid_phone_number: "Please input a valid phone number",
  missing_locations_for_languages:
    "Please do not copy-paste your ADDRESS! To choose the correct ADDRESS, WRITE YOUR ADDRESS, SEARCH, AND SELECT IT FROM THE OPENING MENU! ",
  this_must_be_less_than_5_characters: "This must be less than 5 characters",
  click_here_to_save_qrcode: "<strong>NOT SAVED!</strong> go to edit page and save it",
  type_changer_disclaimer: ({ named }) =>
    `You are about the change your current type from <strong>${named(
      "currentType"
    )}</strong> to a different QR Code. If you proceed you will lose all data related to this QR Code and will be redirected to related page to start to filling the required fields. Please be aware that this process is irreversable.`,

  pricing_guide: `
  <div style="padding:30px;background:#ffffff" >
            <h1 class="text-center">Pricing Guide</h1>
            <ul>
              <li>While providing solutions that suit the needs of our customers, we adopt a transparent and fair pricing policy. We offer different package options in our QR code solutions: Starter Package, Advanced Package and Business Package. We also offer special offers for our customers with special needs.</li>
              <li>In our packages, codes are sold by quantity and each package offers certain features and service levels. The choice made between these packages may vary depending on our customers' needs, budgets and usage scenarios.</li>
              <li>The Starter Package offers the perfect starting point for small businesses and personal users. The Advanced Package offers a wider range of features for medium-sized businesses and users with larger needs. The Business Package is designed to meet the more complex requirements of our large corporate customers.</li>
              <li>Our custom offerings provide customized solutions and codes in quantities other than packages for specific projects or unique requirements. We help our customers find the most suitable solution by offering flexible pricing options according to their specific needs.</li>
              <li>Please contact us for more information about pricing details and package features.</li>         
            </ul>
            <p class="text-end">QR Code Matic</p>
          </div>
  `,

  create_template_button_text: ({ named }) =>
    `<span class="font-weight-light mr-1">Create</span><span class="font-weight-bold">Template</span>`,
  queue_processing_title: ({ named }) => `${named("type")} batch is getting prepared!`,
  queue_done_title: ({ named }) => `${named("type")} is ready!`,
  queue_done_text: ({ named }) =>
    `"Your ${named("type")} master batch is finished. You can now modify master QR Codes."`,
  queue_processing_text: ({ named }) =>
    `"Your ${named("type")} master batch is finished. You can now modify master QR Codes."`,
  qeueu_default_text: ({ named }) =>
    `"Your batch request for your ${named(
      "type"
    )} is on the queue. You have to wait until it's done. You will recieve an email shortly after your request is completed."`,
  you_need_to_chose_start_or_end_date: "You need to chose start or end date",
  you_need_to_fill_the_required_fields: "You need to fill the required fields",
  new_address: "New Address",
  information: "Information",
  description_for_the_address: "Description for the address",
  you_can_not_add_more_than_10_emergency_number: "You can not add more than 10 emergency number",
  your_profile_data_has_been_successfuly_updated: "Your profile data has been successfuly updated!",
  your_profile_photo_has_been_successfuly_updated: "Your profile photo has been successfuly updated!",
  your_profile_photo_has_been_successfuly_removed: "Your profile photo has been successfuly removed!",
  you_at_least_need_to_have_1_address: "You at least need to have 1 address!",
  WHO_ARE_WE_1: `Our fully equipped, software technology company was founded in 1997,
  saying "There is a lot of work to be done, a lot of code to write". Our founder envisioned a software
  company that could serve its customers, deliver real value and deliver measurable 
  results. Today, we continue to serve you by signing works far beyond our dreams.`,

  WHO_ARE_WE_2: `We've come a long way so far. Over the years, we have opened our doors to talented software geniuses and become stronger together. 
  We continue this journey   with what we have gained by combining the knowledge we have accumulated with today's technology and doing our job with love.`,

  WHO_ARE_WE_3: `With our knowledge and experience in the sector, we will approach the processes holistically and strengthen your power in digital with sustainable 
  software technologies. With our Brand Management, Web Design and Software, Digital Marketing Strategies, Social Media Designs, SEM, SEO, E-commerce Systems and 
  Special Software Projects services, we help the companies we work with in the branding process, from production to sales, from finance to marketing, from R&D to 
  purchasing. We own up to all the processes.`,

  WHO_ARE_WE_4: `Beyond the services we offer, the care and attention we give to every project is the same, regardless of size or value. Our reputation is 
  built on satisfied customers, whose businesses we've seen grow through the projects we've completed.`,

  WHO_ARE_WE_5: `We know that the first step to any new project is to listen. We fully understand what you need, the problems you face and your plans for the future. 
  This is how we plan comprehensive strategies that work.`,

  WHO_ARE_WE_6: `With many years of experience, we know that we can help you no matter the scale of your project or the size of your company. Our guiding principles; 
  continuous improvement, empathy, attention to detail, honesty and care. This is why our customers, whom we have continued to work with since our establishment, stay 
  with us.`,

  WHO_ARE_WE_7: `But that's it for us! Now we want to hear about your dreams, goals, projects and what you want to do! If you want to talk to us about your online 
  business, the whole team is waiting for you and we are full of great ideas. We may love digital communication, but we don't like phone calls either. You can reach 
  us at 444 4 082 or send an e-mail to info@medyax.com.`,
  how_it_works: "How It Works",
  how_it_works_button_text: "How it works",
  your_subscription_expired: "Your package has expired. You can Generate a FREE Static QR Code.",
  qrcode_will_be_deleted_in_24: "This qrcode will be deleted in 24 hours",

  //domain list
  manage_your_own_domains: "Manage your own domains",
  domain_list: "Domain List",
  domain_name: "Domain name",
  short_link: "Short Link",
  options: "Options",
  delete: "Delete",
  domain_delete: "Domain Delete",
  domain_delete_desc: "You are about to delete your domain name ",
  domain_delete_desc_2: "Are you sure you want to continue?",
  how_to_setup: "How to setup?",
  how_to_setup_desc:
    "In order to activate your own shortener domain you have to setup a subdomain on your own domain. The subdomain have to point with a CNAME-Record to our domain q-r.cc Please make sure to setup the domain first, sometimes it will take up to 24 hours before our system can recognize the changes on your subdomain.",
  verified_status: "Verified Status",
  what_is_a_short_URL: "What is a short URL?",
  what_is_a_short_URL_desc:
    "Dynamic Codes use a short URL that redirects to the desired landing page. This is neccessary for receiving tracking data and allows editing Codes. You can change this domain e.g. to",

  waiting_for_process: "Waiting for process...",
  select_all: "Select All",
  deselect_all: "Deselect All",
  add_more_child_from_previous_qrcodes: "+ Add other QR Codes to this",
  add_more_child_from_previous_qrcodes_tooltip:
    "You can add or move other QR Codes under this QR Code and update them collectively and quickly.",
  convert_to_master: "Convert to Master",
  convert_to_master_tooltip: "Turn to add or move a QR Code under this QR Code.",
  add_more_child_dialog_title: "Add Existing QR Codes Below This Master",
  add_more_child_dialog_button_text: "Transfer",
  add_more_child_is_successful: "Operation Successful",
  add_more_child_dialog_p1:
    () => `This menu allows you to <strong>batch</strong> your previously created QR Codes</strong>.
   It allows you to encode the code. In this way, you can perform batch operations on the QR Codes you have selected.`,
  add_more_child_dialog_p2: ({ named }) => `Your QR Codes that you will choose from the list below <strong>${named(
    "title"
  )}</strong> title /
   <strong>${named("vars")}</strong> vars will be placed under the Master QR Code. After that
   You can bulk edit these QR Codes via <strong>${named("title")}</strong>`,
  add_more_child_dialog_p3: () => `The warning icons next to the QR Codes in the list indicate;
   <ul>
     <li><span style="background-color:#1abcb5;padding:2px 5px;border-radius:2px;color:#ffffff;">There are 1 sub QR Codes</span> Below this QR Code there are other QR Codes. If you mark the ones with this icon, the QR Codes below will be transferred along with the QR Code you selected.</li>
     <li><i class="fa-solid fa-circle-info orange--text mr-2"></i>This QR Code has master feature. If you choose this, it will lose its master feature and be taken under the QR Code to be processed.</li>
   </ul>`,
  are_you_sure_convert_child_to_master: ({ named }) =>
    `${named(
      "title",
      "You are about to extract the QR Code titled"
    )} from under the master it belongs to and make it a master. Are you sure you want to continue?`,
  add_more_child_count: ({ named }) => `There are ${named("count")} sub QR Codes`,
  gradient_on_eyes: "Gradient On Eyes",
  update_prices: "Update Menu Prices",
  confirm_delete_all: "Are you sure you want to delete all of these QR Codes ?",
  unsaved_changes_will_be_lost: "You have unsaved changes. Are you sure you want to leave this page?",
  show_media: "Show Media to Copy Links",
  $LOGIN: {
    welcome_to: "Welcome to QR Code Matic",
    just_one_step: "Only one step left to create Dynamic or Static QR Code!",
    you_are_almost_done: "You are almost done!",
    please_choose_appropriate_options:
      "Please choose the option that suits you! You can quickly select the 'OTHER' option at the bottom or the 'PERSONAL USE' option at the top.",
    disclaimer:
      "Disclaimer:  Your billing address have to match with the country you choose up here! If you make a purchase, only this country will be invoiced.",
    new_here: "New Here",
    create_an_account: "Create an Account",
    password: "Password",
    password_re: "Confirm Password",
    forgot_password: "Forgot Password",
    sign_in: "Sign in",
    sign_in_with_google: "Sign in with Google",
    sign_in_with_facebook: "Sign in with Facebook",
    sign_in_with_twitter: "Sign in with Twitter",
    sign_up: "Sign",
    subheader: "Enter your details to create your account",
    your_organization: "Your organization",
    your_country: "Your country",
    enter_new_password: "Enter New Password",
    your_password_has_been_reset: "Your password has been reset!",
    you_will_be_redirected_to_login: "You will be redirected to Login...",
    reset_link_is_sent_to_your_mail: "Reset link is sent to your mail",
    check_your_mail_box: "Check your mail box",
    login: "LOGIN",
    please_chose_one_of_the_options: "Please chose one of the options",
    your_organization: "Your Organization",
    your_country: "Your Country",
    your_language: "Your Language",
    login_is_successful: "Login is successful 1",

    fullname: "Full name",
    confirm_password: "Confirm password",
    agree_terms: "I agree terms and conditions",
    did_you_forget_password: "Forgot your Password ?",
    forgot_password_subheader: "Enter your email to reset your password",
    submit: "Submit",
    cancel: "Cancel",
    terms: "Terms and Conditions",
    contact_us: "Contact Us",
  },
  $SELECT_SECTOR: {
    personal_use: "Personal Use",
    tourism: "Tourism",
    restaurants: "Restaurants",
    software: "Software",
    photo_video: "Photo & Video",
    cities_culture: "Cities & Culture",
    event_management: "Event Management",
    home_repair: "Home & Repair",
    finance: "Finance",
    publisher: "Publisher",
    nonprofit: "Nonprofit",
    retail: "Retail",
    real_estate: "Real Estate",
    gym: "Gym & Wellness",
    hotel: "Hotels & Resorts",
    products: "Products",
    education: "Education",
    goverment: "Goverment",
    doctors: "Doctors & Healthcare",
    agency: "Agency Services",
    electronics: "Electrical and Electronics",
    paper: "Woodworking, Paper and Paper Products",
    glass: "Glass, Cement and Soil",
    environment: "Environment",
    energy: "Energy",
    foods: "Foods",
    construction: "Construction",
    business: "Business and Management",
    chemistry: "Chemistry, Petroleum, Rubber and Plastic",
    culture: "Culture, Art and Design",
    mines: "Mines",
    machines: "Machines",
    media: "Media, Communication and Broadcasting",
    metal: "Metal",
    automotive: "Automotive",
    social: "Social Services",
    sports: "Sports and Recreation",
    agriculture: "Agriculture, Hunting and Fishing",
    textile: "Textile, Ready-to-Wear, Leather",
    trade: "Trade (Sales and Marketing)",
    community: "Community and Personal Services",
    beverage: "Food and Beverage Services",
    transport: "Transport  and Logistics",
    communications: "Communications",
    other: "Other",
  },
  $TICKET: {
    mail_support: "Mail Support",
    choose_a_file: "Choose a file or drop it here...",
    enter_mail: "Enter Mail...",
    mail: "Mail",
    subject: "Subject",
    message: "Message",
    select_topic: "Select Topic",
    qenerals_quetion: "Generals Quetion",
    billing: "Billing",
    technical_question: "Technical Question",
    login_issue: "Login Issue",
    other: "Other",
  },
  $ORDERS: {
    order_number: "Order Number",
    product_name: "Product Name",
    currency: "Currency",
    order_date: "Order Date",
    order_status: "Order Status",
    active_package: "Active Package",
    started_at: "Started at",
    expires_at: "Expires at",
    remaining_days: "Remaining days",
    actions: "Actions",
    order_title: "Orders",
    payment_confirmed: "Confirmed",
    incorrect_payment: "Payment failed",
    awaiting_payment: "Awaiting payment",
    order_canceled: "Canceled",
    cancel_order: "Cancel Order",
    status: "Status",
    invalid_package: "Invalid Package",
    payment_type: "Payment Type",
    account_number: "Account Number",
    payment_amount: "Account Amount",
    payment_desc: "Payment Description",
    bank_transfer: "Bank Transfer",
    creditcard: "Credit Card",
  },
  $SUBSCRIPTION: {
    selected_package_name: "Selected Package Name",
    choose_payment_method: "Choose a payment method",
    choose_payment_method_description: "Please select a payment method most convenient to you",
    add_payment_info: "Add payment information",
    payment_is_safe: "Payment process is safe and secure",
    billing_info: "Billing Information",
    complete_payment: "Complete Payment",
    bank_transfer_info: "Bank Transfer Information",
    about_pricing: " If you need more info about our pricing, please check",
    choose_your_plan: "Choose Your Plan",
    pricing_policy: "Pricing Policy",
    private_or_business: "Please select if this is a private or business purchase",
    company_invoice: "Company Invoice",
    individual_invoice: "Individual Invoice",
    select: "CHECKOUT NOW",
    discountMessage: ({ named }) => `${named("amount")}$ discount is applied`,
  },
  validations: {
    field_required: "This field is required",
    email_required: "Email is required",
    password_required: "Password is required",
    agree_terms: "You have to agree on terms",
    invalid_zip: "Zip code is not valid",
    invalid_email: "Email is not valid",
    invalid_password: "Zip code is not valid",
    invalid_youtube: "Invalid youtube link",
    not_a_valid_email: "Not a valid email",
    not_a_valid_phone_number: "Not a valid phone number",
    you_must_accept_the_terms: "You must accept the terms",
    your_password_does_not_match: "Your password does not match",
    you_must_enter_valid_email: "You must enter valid email",
    you_must_enter_password: "You must enter password",
    you_must_enter_your_name: "You must enter your name",
    you_must_choose_country: "You must choose country",
    you_must_choose_organization: "You must choose organization",
    must_contain_numbers: "Must contain at least 1 number",
    must_contain_letters: "Must contain at least 1 letter",
    short_link_is_required: "required",
    short_link_special_char: "Only letters and numbers",
    short_link_between: "Must be 3-30 character long",

    less_than: ({ named }) => `Must be less than ${named("amount")} characters`,
    more_than: ({ named }) => `Must be more than ${named("amount")} characters`,
  },
  authTemplate: {
    confirm: "Onayla",
    legal_disclaimer_title: () => `<h1><span>Legal</span> Disclaimer</h1>`,
    legal_disclaimer:
      "You must be at least 11 years old to access this site. If you are under 11 years old you are not permitted to use this site for any reason.",
    login_area_title: () => `<h1><span>Login</span> Area</h1>`,
    login_button_text: "Login",
    login_disclaimer:
      "The domain you are trying to enter requires password. You can access your page by entering your password",
  },
  ALERT: {
    alert_msg_1:
      "You are about to edit static QR Code data. This will modify the QR Code itself. The changes you will make will not apply to printed or distributed QR Code. Are you sure you want to proceed ?",
  },
  FAQ_MAIN: {
    faqs_and_guides_about_qr_code: "FAQ's and Guides About QR Code",
    search_for_topics: "Search for topics...",
    faq: "FAQ",
    the_most_frequently_asked_question: "The most frequently asked question",
    articles_in_this_collection: "articles in this collection",
    written_by_QR_Code_Matic: "Written by QR Code Matic",
    setting_up_your_account: "Setting Up Your Account",
    why_qr_code: "Why QR Code?",
    pricing_and_billing: "Pricing and Billing",
    information_about_prices_and_invoices: "Information about prices and invoices",
    find_out_everything_you_need_to_know_about_qr_codes_here:
      "Find out everything you need to know about QR Codes here",
    analytic_title: "Analytics to Understand Data: Importance and Applications in Your Business",
    get_to_know_our_pricing_terms_and_billing: "Get to know our pricing, terms, and billing",
    analytic: "Analytical",
  },
  FAQ: {
    static_dynamic_title: "What is the difference between Static and Dynamic QR Code ?",
    static_dynamic: ` Static QR Codes cannot be changed once created and printed. If the link
    is broken, there is nothing you can do except reprint a new one. Dynamic  QR Codes, on the other hand, are editable and can be edited in real-time. 
    Since the dynamic QR Code contains a placeholder URL, the same QR Code can be reused over and over again saving time, effort, and money.`,

    better_static_dynamic_title: "Which is better: static or dynamic QR Code?",
    better_static_dynamic: `If you’re looking for a one-off marketing campaign that doesn’t need future editing and tracking, then static QR Codes might be better for you. If you need better
    flexibility with your campaigns, QR scan analytics, online retargeting, faster scans, etc., then dynamic QR Codes are highly recommended over static ones.`,

    how_change_dynamic_title: "How do I change a static QR Code to a dynamic QR Code ?",
    how_change_dynamic: `You cannot convert a static QR Code to a dynamic one as static QR Codes cannot be edited once created.`,

    how_qrcode_free_title: "How can I create a QR Code for free?",
    how_qrcode_free: `Head over to QrCodeMatic’s QR Code generator and start creating your QR Code for free. You can download it in your preferred format and customize it if you want. 
    Note: Static QR Codes are free forever. However, you need to start a 14-day 
    free trial or purchase a plan to create dynamic QR Codes.`,

    what_is_qr_code_title: "What is QR Code?",
    what_is_qr_code: `The QR Code name consists of the initials of the English word Quick Response.
    QR Code is a 2D Code developed by Denso Wave, which allows to encrypt hundreds of character data.
    dimensional barcode type. It consists of black and white pixels as standard.
    The data encrypted on the code can then be decrypted and viewed quite quickly with a smartphone or tablet.`,

    how_can_i_generate_qr_code_title: "How Can I Generate QR Code ?",
    how_can_i_generate_qr_code: `The QR Code name consists of the initials of the English word Quick Response.
    QR Code is a 2D Code developed by Denso Wave, which allows to encrypt hundreds of character data.
    dimensional barcode type. It consists of black and white pixels as standard.
    The data encrypted on the code can then be decrypted and viewed quite quickly with a smartphone or tablet.`,

    what_should_I_consider_when_printing_title: "What Should I Consider When Printing ?",
    what_should_I_consider_when_printing: `If it is to be used on printed documents, high resolution output formats should be used.
    QR Code Matic You can output your QR Codes in vector formats such as AI, EPS and SVG as well as PNG.
    They can be expanded without loss of image quality. Vector format printouts are particularly suitable for large-scale printing applications.
    An app test should always be done with different smartphones and apps before releasing codes.`,

    how_can_QR_codes_be_scanned_title: "How to Scan QR Codes ?",
    how_can_QR_codes_be_scanned: `If it is to be used on printed documents, high resolution output formats should be used.
    QR Code Matic You can output your QR Codes in vector formats such as AI, EPS and SVG as well as PNG.
    They can be expanded without loss of image quality. Vector format printouts are particularly suitable for large-scale printing applications.
    An app test should always be done with different smartphones and apps before releasing codes.`,

    how_to_use_QR_codes_title: "How to Use QR Codes?",
    how_to_use_QR_codes: ` With the widespread use of smartphones, the importance of QR Codes has increased. Playing a key role in marketing campaigns, 
    QR Codes have become indispensable for reaching a wider audience. Nowadays, QR Codes are primarily used for mobile marketing purposes. They can be 
    incorporated into printed materials such as leaflets, posters, catalogs, or business cards, as well as digital content like websites, videos, PDFs, 
    or contact information. The variety of applications is virtually limitless.`,

    can_I_customize_QR_codes_visually_title: "Can I Customize QR Codes Visually?",
    can_I_customize_QR_codes_visually: `Thanks to a high level of error tolerance, it is possible to customize QR Codes to some extent without altering 
    the necessary code structures for readability. For example, you can set the foreground and background colors as gradients or radial patterns, 
    add your logo to the QR Code, or modify the body patterns with reference points. However, always test the readability when visually adjusting your QR Code.`,

    can_I_view_scan_statistics_of_QR_codes_title: "Can I View Scan Statistics of QR Codes?",
    can_I_view_scan_statistics_of_QR_codes: `It is possible to view real-time scanning statistics of your dynamic QR Codes. You can obtain detailed statistics 
    on countries, cities, browsers, devices, age, gender, device resolution, and downloads.`,

    what_file_formats_and_size_should_i_use_for_printing_title:
      "What File Formats and Sizes Should I Use for Printing?",
    what_file_formats_and_size_should_i_use_for_printing: `For large-scale printing applications, it is recommended to use vector outputs to avoid loss of image quality. 
    In addition to the file format, attention should be paid to the size of the QR Code to ensure readability. Especially for static QR Codes, which contain more data, 
    larger sizes should be used. As more content is encoded, the required space increases. For comfortable user interaction, the size of the code used in the application 
    should be at least 2 x 2 cm. There should be at least a 1:10 ratio between the reading distance and the code size. For example, if you want your QR Code to be 
    readable from a distance of 10 meters, it should be at least 1 meter x 1 meter in size. Avoid placing QR Codes on uneven surfaces as brochure or page creases can 
    negatively affect their readability.`,

    where_and_how_to_use_qr_code_title: "Where and How to Use QR Codes?",
    where_and_how_to_use_qr_code: `The usage areas of QR Codes are limitless. QR Codes offer endless possibilities, from sharing personal information to solving 
    marketing and logistics challenges for companies.`,
  },
  WHY_QR_CODE: {
    unlimited_title: "Unlimited",
    unlimited:
      "Enable your users to access unlimited videos, PDFs, messages, information, and all kinds of content with QRCodeMatic.com QR Codes.",

    attractive_title: "Attractive",
    attractive:
      "The QR Code you use naturally attracts attention and arouses curiosity. With the right storytelling, you can increase curiosity levels and enhance customer engagement. Moreover, being results-oriented will lead to a noticeable increase in customer satisfaction.",

    everywhere_is_a_showcase_for_you_title: "Everywhere is a Showcase for You",
    everywhere_is_a_showcase_for_you:
      "Everywhere you place the QR Codes that represent your products becomes your showcase. From other users seeing and liking your products to brochures and business cards offering snacks, every place becomes a living showcase for you.",

    add_on_your_product_title: "Add Your Product Information Brochure to Your Product",
    add_on_your_product:
      "You can stick user manuals, details, instructions, and documents (videos, PDFs, files) that take up several pages and easily become unreadable on tools, devices, consumables, and all equipment you have. Additionally, easily deliver the special permissions and standards you obtain for your products to your users, thereby increasing your reliability.",

    your_successful_sales_representative_title: "Your Successful Sales Representative",
    your_successful_sales_representative:
      "It turns the products in your showcase into recommendable items 24/7 and provides users with a fun and engaging shopping experience with easy access to the products.",

    makes_you_easy_to_reach_title: "Makes You Easy to Reach",
    makes_you_easy_to_reach:
      "By including your entire company profile, as well as various information like your location on Google Maps, it allows customers to call you, send emails, messages, reach your social media accounts, add you to their contacts and address book, or obtain your location information, all with a single QR Code.",

    it_is_social_title: "It is Social",
    it_is_social:
      "It enables the display of all your social media accounts, encourages people to follow your brand, like your page, leave comments, and provide feedback or complaints.",

    lets_you_show_your_creativity_title: "Lets You Show Your Creativity",
    lets_you_show_your_creativity:
      "It is a user-friendly marketing platform for special events, giveaways, campaigns, and discounts. You can create intriguing applications that present your digital gift vouchers, events, and promotional products to your customers. The only limit to these applications is your creativity and imagination.",

    low_cost_title: "Low Cost",
    low_cost:
      "Considering its benefits, it is a very cost-effective promotional and marketing tool. You can review our packages on Qrcodematic.com for more details.",

    durable_title: "Durable",
    durable:
      "Considering its benefits, it is a very cost-effective promotional and marketing tool. You can review our packages on Qrcodematic.com for more details.",
  },
  BILLING: {
    when_can_i_use_my_package_title: "When Can I Use My Package?",
    when_can_i_use_my_package:
      "After making your payment and placing your order, the lock on all features of your plan will be instantly released, and you can start creating new QR Codes or managing your Free Trial Codes directly.",

    can_i_upgrade_to_a_higher_plan_title: "Can I Upgrade to a Higher Plan?",
    can_i_upgrade_to_a_higher_plan:
      "Yes, if you have reached the limit of Dynamic QR Codes or if you want to unlock more features. You can upgrade to a higher plan at any time. We will provide you with a proportional refund.",

    how_long_is_the_contract_period_title: "How Long is the Contract Period?",
    how_long_is_the_contract_period:
      "If the contract is not terminated, it will automatically renew for the duration you have selected.",

    how_do_i_cancel_my_subscription_title: "How Do I Cancel My Subscription?",
    how_do_i_cancel_my_subscription:
      "If you do not want your contract to be renewed, an informal email will be sufficient. We need to receive your cancellation at least 30 days before the end of the original contract period.",
  },
  ANALYTIC: {
    what_kind_of_analytics_reporting_do_you_offer_title: "What kind of analytics reporting do you offer?",
    what_kind_of_analytics_reporting_do_you_offer:
      "Analytics data for redirected URLs used with our URL shortening service is provided in real-time in your account control panel. The data includes date, time, device type, and location for each scan event, presented in a searchable date format. The data is available in both table and graph formats and can be downloaded as an Excel file or a downloadable PDF report. Analytics reporting is available for each unique dynamic QR Code. Click here to see a screenshot or download a sample PDF report.",

    can_analytics_be_used_with_all_your_QR_codes_title: "Can analytics be used with all your QR Codes?",
    can_analytics_be_used_with_all_your_QR_codes: `No. Analytics data can only be collected when you create a dynamic QR Code that allows users to return to our website through the encoded short URL and enables us to gather the necessary data. Many other QR Code generator websites force you to create a dynamic QR Code whether you want to or not, but we have made it optional to ensure that when the QR Code is scanned, it displays the URL of your actual website instead of ours.
      The disadvantage of this is that if you create a static QR Code, the visitor scans the QR Code and then goes directly to the target URL or encoded content without passing through our server first. Since there is no tracking mechanism within the QR Code itself, you will need to use something like Google Analytics on the URL the code points to in order to collect tracking data on our behalf. Our blog post explains how to use Google Analytics with QR Codes.
      If you specifically choose to create a static QR Code, analytics reporting cannot be used for it and cannot be added later - the choice between static and dynamic is a one-time decision and cannot be changed later.`,

    how_can_I_access_my_analytics_reports_title: "How can I access my analytics reports?",
    how_can_I_access_my_analytics_reports: `When you log into your subscriber account, you can access your analytics reports through the link in the top right corner of the account control panel page. In the report history, the 'Manage' tab on the right side of each QR Code record opens up detailed information about that QR Code.
      For any dynamic QR Code you have created, an 'Analytics' link is displayed on the right side of the detailed information list for that QR Code. If the 'Analytics' option is not visible, it means the QR Code you created is a static QR Code, and analytics reporting is not available for it.`,

    are_phone_numbers_and_email_addresses_included_in_analytics_reports_title:
      "Are phone numbers and email addresses included in analytics reports?",
    are_phone_numbers_and_email_addresses_included_in_analytics_reports:
      "No. Due to privacy and security protocols inherent in mobile devices, identifiable user information such as phone numbers and email addresses stored on the phone and used for scanning the QR Code is not accessible in any way.",
  },
  login_note: `<div style="font-weight: 900">IMPORTANT!!!</div> <span class="demo-time">When Trial Period Ends</span> using the inside features
   You can continue to generate <span class="font-weight-bolder">FREE Static QR Code</span> and use your <span class="font-weight-bolder">MEMBERSHIP</span>.`,
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
    MARKET: "Market",
    MYQRCODES: "My QR Codes",
    ALLQRCODES: "All QR Codes",
    ACTIVEQRCODES: "Active QR Codes",
    PASSIVEQRCODES: "Passive QR Codes",
    DELETEDQRCODES: "Deleted QR Codes",
    MASTERQRCODES: "Master QR Codes",
    SUBSCRIPTIONS: "Prices and Packages",
    MYFOLDERS: "My Folders",
    MYMEDIA: "My Media",
    QRFOLDERS: "QR Code Folders",
    ADDNEWFOLDER: "Add New Folder",
    CREATENEWQRCODE: "Create New QR Code",
    MYACCOUNT: "My Account",
    PAIDPLAN: "Paid Plan",
    ORDERHISTORY: "Order History",
    SUBMITTICKET: "Submit Ticket",
    HELP: "Help",
    PACKAGE_HISTORY: "Package History",
    DAY_REMANING: "Days Remaining",
    SCAN: "Scan",
    STATIC: "Static",
    DYNAMIC: "Dynamic",
    PACKAGE_TITLE: "PACKAGE DETAIL",
    CORPARATE: "Corparate",
    TITLE: "Menu",
    BULKPRICE: "Helps to quickly update menu prices",
  },
  QRLIST: {
    STATIC: "Static",
    PREVIEW: "You can preview your created QR Codes.",
    DYNAMIC: "Dynamic",
    BULK_CREATE:
      "You can add new QR Codes under this QR Code and update the contents and designs of the QR Codes under it in bulk.",
    BULK_UPLOAD: "Bulk Upload",
    BULK_UPDATE: "Bulk Update",
    BULK_SETTINGS: "Bulk Settings",
    CLICK_TO_DOWNLOAD: "Click to download",
    CLICK_TO_UPLOAD: "Click to Upload your excel file",
    CLICK_TO_UPDATE: "Click to Update your excel file",
    SELECT_FILE: "Select File",
    DOWNLOAD_SAMPLE_FILE: "Download sample file",
    DOWNLOAD_SUCCESSFUL: "Download is successful!",
    UPLOAD_YOUR_FILE: "Upload your file",
    CLICK_HERE_TO_CUSTOMIZE: "Click here to customize the colors and designs of your QR Code!",
    CLICK_HERE_TO_EDIT: "Click on the icon to edit your card content information!",
    CLICK_HERE_TO_FOLDER_CUSTOMIZE:
      "You can create a folder in the add new cashier field on the left menu and filter the QR Code types you want for your campaigns!",
    NON_TRACKABLE: "Static / Non-trackable",
    MY_QR_CODE: "You are about to delete My Qr Card with id of",
    DOWNLOAD_IN_PDF_FORMAT: "Download in PDF format",
    DOWNLOAD_IN_PNG_FORMAT: "Download in PNG format",
    DOWNLOAD_IN_SVG_FORMAT: " Download in SVG format",
    DOWNLOAD_IN_EPS_FORMAT: "Download in EPS format",
    DESC: `Please test your QR Code on different devices before downloading it. 
    You can use .svg .eps .pdf for printing and .png for your website. In this way, you can use it in print or for design 
    purposes in all your documents, including very large banners. When you complete your checks, your QR Codes are ready for printing.`,
    NOTE: "Note: You can download and open Radial and Gradient QR Code outputs with .svg extension.",
    BULK_UPLOAD_DESC:
      "To be able to create multiple QR Codes at once, you are required to download sample excel file and the fill the fields with your staff information correctly. Each row of data will create a new QR Code. Click the excel icon below to download sample file and use upload button to send your file.",
    BULK_CREATE_DESC: "Download all of your QR Codes at once and edit their content.",
    BULK_UPDATE_DESC: "Download all of your QR Codes at once and edit their content.",
    UPLOAD_YOUR_FILE: "Upload your file",
  },
  CREATEQR: {
    MENU: {
      DYNAMIC: {
        TITLE: "Dynamic QR Codes",
        ITEMS: {
          WEBSITE: "Website",
          RESTAURANT: "Restaurant",
          FEEDBACK: "Feedback",
        },
      },
      STATIC: {
        TITLE: "Static QR Codes",
        ITEMS: {},
      },
    },

    WIZARD: {
      BASIC_INFO: {
        TITLE: "Basic Information",
        CATEGORIES: "Categories",
        CATEGORY_IMAGE: "Category Image",
        SELECT_IMAGE: "Select Image",
        LOADER_TEXT: "",
        FIELDS: {
          key: "Key",
          value: "Value",
          title: "Title",
          text: "Text",
          color: "Color",
          description: "Description",
          video: "Video",
          start: "Start",
          image: "Image",
          end: "End",
          name: "Name",
          number: "Number",
          phone: "Phone",
          buttonText: "Button Text",
          url: "URL",
          BASIC_INFORMATION: "Basic Information",
          SETTINGS: "Settings",
          QR_CODE_TITLE: "QR Code Title",
          SHOW_CONTACT_FROM: "Show Contact Form",
          SHOW_GOOGLE_MAPS: "Show Google Maps",
          SHOW_ADD_TO_CONTACTS: "Show Add To Contacts option",
          SHOW_ADDRESS_FIELD: "Show or Hide Address field",
          ADDITIONAL_INFO: "Additional Info",
          SHOW_PHONE_ICON: "Show Phone Icon",
          SHOW_MAIL_ICON: "Show Mail Icon",
          CONTACT_INFO: "Contact Info",
          FIELDS: "Field",
          YOU_HAVE_TO_FILL_THE_FIELDS_BEFORE_APPLY: "You have to fill the fields before apply",
          SELECT_PDF: "Select PDF.",
          FDF_MENU_IF_YOU:
            "If you want to use unique menus for each restaurant you can add more here. Each restaurant will have their own QR Code.",
          DESCRIPTION_FIELD: "You can type description for the field here",
          CURRENT_RESTAURANTS: "Current Restaurants",
          NO_TITLE: "No Title",
          BOOK_TITLE: "Book Title",
          NEW_RESTAURANT: "New Restaurant",
          PERSONNEL_INFO: "Personnel Info",
          COMPANY_INFO: "Company Info",
          ENABLE_DOCUMENTS_PAGE: "Enable/Disable Documents Page",
          ENABLE_REMINDER: "Enable/Disable Email Reminder",
          ENABLE_COUNTER: "Enable/Disable Counter",
          CUSTOM_FIELDS: "Custom Fileds",
          CALL_LIST: "Call List",
          PERSON: "Person",
          EMERGENCY_CALLS: "Emmergency Calls",
          DOCUMENTS_LIST: "Documents List",
          GROUPS: "Group",
          SCHEDULE_INFO: "Schedule Info",
          DAY: "Day",
          SHOW_SCHEDULE_CALENDER: "Show schedule calendar",
          MANAGER_INFO: "Manager Info",
          MAPS_STYLE: "Map Style",
          TEXT_ADDRESS: "Text for",
          TITLE_ADDRESS: "Title for",
          ADDRESS: "Address",
          SONG: "Song",
          ADD_MORE: "Add More",
          ARTIST: "Artist",
          CITY: "İlçe/Şehir",
          FAX: "Fax",
          ZIP: "Zip code",
          ADD_A_NEW_DOCUMENT: "Add a new document",
          NO_RESTRICTION: "No Restriction",
          ARTIFACT_INFORMATION: "Artifact Information",
          ARTIFACT_INFO: "Artifact Info",
          STORE_INFORMATION: "Store Information",
          PLATFORM: "Popular Platform",
          OTHER_FIELDS: "Other fields",
          RANDOM_LINK: "Random Link",
          SHOW_RANDOM_LINK: "Shows a random item from the list.",
          SHOW_ALL_RANDOM_LINK: "Shows all items in the list.",
          MULTIPLE_LINK: "Multiple Link",
          LINKS: "Links",
          PRODUCT_NAME: "Product Name",
          GO_TO_WEBSITE: "Go To Website",
          SELECT: "Select",
          SELECT_RELATED_CATEGORY: "Select related category",
          SELECT_ICON: "Select Icon",
          brochure_image: "Slider Image",
          general_info: "General Info",
          ABOUT_US_PAGE_INFO: "About Us Page Infromation",
          CONTACT_PAGE_INFO: "Contact Page Information",
          RESERVATION_PAGE_INFO: "Reservation Page Information",
          ABOUT_US_PAGE: "About Us Page",
          CONTACT_PAGE: "Contact Page",
          RESERVATION_PAGE: "Reservation Page",
          BROCHURES: "Brochures",
          BANNER: "Slider",
          type: "Type",
          REDIRECT: "Redirect",
          SONGURL: "Paste song link here...",
          isActive: "Active/Passive",
          proximity: "Proximity",
          sort_order: "Sort order",
          SHOW_MIDDLE_SOCIAL_MEDIA_BUTTONS: "Show Middle Social Media Buttons",
        },
      },
      VALIDATION: {
        VAILD_PHONE_NUMBER: "Please input a valid phone number",
      },
      CUSTOMIZE: {
        TITLE: "Design & Customize",
        FIELDS: {
          CUSTOMIZE_TEXT: "Customize your text",
          CUSTOMIZE_YOUR_PAGE: "Customize your page by selecting color",
          SINGLE_COLOR: "Single Color",
          FIRST_COLOR: "First Color",
          SECOND_COLOR: "Second Color",
          BG_COLOR: "Bg Color",
          GRADIENT: "Gradient",
          COLOR: "Color",
          PRIMARY: "Primary",
          TEXT_COLOR: "Text Color",
          BUTTONS: "Butons",
          CHOOSE_BACKGROUND: "Choose Background",
          IMAGE: "Image",
          CHOOSE_FILE_DROP: "Choose a file or drop it here",
          BASIC_INFORMATION: "Basic Information",
          BODY_OPTION: "Body Option",
          LINEAR: "Linear",
          RADIAL: "Radial",
          TRANSPARENT_BACKGROUND: "Transparent Background",
          CUSTOMIZE_YOUR_PAGE_LAYOUT: "You can customize your page layout here by toggling on/off options below.",
          CONTACT_EMAIL_LABEL: "Email for Contact Form",
        },
      },
      SOCIAL_MEDIA_CHANNELS: {
        TITLE: "Social Media Channels",
        FIELDS: {
          LINK_YOUR_SOCIAL: "Add Your Social Media Links",
          YOU_CAN_REMOVE: "You can remove the social media link by clicking cross",
          ADD_MORE: "Add more",
        },
      },
      MEDIA: {
        TITLE: "Media",
        FIELDS: {
          UPLOAD_PROFILE_PHOTO: "Upload a profile photo of your own",
          PHOTO: "Photo",
          STORY: "Story",
          NOTE: "For the best result please select an image with 1:1 aspect ratio(eg: 300px/300px)",
          CHOOSE_MEDIA: "Choose media from your local storage",
          VIDEO: "Video",
          CHOOSE_STORY_VIDEO: "Choose a video to show on your story",
          YOUTUBE_VIDEO: "Paste youtube link",
          PHOTOS: "Photos",
          CHOOSE_PHOTO: "Choose photos for your story",
        },
      },
      CATEGORY: {
        TITLE: "Categorys & Produtcs",
      },
      WELCOME_SCREEN: {
        TITLE: "Welcome Screen",
        FIELDS: {
          LOADING_SCREEN_LOGO: "Loading Screen Logo",
          SELECT_FROM_MYMEDIA: "Click to Select From My Media",
          SELECT_FROM_ANIMATIONS: "Click to Select From Animations",
          LOADING_SCREEN_BACKGROUND: "Loading Screen Background",
          SINGLE_COLOR: "Single Color",
          GRADIENT: "Gradient",
          YOUR_INFORMATION: "Your Information",
          FIRST_FIELD: "First Field",
          SECOND_FIELD: "Second Field",
        },
      },
      ADVANCED_SETTINGS: {
        TITLE: "Advanced Settings",
        FIELDS: {
          PASSWORD: "Password",
          AGE_BELOW: "Age Below",
          PASS_CODE_HERE: "Paste code here...",
          TIME_ZONE: "Time Zone",
          PICK_START_DATE: "Pick start date",
          CANCEL: "Cancel",
          OK: "Ok",
          STARTING_DATE: "Starting Date",
          ENDING_DATE: "Ending Date",
          PICK_END_DATE: "Pick end date",
        },
      },
      CREATEQR: "Create QR Code",
      UPDATE_CHANGES: "Update Changes",
    },
    FOLLOWALBE: "Followable (Paid)",
    NOT_TRACKED: "Untrackable (Lifetime Free)",
  },
  RESTAURANT: {
    title: "Title: ",
    description: "Description: ",
    cover_image: "Cover Image: ",
    category_icon: "Category Icon: ",
    categories: "Categories",
    category: "Category",
    product: "Product",
    products: "Products: ",
    product_name: "Product name",
    preparation: "Preparation: ",
    calorie: "Calorie",
    ingredients: "Ingredients: ",
    price: "Price: ",
    price_label: "Price label",
    likes: "Likes: ",
    current_categories: "Current Categories",
    add_new_field: "Add New Field",
    add_new_category: "Add New Category",
    add_new_product: "Add New Product",
    add_new_price: "Add",
    please_add_product: "Please add product",
    brochure_slider: "Slider",
    delete_product: "DELETE PRODUCTION",
    delete_category: "DELETE CATEGORY",
    new_category_title: "New Category",
    new_category_description: "Description about the new Category",
    new_product_title: "New Product",
    new_product_description: "Brief decription about the new product",
    status_desc: "Inactive categories are removed from publication.",
    status: "Status",
    detailed_title: ({ named }) => `${named("value")} Details`,
  },
  ERRORS: {
    err_1: "Error; An uknown error occured!",
    err_2: "Error; An uknown error occured while uploading avatar!",
    err_3: "Error; Incorrect link",
    err_4: "Error; This e-mail has already been verified",
    err_6: "Error; Error",
    err_7: "Error; You tried too many times. Try again in 1 min",
    err_8: "Error; Mail is not approved",
    err_9: "Error; Incorrect password",
    err_10: "Error; Not Registered,There is no such account. Check your email and try again",
    err_11: "Error; User agreement not accepted.",
    err_12: "Error; User deleted",
    err_13: "Error; User account suspended.",
    err_14: "Error; Status Inactive",
    err_15: "Error; Your current package has more than 1 year to expire. You can't buy new packs this way",
    err_16:
      "WARNING!! You have a pending transfer transaction. To place a different order, you must first cancel your current order. Would you like to proceed with the cancellation?",
    err_17: ({ named }) =>
      `Error; You have already occupied ${named("value")} mb disk space. Remove some media before uploading new ones`,
    err_18: "Error; This code has already been used",
    err_19: "Error; Invalid code",
    err_20: "Error; This code is expired",
    err_21: "Error; You are not allowed downgrade your active subscription",
    err_22: "Error; Invalid code",
    err_23: "Error; Invalid reset token",
    err_24: "Error; Can not find a user with that email address",
    err_25: "Error; The name field is required",
    err_26: "Error; This name has already been taken",
    err_100: "Error; The email field is required.",
    err_101: "Error; The email field must be of type text.",
    err_102: "Error; Please enter a valid email address.",
    err_103: "Error; The email field must be a maximum of 255 characters.",
    err_104: "Error; This email address is already registered.",
    err_105: "Error; The email address is not accepted. Please enter an accepted email extension.",
    err_106: "Error; The name field is required.",
    err_107: "Error; The name field must be of type text.",
    err_108: "Error; The name field must be a maximum of 255 characters.",
    err_109: "Error; The password field is required.",
    err_110: "Error; The password must contain at least 6 characters.",
    err_111: "Error; The password must contain at least one letter.",
    err_112: "Error; The password must contain at least one digit.",
    err_113: "Error; The country field is required.",
    err_114: "Error; You have selected an invalid country.",
    err_115: "Error; The organization field is required.",
    err_116: "Error; You must accept the terms of use.",
    err_404: "Error; The content you are looking for could not be found",
    err_500:
      "Error; Sorry, An unknown error has occurred. Please try again by refreshing the page. If the error persists, please contact us.",
    err_1001: "Error; Forbidden",
    err_1002: "Error; Your package is finished",
    err_1003: "Error; You have reached your max limit",
    err_1004: "Error; You don't have this QR Code type",
    err_1005: "Error; You exceeded the max number of 100",
    err_1006: "Error; Your link validations have failed",
    err_1007: ({ named }) =>
      `Error; You are not allowed to create more than ${named("value")} QR Codes without subsciption`,
    err_1008: ({ named }) => `Hata; Paketiniz bittiği için en fazla ${named("value")} adet klasör yaratabilirsiniz. `,
    err_2001: "Error; Uknown character",
    err_2002: "Error; Duplicate url",
    err_2003: "Error; An unknown error occured",
    err_2004: "Error; Vars can't be empty",
    err_2005: "Error; Vars should be at least 3 characters long",
    err_2006: "Error; Vars should be less than 30 characters",
    err_2007: "Error; You need to choose image",
    err_2008: "Error; Special characters are not accepted. You can only use numbers and digits.",
    err_2009: "Error; You need to choose file.",
    err_2010: "Error; It must be file.",
    err_2011: "Error; Maximum 25mb file can be uploaded. Please upload a different file.",
    err_2012: "Error; You have already uploaded a file with this name. Please upload a file with a different name.",
    err_2013:
      "Error; You have an ongoing transaction. You will be notified by e-mail that your transaction is complete. You can try again later.",
    err_2014:
      "Error; You can upload up to 10 QR Codes in bulk in the trial package. Please update your package for more.",
    err_2015:
      "Error; Your Excel file must have a maximum of 400 lines for loading at once. Try again by updating your file.",
    err_2016: "Error; You do not have bulk download files. Try again after installation.",
    err_2017: "Error; You have exceeded the bulk upload limit. Please contact us.",
    err_2018: "Error; Please delete at least 1 of your old files so that you can upload a new excel file.",
    err_2019:
      "Error; Inappropriate or redirecting links have been detected in your data. Please try again by changing them.",
    err_2020: "Error; Only excel files with .xslx extension can be loaded. Check your file type and try again.",
    err_2021: "Error; Excel files with a maximum size of 2mb can be uploaded. Please upload a different file.",
    err_2022: "Error; You must have at least 2 QR Codes to proceed.",
    err_2023: "Error; Please select at least 1 sub-qr code.",
    err_2024:
      "Error; To perform this operation, you must have at least 1 main QR code. Please select at least 1 sub-QR code.",
    err_2025: ({ named }) =>
      `Error; A maximum of ${named("value")} MB files can be uploaded. Please upload a smaller file.`,
    err_2026: `Error; Images with a maximum width of 1000 px can be uploaded. Please resize your image so that its width and/or height is no more than 1000px.`,
    err_2027: "Error; The domain format is invalid.",
    err_2028: "Error; This domain has already exist.",
    err_2029: "Error; CNAME record is not found.",
  },
  PAYMENT: {
    unknown_reason: "Unknown reason",
    payment: "Payment",
    successful: "SUCCESSFUL",
    awaiting: "AWAITING",
    failed: "FAILED",
    reason: "Reason",
    payment_successful_desc: `Dear Customer,
     Your order has been received successfully, your package has been credited to your account.
     If you have any questions or concerns, please do not hesitate to contact us. We will be happy to assist you.
     Thank you for your attention and trust!`,
    payment_failed_desc: `Dear Customer,
     Sorry, we have detected an error in your payment and you can get support from us regarding the issue.
     We will be happy to assist you.`,
    payment_awaiting_desc: `Dear Customer,
     Thank you for your order! The final step required to process your order is to complete your payment.
     However, during a check in our system, we found that your payment has not been completed yet.
     Please make your payment to our bank account numbers to complete your payment.`,

    title_success: () => `<strong>Thank You</strong>, Recieved Your Order!`,
    title_fail: () => `<strong>Sorry</strong>, Payment Failed!`,
    title_awaiting: () => `<strong>Thank You</strong>, Recieved Your Order!`,
    err_105: "Payment is canceled by user",
    err_000: "Payment transaction failed",
  },
  SNACKBAR: {
    qrcode_updated: "QR Code is updated!",
    qrcode_created: "QR Code is created!",
    verify_email: "You need to verify your email",
    login_successful: "Login is successful",
    logged_out: "You have been logged out!",
    registeration_successful: "Registeration is successful",
  },
  DIALOG: {
    STEP_BY_STEP_TOUR_TITLE: "Welcome to Step-by-Step Tour",
    STEP_BY_STEP_TOUR_DESC:
      "Hello User! We have realized that it's your first time visiting here. This tour will guide you through the sections and explain how things work. If you would like to skip the tour you can do so by clicking skip button bellow",
    STEP_BY_STEP_TOUR_SKIP: "Skip",
    STEP_BY_STEP_TOUR_START: "Start",
    STEP_BY_STEP_TOUR_CREATE_QR: "Create QR Code",
    STEP_BY_STEP_TOUR_GET_STARTED_CREATE_QR_CODE: "You can use the button above to create a QR Code.",
  },
  POPUP: {
    error_title: "Error!",
    CHOOSE_ONE: "Choose One",
    TRANSPARENT_QR_CODE: "Transparent QR Code",
    STANDART_QR_CODE: "Standart QR Code",
    BANNER_QR_CODE: "Banner QR Code",
    GO_BACK: "Go Back",
    QR_CODE_BACKGROUND_PICTURE: "I would like to be able to put background image of my choice for my QR Code",
    QR_CODE_COLOR: "I would like to be able to customize QR Code shape and colors",
    QR_CODE_BANNER: "I would like to create a banner and customize qr-code size",
    upgradeContent: ({ named }) =>
      `<div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <img width="200px" src="https://vue.qrcodematic.com/backgroundImages/popup01/istockphoto-1176277785-612x612_preview-1686842223.jpg">
      <h1 class="text-h5"><span style="font-weight: 900;" class="button_green--text text-h4">Upgrade</span> <span style="font-weight: 200; color:#919191">to create more</span> <strong class="text-h4 button_green--text">QR Code</strong></h1>
      <p style="font-size: 1rem; max-width: 360px; text-align: justify;">You have reached your limit of <strong class="orange--text">${named(
        "total"
      )} QR Code</strong> for your subscription</strong>. If you need to create more QR Code check out out upgrade plan</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Upgrade <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>`,
    packageController: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5">Your subscription has <strong style="color: tomato">expired!</strong></h1>
      <p style="width: 300px; text-align: justify">Check out our <strong class="button_green--text"><i class="fa-solid fa-rectangle-pro fa-lg"></i> Package</strong> and start using QR Code Matic again.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Upgrade <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    allowedTypeController: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5">Upgrade to use <strong class="orange--text">${named("typeName")}</strong></h1>
      <p>Your current active subscription plan does not include this QR Code type. Upgrade your subscription plan to <strong>Pro</strong> and start using this and other premium QR Codes without limit.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Upgrade <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    getMoreInformation: ({ named }) => `
    <div>
     <p>Language setting allows you to input different information for each language you have choosen. The end user of the QR Code will have language menu on top right on live version if you add more than one language</p>
     <p>Here is how to use this;</p>
     <ol>
      <li>First click the <span class="font-weight-bold">"+"</span> button</li>
      <li>Select the languages you want to add. You can select multiple language. Then select the language for content to be copied from.(This option might not be available for this QR Code type) then click <span class="font-weight-bold">"OK"</span></li>
      <li>Now you can click on the new added language flag icons then click <span class="font-weight-bold">"Active"</span>. Doing it so you can change your content information for this spesific language.</li>
      <li>Click <span class="font-weight-bold">"Delete"</span> to remove any added language. Not that you have to have at least one language</li>
      <li>Clicking <span class="font-weight-bold">"Set as a default"</span> will result in saving that language as default and users will welcomed by this language when they visit your page</li>
     </ol>
    </div>
    `,
    getInfoAboutTitle: ({ named }) => `
    <div>
     <p class="text--justify" style="font-size: 14px">Your QR Code title will be seen as shown below the image on QR Code list page. You will be redirected to this page after you complete creating your QR Code.</p>
     <div class="d-flex justify-content-center" style="width: 100%;">
      <img src="/media/tutorials/qrcode-title.png" style="max-width: 400px; object-fit: cover; border-radius: 5px; "/>
     </div>
     <p style="font-size: 14px" class="mt-2">You can edit QR Code title later on</p>
    </div>
    `,
  },
  BULK: {
    create_new_child: "I want to create new child QR Code(s)",
    update_childs_data: "I want to change the detailed information related to my sub QR codes",
    update_data: "I want to update the information of all my bottom QR Codes",
    zip_request: "I want to download all my QR Codes in SVG format",
    zip_downloaded: "Zip file has been downloaded!",
    download_master: "Download Master Excel File",
    update_config_button_text: "I want to update the color, design and other settings of all my bottom QR Codes.",
    update_data_description: () => `<p style="text-align: center">
     The fields below the master that you want the QR Codes to be updated are as follows:
     select from the boxes. All QR Codes under master are excel you choose
     It will be automatically updated with the settings in the (vcard-bulk-update) file.
  </p>
  <p style="text-align: center">
    For example; Selects the <strong>avatar box</strong>, press the <strong>Update</strong> button
     If you press
     <strong>avatar value</strong> is about to apply to all QR Codes under master
     update is queued.
  </p>`,
    update_config_description: () => `<p>
   This is to apply the changes you have made on <strong>master</strong> to sub-QR Codes at once. All features specified in the master's list are applied to sub-QR Codes.
  <ul>
    <li>All QR Code color settings</li>
     <li>All QR Code designs</li>
  </ul>
</p>
<p>
  If you make the update, the design of all sub-QR Codes will be the same as the master.
</p>`,
    create_description: () => `To create batch QR Code, you can edit the excel file in the download area below.
Drag it and click the <strong class="green--text">INSTALL</strong> button.`,
    update_options_title: "Update Options",
    avatar: "Avatar",
    are_you_sure: "Are you sure?",
    see_qr_codes: "See QR Codes",
    see_qr_codes_tooltip: "You can see the QR Codes underneath and turn them back to the master.",
    background: "Background",
    settings: "Settings",
    advanced_settings: "Advanced Settings",
    update_data_button_text: "Update",
    update_config_button_text: "Update Sub QR Codes",
    show_previously_uploaded: "Show My Previous Excel Uploads",
    reached_upload_limit: "Your upload limit is full. You need to delete one or more files from the list first.",
    upload_error_text:
      "The data you entered in the excel file you uploaded is not in the correct format. Please check the data for the incorrect fields listed below and try to upload again.",
    back_to_upload: "Back to upload",
    select_from_list: "Choose your desired action below",
  },
  NEWS: {
    firstVisitMessage: ({ named }) => `
    <p>Today, we have exciting news to share with you. We have revamped our QR Code system! We have made a series of improvements to offer you a better experience.</p>
    <p>QR Codes have become an indispensable tool for quick and easy information sharing. We are constantly working on enhancing this technology to provide you with the best service.</p>  
    `,

    firstVisitFullMessage: ({ named }) => `
    <p>Today, we have exciting news to share with you. We have revamped our QR Code system! We have made a series of improvements to offer you a better experience.</p>

    <p>QR Codes have become an indispensable tool for quick and easy information sharing. We are constantly working on enhancing this technology to provide you with the best service.</p>  

    <p> Our new QR Code system features a user-friendly design. You will now need to take fewer steps to access our website and generate QR Codes. We have simplified our user interface 
    and saved you from unnecessary clicks. We have redesigned our layout for a fast, smooth, and seamless experience. </p>

    <p>Additionally, we have added new features to make our QR Codes more functional and flexible. You can now personalize the QR Codes you create with more data. 
    You can include links to your website or social media profiles, create menus for your delightful dishes, or generate a vCard containing your email address or phone number. 
    We offer various options to expand the usage of our QR Codes.</p>

    <p>Your security within this wide range of applications is our priority. In our new QR Code system, we have implemented additional security measures to protect your data and information.
     We have established a stronger foundation to ensure the security of your personal and sensitive information.</p>

    <p> The speed of our QR Codes has also improved. Thanks to enhanced algorithms and optimized systems, you will be able to scan your QR Codes faster and complete your tasks more 
    efficiently. We have updated our technological infrastructure to help you make better use of your time.</p>    

    <p> The feedback and needs of our valued users are always important to us. Therefore, we are constantly working to provide you with a better experience on our revamped QR Code system. 
    If you have any questions, suggestions, or feedback regarding the usage of our website, please let us know. Your satisfaction is our success. Welcome!</p>
    
    `,

    HELLO: "Hello, we have been renewed!",
    we_are_improving_to_provide_you_with_better_service: "We are improving to provide you with better service",
  },

  TERMS: {
    terms_text: ({ named }) =>
      `
      <h1>QRcodematic Site Terms of Use</h1>
      <hr>
      <p>Our customers who use this QR Code creation site and create QR Code are deemed to have accepted the following terms. In addition, the web pages on our site and all related pages ('Site') are located in FSM Bulvarı Fethiye Mah. Fazil Sk. No:8 D:2 Nilüfer Bursa is the property of and operated by Medyax Bilişim Hizmetleri (Company). By using and continuing to use the services on the site, you ('User') are subject to the following conditions while using all the services offered on the site; You agree that you have the right, authority and legal capacity to sign a contract in accordance with the laws to which you are bound and that you are over the age of 18, that you have read and understood this contract and that you are bound by the terms written in the contract. This contract imposes the rights and obligations of the parties on the site that is the subject of the contract, and when the parties accept this contract, they declare that they will fulfill the aforementioned rights and obligations completely, accurately, on time, and within the terms requested in this contract.</p>
      <p>This contract imposes the rights and obligations of the parties on the site that is the subject of the contract, and when the parties accept this contract, they declare that they will fulfill the aforementioned rights and obligations completely, accurately, on time, and within the terms requested in this contract.</p>
      <p>
      <h2>1. RESPONSIBILITIES</h2>
        <p> a. The company always reserves the right to make changes on the prices and offered products and services.</p>
        <p> b. The company accepts and undertakes that the member will benefit from the contracted services, except for technical failures.</p>
        <p> c. The user agrees in advance that he will not reverse engineer the use of the site or take any other action to find or obtain the source code of them, otherwise he will be responsible for the damages that may arise before the third parties, and that legal and penal action will be taken against him.</p>
        <p> d. The user shall not produce content that is against general morality and morality, unlawful, injures the rights of third parties, misleading, offensive, obscene, pornographic, injures personal rights, violates copyrights, encourages illegal activities, in his activities, in any part of the site or in his communications, agrees not to share. Otherwise, he is fully responsible for the damage and in this case, the site authorities reserve the right to initiate legal proceedings by suspending or terminating such accounts. For this reason, it will reserve the right to share information requests from jurisdictions regarding activity or user accounts.
to. The relations of the members of the site with each other or with third parties are under their own responsibility.</p>
          <p>e. The relations of the members of the site with each other or with third parties are under their own responsibility.</p>
      </p>
       <h2>2. Intellectual Property Rights</h2>
        <p>2.1. All proprietary or unregistered intellectual property rights such as title, business name, trademark, patent, design, system codes, information and method on this site belong to the site operator and owner company or the person concerned, and are under the protection of national and international law. Visiting this site or using the services on this site does not grant any right to such intellectual property rights.</p>
        <p>2.2. The information on the site cannot be reproduced, published, copied, presented and/or transferred in any way. The whole or part of the site cannot be used on another website without permission. </p>
      <h2>3. Confidential Information</h2>
        <p>3.1. The company will not disclose personal information transmitted by users through the site to third parties. This personal information; It contains all kinds of other information to identify the user such as person name-surname, address, telephone number, mobile phone, e-mail address, and will be referred to as "Confidential Information" for short.</p>
        <p>3.2. User; only promotion, advertisement, campaign, promotion, announcement etc. It accepts and declares that it consents to the company that owns the site to share its communication, portfolio status and demographic information with its affiliates or group companies to which it is affiliated, limited to its use within the scope of marketing activities. This personal information can be used to determine the customer profile within the company, to offer promotions and campaigns suitable for the customer profile, and to carry out statistical studies.</p>
        <p>3.3. Confidential Information can only be disclosed to official authorities if this information is requested by official authorities and when disclosure to official authorities is obligatory in accordance with the provisions of the applicable mandatory legislation.</p>

         <h2>4. No Warranty</h2>
        <p>This contract clause shall apply to the maximum extent permitted by applicable law. The services offered by the Company are provided on an "As Is" and "As Available" basis and with respect to the services implementation (including all information contained therein), express or implied, including all implied warranties of merchantability, fitness for a particular purpose, or non-infringement. No warranties, statutory or otherwise, are made.</p>
         <h2>5.Registration and Security</h2>
        <p>User; accurate, complete and up-to-date registration must give their attention. Otherwise, this contract will be deemed to have been violated and the account may be closed without informing the user. The user is responsible for the password and account security on the site and third-party sites. Otherwise, the company cannot be held responsible for data loss and security breaches or damage to hardware and devices. Before sending your QR Codes to print, you need to test them on several different devices. Our company is not responsible for incorrect printing and / or creation.</p>
       
        <h2>6. Force Majeure</h2>
        <p>Not under the control of the parties; Contractual obligations arising from reasons such as natural disasters, fire, explosions, civil wars, wars, uprisings, public movements, declaration of mobilization, strikes, lockouts and epidemics, infrastructure and internet failures, power cuts (Referred to as "Force Majeure" below). If it becomes unfulfillable by the parties, the parties are not responsible for it.In this period, the rights and obligations of the parties arising from this contract are suspended.</p>
      
      <h2>7. Integrity and Applicability of the Agreement</h2>
        <p>If one of the terms of this contract becomes partially or completely invalid, the rest of the contract will continue to be valid.</p>
      
       <h2>8. Changes to the Contract</h2>
        <p>The company can change the services offered on the site and the terms of this contract, partially or completely, at any time. Changes will be effective from the date of publication on the site. It is the user's responsibility to follow the changes. The user is deemed to have accepted these changes by continuing to benefit from the services offered.</p>
     
     <h2>9. Notification</h2>
        <p>All notifications to be sent to the parties related to this contract will be made through the known e-mail address of the company and the e-mail address specified by the user in the membership form. The user agrees that the address he/she specified while signing up is a valid notification address, that he/she will notify the other party in writing within 5 days in case of changes, otherwise the notifications to this address will be deemed valid.</p>
     
     <h2>10. Evidence Convention</h2>
        <p>In any disputes that may arise between the parties regarding the transactions related to this contract, the books, records and documents of the parties, computer records and fax records will be accepted as evidence in accordance with the Law of Civil Procedure No. 6100, and the user agrees that he will not object to these records.</p>
      
      <h2>11. Dispute Resolution</h2>
        <p>Bursa (Central) Courthouse Courts and Enforcement Offices are authorized to resolve any disputes arising from the implementation or interpretation of this contract.</p>
       
      <h2>12. Pricing</h2>
       <p>When you first register to the system, a package is defined to your account via the Trial Package so that you can test it. The validity period of the Trial Package and the digital business cards you will create in this process is 5 days and you can print out with .png extension. When creating a QR Code, you can choose its type from the static/dynamic option on the final page. QR Code type and type selection is the user's responsibility. Vector prints are only valid for our corporate packages. QRcodematic reserves the changes of Trial Package durations. He has the right to change it at any time. At the end of 5 days, you can continue to use the QR Codes you have created by choosing one of the paid packages. At the end of the period, if you have not upgraded your package, the QR Codes you have created will be deleted. If you do not want your QR Codes to be lost, deleted or inactive, you must have your package renewed one month in advance. If it is not renewed, the codes you have created will be deleted and Qrcodematic will not accept any responsibility in this regard. Do not forget, your QR Codes and digital business cards remain active as long as your package continues.</p>
     
       <h2>13. Account Deletion</h2>
       <p>You can perform the deletion of your account by logging in as a user and selecting the option in the Delete My Account field from the My Account tab in the top menu by selecting Yes, or you can request the deletion of your account by sending it to info@qrcodematic.com with the e-mail address you registered and to be deleted and the reason for it to be processed by us. The thing to remember is that when your account is deleted, all the content and QR Codes you created will be irreversibly lost. By confirming the account deletion, you agree to all such cases.
Before sending the QR Codes you have created to print, make sure that it works by testing it on several devices. Do not print without testing. By logging into the site, creating an account, you are deemed to have read, understood and accepted all these terms.</p>


  `,
  },
  CROPPER: {
    confirm_selected_area: "Apply Image",
    confirm_selection_tooltip: "Confirm the selected area",
    cropper_success: "Successfully Implemented",
  },
  STATISTICS: {
    show_all: "Show All",
    last_x_days: ({ named }) => `Last ${named("days")} days`,
    trial_warning:
      "<strong>DEMO: </strong>Statistical Data shown below are only for demonstration purposes for <strong>Trial Accounts</strong>.",
  },

  HELP: {},
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
    },
  },
  ICONSELECT: {
    animals: "animals",
    landscape: "landscape",
    people: "people",
    vehicles: "vehicles",
    communication: "communication",
    technology: "technology",
    business: "business",
    food: "food",
  },
};
