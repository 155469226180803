/* eslint-disable no-unused-vars */
import logservice from "@/core/services/log.service.js";
import { SEND_SNACKBAR } from "./snackbar.module";

// Actions
export const GET_AUDIT_LOGS = "getAuditLogs";
export const GET_SYSTEM_LOGS = "getSystemLogs";
export const DELETE_SYSTEM_LOG = "deleteSystemLog";

// Mutations
export const SET_AUDIT_LOGS = "setAuditLogs";
export const SET_SYSTEM_LOGS = "setSystemLogs";
const state = {
  auditlogs: [],
  systemLogs: []
};
const getters = {
  getAuditLogs(state) {
    return state.auditlogs;
  },
  getSystemLogs(state) {
    return state.systemLogs;
  }
};

const actions = {
  async [GET_AUDIT_LOGS](context) {
    const response = (await logservice.getAuditLogs()).data.data;
    context.commit(SET_AUDIT_LOGS, response);
    context.dispatch(
      `${SEND_SNACKBAR}`,
      {
        message: "Audit Logs is fetched!",
        color: "green"
      },
      { root: true }
    );
  },
  async [GET_SYSTEM_LOGS](context, data) {
    const response = (await logservice.getSystemLogs(data)).data;
    context.commit(SET_SYSTEM_LOGS, response);
    context.dispatch(
      `${SEND_SNACKBAR}`,
      {
        message: "System Logs is fetched!",
        color: "green"
      },
      { root: true }
    );
  },
  async [DELETE_SYSTEM_LOG](context, id) {
    const response = (await logservice.deleteSystemLog(id)).data;
    context.dispatch(
      `${SEND_SNACKBAR}`,
      {
        message: "System Log is deleted!",
        color: "green"
      },
      { root: true }
    );
  }
};
const mutations = {
  [SET_AUDIT_LOGS](state, payloads) {
    state.auditlogs = payloads;
  },
  [SET_SYSTEM_LOGS](state, payloads) {
    state.systemLogs = payloads;
  }
};
export default {
  state,
  actions,
  mutations,
  getters
};
