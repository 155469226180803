import Api from "@/core/services/Api";
// import JwtService from "@/core/services/jwt.service";

export default {
  register(credentials) {
    return Api().post("api/register", credentials, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  login(credentials) {
    return Api().post("api/login", credentials);
  },
  loginOAuth(hashCode) {
    return Api().get(`api/semiLogin?token=${hashCode}`);
  },
  logout() {
    return Api().post("api/logout");
  },
  forgotPassword(credentials) {
    return Api().post("api/forgot-password", {
      email: credentials,
    });
  },
  fetchUserById(id) {
    const token = localStorage.getItem("id_token");
    return Api().get(`api/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  changeUserName(nameToChange) {
    const token = localStorage.getItem("accessToken");
    return Api().patch(
      "api/profile/",
      {
        name: nameToChange,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  changePassword(oldPass, newPass) {
    const token = localStorage.getItem("accessToken");
    return Api().post(
      "api/users/set_password/",
      {
        current_password: oldPass,
        new_password: newPass,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  refreshToken() {
    const token = localStorage.getItem("refreshToken");
    return Api().post("/api/customers", token);
  },
};
