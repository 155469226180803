import PaymentService from "../payment.service";
import ErrHandler from "@/core/services/errorHandle.service";
// actions
export const INIT_PAYMENT = "payment/initPayment";
export const GET_ENROLLMENT_DATA = "getEnrollmentData";
export const INIT_BANK_TRANSFER = "payment/initBankTransfer";
// mutations
export const SET_ENROLLMENT_DATA = "setEnrollmentData";
const state = {};

const getters = {};

const actions = {
  async [INIT_PAYMENT](context, payload) {
    // const fd = new FormData()

    // for (const [key, value] of Object.entries(payload)) {
    // 	fd.set(key, value)
    // }

    try {
      const response = (await PaymentService.pay(payload)).data;

      // context.commit(SET_ENROLLMENT_DATA, response);
      context.dispatch("auth/fetchUser");
      return response;
    } catch (error) {
      ErrHandler(error);
      return null;
    }
  },
  async [INIT_BANK_TRANSFER](context, payload) {
    try {
      const response = await PaymentService.bankTransfer(payload);
      context.dispatch("auth/fetchUser");
      return response.data;
    } catch (error) {
      ErrHandler(error);
      return null;
    }
  },
};
const mutations = {
  [SET_ENROLLMENT_DATA](state, payload) {
    state.enrollement = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
