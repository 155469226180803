// import Vue from "vue";

//actions
export const SEND_SNACKBAR = "sendSnackbar";

//mutations
export const SET_SNACKBAR = "setSnackbar";
export const PURGE = "snackbar/purge";

const state = {
  isClearing: false,
  snackList: [],
};

const getters = {
  currentSnackList() {
    return state.snackList;
  },
};

const actions = {
  [SEND_SNACKBAR](context, payload) {
    // context.commit(SET_SNACKBAR, payload);
    window.addNewSnack({
      timeout: 4000,
      bottom: "bottom",
      right: "right",
      message: payload.message,
      color: payload.color,
    });
  },
};

const mutations = {
  async [SET_SNACKBAR](state, payload) {
    state.snackList.push({
      timeout: 4000,
      bottom: "bottom",
      right: "right",
      message: payload.message,
      color: payload.color,
    });

    clearSnacks();

    async function clearSnacks() {
      if (state.isClearing) return;
      state.isClearing = true;
      const getLength = () => state.snackList.length;
      const removeSnack = async () => {
        console.log("running removeSnack...");
        state.snackList.shift();
        await timeout(2000);
        console.log("getLength is: ", getLength());
        if (getLength() > 0) removeSnack();
        else state.isClearing = false;
      };

      removeSnack();

      // for (let i = 0; i <= state.snackList.length; i++) {
      //   await timeout(2000);
      //   state.snackList.splice(0, 1);

      //   if (state.snackList.length <= 0) {
      //     state.isClearing = false
      //     return;
      //   }
      // }
    }

    function timeout(delay) {
      return new Promise((resolve) => {
        setTimeout(resolve, delay);
      });
    }
  },
  [PURGE](state) {
    state.snackList = [];
    state.isClearing = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
