import Api from "./Api";
import JwtService from "./jwt.service";
import store from "@/core/services/store";
export const token = JwtService.getToken();

export default {
  /**
   *** @FETCH :: start
   */
  fetchAll() {
    return Api().get("/api/qrcode");
  },
  /* Fetch Qrcode By id */
  fetchQr({ id, params }) {
    let route = `/api/qrcode/${id}`;
    route += !!params ? `?${params}` : `?filter=both`;
    return Api().get(route);
  },

  /* Body */
  fetchBody(payload) {
    let q;
    if (!!!payload) q = 1;
    else q = payload;
    return Api().get(`/api/body?type=${q}`);
  },

  /* Eyeball */
  fetchEyeball(payload) {
    let q;
    if (!!!payload) q = 1;
    else q = payload;
    return Api().get(`/api/eyeball?type=${q}`);
  },

  /* Eyeframe */
  fetchEyeframe(payload) {
    let q;
    if (!!!payload) q = 1;
    else q = payload;
    return Api().get(`/api/eyeframe?type=${q}`);
  },

  /* Frames */
  fetchFrames() {
    return Api().get(`/api/frame`);
  },

  /* Templates */
  fetchTemplates(options) {
    const params = new URLSearchParams(options);
    return Api().get(`/api/template?${params.toString()}`);
  },

  /* Types */
  fetchTypes(type) {
    const p = `?code=${type}`;
    let d = `/api/type`;
    if (!!type) d += p;
    return Api().get(d);
  },

  /* Created types by user */
  fetchUserQrTypes() {
    return Api().post(`/api/user/types`);
  },

  /* Logos */
  fetchLogos(type) {
    let q = type ?? 1;
    return Api().get(`/api/logo?type=${q}`);
  },

  /* User Qr Codes */
  fetchQrCodes({ type, page, status, sort, search, trashed, master, parent_id }) {
    let uri = [];
    if (!!type) uri.push(`type=${type}`);
    if (!!page) uri.push(`page=${page}`);
    if (status != undefined || status) uri.push(`status=${status}`);
    if (!!sort) uri.push(`sort=${sort}`);
    if (!!search) uri.push(`search=${search.toLowerCase()}`);
    if (!!trashed) uri.push(`trashed=${trashed}`);
    if (!!master) uri.push(`master=${master}`);
    if (!!parent_id) uri.push(`parent_id=${parent_id}`);
    uri = "?" + uri.join("&");
    return Api().get(`api/user/qrcode${uri}`);
  },
  /* User Qr Codes */
  fetchUserFolderQrCodes(searchParams, folderId) {
    return Api().get(`api/folder/${folderId}?${searchParams}`);
  },

  /* Single Qrcode */
  fetchStats(vars) {
    return Api().get(`api/${vars}`);
  },

  /* Sub Packages */
  fetchPackages() {
    return Api().get("api/package", {
      headers: {
        "Content-Language": "tr",
      },
    });
  },
  /* Package Details */
  fetchPackageDetails(userId) {
    return Api().get("api/user/simpleLimits/" + userId);
  },

  /* Ratings */
  fetchRatings(id) {
    return Api().get(`api/vote/${id}/votes`);
  },

  /* Sub Package Groups */
  fetchPackageGroups() {
    return Api().get("api/packagegroup");
  },

  fetchContent() {
    return Api().get("api/content");
  },
  /**
   *** @FETCH :: end
   */

  /* Create Qr (standart) */
  storeQrCode(payload) {
    const formData = new FormData();
    let route = "";

    switch (payload.qrType) {
      case "standart":
        formData.set("config", JSON.stringify(payload.config));
        if (!!payload.isParent) formData.set("is_parent", 1);
        route = "api/qrcode";
        break;
      case "transparent":
        formData.set("transparent", 1);
        route = "api/qrcode/transparent";
        break;
      case "banner":
        formData.set("transparent", 2);
        formData.set("config", JSON.stringify(payload.config));
        route = "api/qrcode/banner";
        break;
      default:
        break;
    }
    formData.set("data", JSON.stringify(payload.data));
    formData.set("web_template_id", payload.web_template_id);
    formData.set("type_id", payload.type_id);
    formData.set("type", payload.type);
    if (payload.hasOwnProperty("format")) formData.set("format", payload.format);
    else console.error("-FORMAT- eksik ! ! !");

    return Api().post(route, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // const formData = new FormData();

    // for (const [key, value] of Object.entries(payload)) {
    //   formData.set(
    //     key,
    //     /type|format/gim.test(key) ? value : JSON.stringify(value)
    //   );
    // }

    // // formData.set("type", payload.type);
    // // formData.set("data", JSON.stringify(payload.data));
    // // formData.set("config", JSON.stringify(payload.config));
    // // formData.set("web_template_id", JSON.stringify(payload.web_template_id));

    // return Api().post("/api/qrcode", formData, {
    //   headers: {
    //     "Content-Type": "multipart/form-data"
    //   }
    // });
  },
  /* Create Qr (transparent) */
  createTransQrCode(payload) {
    const formData = new FormData();
    // formData.set("config", "test");
    formData.set("data", JSON.stringify(payload.data));
    formData.set("web_template_id", payload.web_template_id);
    formData.set("type_id", payload.type_id);
    formData.set("type", payload.type);
    formData.set("trasparent", 1);
    if (payload.hasOwnProperty("format")) formData.set("format", payload.format);

    return Api().post("api/qrcode/transparent", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  /* Create Qr (banner) */
  // createBannerQrCode(payload) {
  //   const formData = new FormData();
  //   formData.set("data", JSON.stringify(payload.data));
  //   formData.set("web_template_id", payload.web_template_id);
  //   formData.set("type_id", payload.type_id);
  //   formData.set("type", payload.type);
  //   formData.set("trasparent", 1);
  //   if (payload.hasOwnProperty("format")) formData.set("format", payload.format);

  //   return Api().post("api/qrcode/banner", formData, {
  //     headers: {
  //       "Content-Type": "multipart/form-data"
  //     }
  //   });
  // },

  changeQrType(payload) {
    const formData = new FormData();
    formData.set("data", JSON.stringify(payload.data));
    formData.set("web_template_id", payload.web_template_id);
    formData.set("type_id", payload.type_id);
    formData.set("type", payload.type);
    formData.set("_method", "PUT");
    return Api().post(`api/qrcode/updateType/${payload.qrcodeId}`, formData);
  },

  ////=== DELETE QR CODE === \\\
  deleteQrCode(id) {
    return Api().delete(`api/user/qrcode/${id}`);
  },

  ////=== Create QR  === \\\
  createQr(payload) {
    const formData = new FormData();
    let route = "";

    switch (payload.qrType) {
      case "standart":
        route = "/api/qrcode/create";
        break;
      case "transparent":
        route = "/api/qrcode/transparent/create";
        break;
      case "banner":
        route = "api/qrcode/banner/create";
        break;
      default:
        route = "/api/qrcode/create";
        break;
    }

    formData.set("config", JSON.stringify(payload.config));
    formData.set("vars", payload.vars);
    formData.set("id", payload.id);
    formData.set("type", payload.type);
    if ("format" in payload) formData.set("format", payload.format);
    if (!!payload.frame) formData.set("frame", JSON.stringify(payload.frame));
    if (!!payload.data) formData.set("data", JSON.stringify(payload.data));
    if ("old" in payload) formData.set("old", payload.old);
    if ("domain" in payload) formData.set("domain", payload.domain);
    if (!!payload.logo) {
      formData.append("logo", JSON.stringify(payload.logo));
      formData.append("size", 300);
    }

    return Api().post(route, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  ////=== RE-CREATE Transparent QR CODE === \\\
  reCreateTransQrCode(payload) {
    const formData = new FormData();

    formData.set("config", JSON.stringify(payload.config));
    formData.set("vars", payload.vars);
    formData.set("type", payload.type);
    if (payload.hasOwnProperty("format")) formData.set("format", payload.format);
    // if (payload.image) {
    //   formData.set("image", payload.image);
    // }

    return Api().post("/api/qrcode/createTransparent", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  //===__END__===\\

  ////=== UPDATE QR CODE === \\\
  updateQrCode(payload) {
    // if (!!payload.data) payload = payload.data;

    let route = payload.isBanner ? `/api/qrcode/banner/${payload.id}` : `/api/qrcode/${payload.id}`;
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      if (key === "id" || key === "isBanner" || value == undefined || value == null) continue;

      if (typeof value === "object" && key != "logo" && key != "format") {
        formData.set(key, JSON.stringify(value));
      } else {
        formData.set(key, value);
      }
    }
    formData.set("_method", "PUT");
    return Api().post(route, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  //===__END__===\\

  ////=== UPDATE QR DATA === \\\
  updateQrData(payload) {
    const formData = new FormData();
    formData.set("data", JSON.stringify(payload.data));
    formData.set("vars", payload.vars);
    if (payload.hasOwnProperty("format")) formData.set("format", payload.format);
    formData.set("_method", "PUT");
    return Api().post(`/api/qrcode/${payload.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  //===__END__===\\

  deleteFolder(payload) {
    return Api().delete(`/api/${payload.root}/${payload.folderName}?directory=${payload.root}`);
  },

  ////=== SAVED DESIGN === \\\
  //        START           \\
  getAllUserDesigns() {
    return Api().get("api/design");
  },
  getUserDesign(id) {
    return Api().get(`api/design/${id}`);
  },
  editUserDesign(id) {
    const formData = new FormData();

    formData.set("config", JSON.stringify(payload.config));
    formData.set("svg", JSON.stringify(payload.svg));
    formData.set("frame", JSON.stringify(payload.frame));
    return Api().put(`api/design/${id}`, formData);
  },
  deleteUserDesign(id) {
    return Api().delete(`api/design/${id}`);
  },
  sendUserDesign(payload) {
    const formData = new FormData();

    formData.set("config", JSON.stringify(payload.config));
    formData.set("svg", payload.svg);
    formData.set("svgFrameless", payload.svgFrameless);
    formData.set("placeholder", payload.placeholder);
    // if (!!payload.frame) {
    //   formData.set("frame", JSON.stringify(payload.frame));
    // }
    return Api().post("api/design", formData);
  },

  ////=== SAVED DESIGN === \\\
  //\\        END           //
  bulkUpload({ id, file }) {
    const fd = new FormData();
    fd.append("file", file);
    fd.append("user_id", store.state.auth.user.id);
    return Api().post(`/api/qrcode/createExcelQrCode/${id}`, fd);
  },
  downloadQr(payload) {
    const fd = new FormData();
    fd.set("id", payload.id);
    fd.set("extension", payload.format);
    return Api().post(`api/qrcode/download`, fd, {
      responseType: "blob",
    });
  },
  sendRating({ id, comment, rating, email }) {
    const fd = new FormData();
    fd.set("comment", comment);
    fd.set("email", email);
    fd.set("rating", rating);
    return Api().post(`api/qrcode/${id}/vote`, fd);
  },
  updateRating({ status, commentId }) {
    const fd = new FormData();
    fd.set("status", status);
    fd.set("_method", "PUT");
    return Api().post(`api/vote/${commentId}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteRating({ commentId }) {
    return Api().delete(`/api/vote/${commentId}`);
  },
};
