import axios from "axios";
import Api from "./Api";
import JwtService from "./jwt.service";

export const token = JwtService.getToken();

export default {
  /**
   * mandatory params for formData;
   * @MerchantId
   * @MerchantPassword
   * @VerifyEnrollmentRequestId : payment unique id
   * @Pan : credit card no
   * @ExpiryDate
   * @PurchaseAmount : eg: 5.00
   * @Currency : 949 -> TRY
   * @BrandName : 100 -> VISA
   * @SuccessUrl : url address to redirect on Success
   * @FailureUrl : url address to redirect on fail
   */
  pay(payload) {
    const formData = new FormData();
    formData.set("card_holder", payload.cardName);
    formData.set("card_number", payload.cardNumber);
    formData.set("expiration_month", payload.cardMonth);
    formData.set("expiration_year", payload.cardYear);
    formData.set("cvc", payload.cardCvv);
    formData.set("address", payload.address);
    formData.set("billingName", payload.billingName);
    if (!!payload.companyInfo) formData.set("company", payload.companyInfo);
    if (!!payload.note) formData.set("note", payload.note);
    formData.set("terms", 1);
    if (!!payload.cheque_id) formData.set("cheque_id", payload.cheque_id);
    formData.set("products[0][id]", payload.productId);
    formData.set("products[0][type]", "yearly");
    formData.set("products[0][quantity]", 1);
    // formData.set('type',"yearly");
    return Api().post(`/api/payment/enrollment`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  bankTransfer(payload) {
    const formData = new FormData();
    formData.set("products[0][id]", payload.productId);
    formData.set("products[0][type]", "yearly");
    formData.set("products[0][quantity]", 1);
    formData.set("address", payload.address);
    formData.set("billingName", payload.billingName);
    if (!!payload.companyInfo) formData.set("companyInfo", payload.companyInfo);
    if (!!payload.note) formData.set("note", payload.note);
    formData.set("payment_bank", payload.bankId);
    formData.set("terms", 1);
    if (!!payload.cheque_id) formData.set("cheque_id", payload.cheque_id);

    return Api().post(`/api/payment/bankTransfer`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
