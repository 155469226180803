import Vue from "vue";
import router from "../../router";
import store from "./store/index";
import i18n from "@/core/plugins/vue-i18n.js";

const handler = async (err) => {
  let errText;
  let errStatus;
  let errValue;
  if (typeof err == "object") {
    if (!!err.response.data && !!err.response.data.message && typeof err.response.data.message == "string") {
      errText = err.response.data.message;
      errStatus = err.response.data.statusText;
    } else if (!!err.response.data && typeof err.response.data == "string") {
      errText = err.response.data;
    } else if (typeof err.response?.data === "object" && err.response?.data?.code && err.response?.data?.value) {
      errText = err.response.data.code;
      errValue = err.response.data.value;
    } else {
      console.error("Error: " + err + " is not a string");
      return;
    }
  } else if (typeof err == "string") errText = err;

  let code;
  (function check() {
    let unAuthorized = /authorize|authorization|authenticate|authentication/gim.test(errText);
    let errorCode = /err_[0-9]{4}/g.test(errText);
    let address_required = /The address field is required/g.test(errText);

    if (unAuthorized) code = 1;
    if (errorCode) code = -1;
    if (address_required) code = 3;
  })();

  switch (code) {
    case 1:
      if (!!localStorage.getItem("credentials")) {
        await store.dispatch("auth/autoLogin");
        if (router.history.current.path.includes("createqr")) window.location.reload();
        else router.push({ path: "/createqr" });
      } else {
        store.dispatch("auth/logout");
        if (router.history.current.path.includes("login")) window.location.reload();
        else router.push({ path: "/login" });
      }
      break;
    case 2:
      store.dispatch("util/alert", { title: "Error", text: i18n.t(`ERRORS.${errText}`) });
      break;
    case 3:
      store.dispatch("util/alert", { title: "Error", text: errText });
      break;
    default:
      let errorText = [];
      if (errValue) {
        if (typeof errValue === "string" || typeof errValue === "number") {
          errorText = i18n.t("ERRORS." + errText, { value: errValue }).split(";");
        }
        if (typeof errValue === "object") {
          errorText = i18n.t("ERRORS." + errText, { ...errValue }).split(";");
        }
      } else errorText = i18n.t("ERRORS." + errText).split(";");
      const title = errorText[0];
      const content = errorText[1];
      store.dispatch("util/alert", {
        vHtml: `<div style="width: 100%; border-radius: 10px">
        <section style="height: 50px; width: 100%; position: relative; background: var(--v-error-base); border-radius: 10px 10px 0 0; padding: 1rem">
          <div style="position: absolute; width: 100%; left: 0; top: -15px" class="d-flex justify-content-center">
            <div style="background: #fff; color: var(--v-error-base); width: 30px; height: 30px; border-radius: 50%; text-align: center">
              <i class="fa-solid fa-xmark fa-xl" style="margin-top: 14px"></i>
            </div>
          </div>
          <h1 class="text-h5 white--text text-center" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">${title}</h1>
        </section>
  
        <section class="d-flex flex-column justify-content-center align-items-center" style="background: #fff;padding: 1rem; border-radius: 0 0 10px 10px;">
          <p style="text-align: center;">${content}</p>
          <button id="alert-callback" style="width: 100px; height: 25px; background: var(--v-error-base); color: #fff; border-radius: 5px;">
            ${i18n.t("CLOSE")}
          </button>
        </section>
      </div>`,
        isVCard: false,
        isPersistant: false,
      });
      // store.dispatch("util/alert", {
      //   title: { iconClass: "fa-regular fa-square-xmark fa-lg red--text", text: i18n.t("POPUP.error_title") },
      //   text: i18n.te(`ERRORS.${errText}`) ? i18n.t(`ERRORS.${errText}`) : errText,
      //   sleep: false,
      //   isPersistant: false,
      // });
      break;
  }
  if (window.ym && window.gtag) {
    const eventData = {
      errorCode: errText,
    };
    window.ym(86330776, "reachGoal", "ERROR", eventData);
    window.gtag("event", "ERROR", eventData);
  }
};

export default handler;
