// import AuthenticationService from '@/core/services/AuthenticationService.js';
import PermissionService from "@/core/services/permission.service.js";
import UserService from "@/core/services/user.service.js";
import PackageService from "@/core/services/package.service";
import { SEND_SNACKBAR } from "./snackbar.module";

//actions
export const GET_ROLES = "getRoles";
export const GET_PERMISSIONS = "getPermissions";
export const GET_USERS = "getUsers";
export const GET_PACKS = "getPacks";

//mutations
export const SET_ROLES = "setRoles";
export const SET_PERMISSIONS = "setPermissions";
export const SET_USERS = "setUsers";
export const SET_PACKS = "setPacks";

const state = {
  users: {},
  roles: [],
  packs: [],
  permissions: [],
  admins: [],
};

const getters = {
  currentRoles(state) {
    return state.roles;
  },
  currentPermissions(state) {
    return state.permissions;
  },
  currentPacks(state) {
    return state.packs;
  },
  currentUsers(state) {
    return state.users;
  },
};

const actions = {
  async [GET_ROLES](context) {
    try {
      const response = (await PermissionService.getRoles()).data.data.roles;
      context.commit(SET_ROLES, response);
      context.dispatch(
        `${SEND_SNACKBAR}`,
        {
          message: "Roles data is fetched!",
          color: "green",
        },
        { root: true }
      );
    } catch (err) {
      context.dispatch(
        `${SEND_SNACKBAR}`,
        {
          message: "Roles fetch is failed",
          color: "red",
        },
        { root: true }
      );
    }
  },

  async [GET_PERMISSIONS](context) {
    try {
      const response = (await PermissionService.getPermissions()).data.data.permissions;
      context.commit(SET_PERMISSIONS, response);
      context.dispatch(
        `${SEND_SNACKBAR}`,
        {
          message: "Roles data is fetched!",
          color: "green",
        },
        { root: true }
      );
    } catch (err) {
      context.dispatch(
        `${SEND_SNACKBAR}`,
        {
          message: "Roles fetch is failed",
          color: "red",
        },
        { root: true }
      );
    }
  },

  async [GET_USERS](context, page) {
    try {
      const response = (await UserService.fetchUsers(page)).data;

      context.commit(SET_USERS, response);
      context.dispatch(
        `${SEND_SNACKBAR}`,
        {
          message: "Users list is fetched",
          color: "green",
        },
        { root: true }
      );
    } catch (err) {
      context.dispatch(
        `${SEND_SNACKBAR}`,
        {
          message: err.response.data.message ? err.response.data.message : err.response,
          color: "red",
        },
        { root: true }
      );
    }
  },

  async [GET_PACKS](context) {
    try {
      const response = (await PackageService.fetchPacks()).data.data;
      context.commit(SET_PACKS, response).then(() => {
        context.dispatch(
          `${SEND_SNACKBAR}`,
          {
            message: "Packages are fetched",
            color: "green",
          },
          { root: true }
        );
      });
    } catch (err) {
      context.dispatch(
        `${SEND_SNACKBAR}`,
        {
          message: err.response,
          color: "red",
        },
        { root: true }
      );
    }
  },
};

///////////////  MUTATIONS ////////////////////
///////////////  MUTATIONS ////////////////////
///////////////  MUTATIONS ////////////////////
const mutations = {
  [SET_ROLES](state, payload) {
    state.roles = payload;
  },
  [SET_PERMISSIONS](state, payload) {
    state.permissions = payload;
  },
  [SET_USERS](state, payload) {
    state.users = payload;
  },
  [SET_PACKS](state, payload) {
    state.packs = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
