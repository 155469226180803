//actions
export const TOGGLE_ASIDE = "toggleAside";

//mutations
export const SET_ASIDE = "setAside";

const state = {
  aside: true
};

const getters = {
  currentAside(state) {
    return state.aside;
  }
};

const actions = {
  [TOGGLE_ASIDE](context, value) {
    context.commit(SET_ASIDE, value);
  },

};

const mutations = {
  [SET_ASIDE](state, value) {
    state.aside = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
