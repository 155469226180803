import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#3699FF",
        secondary: "#f9f9f9",
        accent: "#5d78ff",
        error: "#fe3a4b",
        info: "#5578eb",
        success: "#0abb87",
        warning: "#ffb822",
        button_green: "#1ABCB5",
      },
    },
  },
});
