import Api from "./Api";
import JwtService from "./jwt.service";

export const token = JwtService.getToken();
export default {
  getAuditLogs() {
    return Api().get("api/log/audit", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  getSystemLogs(data) {
    return Api().get("api/log/system", {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        page: data
      }
    });
  },
  deleteSystemLog(id) {
    return Api().delete(`api/log/system/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
};
