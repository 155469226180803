Object.defineProperty(String.prototype, "capitalize", {
      value: function () {
        let str = this;
        str = str.split(" ");
        str = str.map((e) => {
          let first = e.charAt(0).toUpperCase();
          let rest = e.slice(1);

          return first + rest;
        });
        return str.join(" ");
      },
    });