import service from "@/core/services/user.service";
import { SEND_SNACKBAR } from "./snackbar.module";

// ACTIONS
export const GET_USER_DETAIL = "getUserDetail";

// MUTATIONS
export const SET_USER_DETAIL = "setUserDetail";


const state = {
  userDetail: {}
};

const getters = {
  getUserDetail(state) {
    return state.userDetail;
  }
};

const actions = {
  async [GET_USER_DETAIL](context, userId) {
    const response = (await service.fetchUser(userId)).data.data;
    context.commit(SET_USER_DETAIL, response);
    context.dispatch(
      `${SEND_SNACKBAR}`,
      {
        message: "User is fetched!",
        color: "green"
      },
      { root: true }
    );
  }
};
const mutations = {
  [SET_USER_DETAIL](state, payload) {
    state.userDetail = payload;
  }
};
export default {
  state,
  actions,
  mutations,
  getters
};
