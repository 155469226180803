import Api from "./Api";
import JwtService from "@/core/services/jwt.service";

export const token = JwtService.getToken();

export default {
  getRoles() {
    return Api().get("api/role", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  getPermissions() {
    return Api().get("api/permission", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
};
