import i18n from "@/core/plugins/vue-i18n.js";
import Api from "../Api";
import ErrorHandler from "../errorHandle.service";
/* ACTIONS */
export const ANIMATE = "animate";
export const ALERT = "util/alert";
export const CLOSE_ALERT = "util/closeAlert";
export const SET_QR_CREATION_STATE = "util/setQrCreationState";
export const SET_ASIDE_STATE = "util/setAsideState";
export const GET_MORE_INFO = "util/getMoreInfo";
export const FETCH_VIDEO_TUTORIAL = "util/fetchVideoTutorial";

/* MUTATIONS */
export const SET_FOOTER = "util/setFooter";
export const PURGE = "util/purge";

const state = {
  isFooter: true,
  qrCreationState: "",
  alertModel: false,
  alertTitle: "Error",
  alertText: "En error occured",
  alertCallback: null,
  alertAsyncCallback: null,
  alertSleep: null,
  alertIsActions: true,
  alertIsPersistant: true,
  vHtml: null,
  asideState: "fixed",
  siteSettings: {
    tutorials: false,
  },
  videoTutorial: {},
};

const getters = {
  isFooterVisible(state) {
    return state.isFooter;
  },
  getLastPage(state, getters, rootState) {
    const qrCodes = getters.currentQrCodes;
    const length = qrCodes.length;
    const pageTotal = Math.ceil(length / 15);
    return pageTotal;
  },
  isAlert(state) {
    return state.alertModel;
  },
  getQrCreationState(state) {
    return state.qrCreationState;
  },
  alertContent(state) {
    return {
      title: state.alertTitle,
      text: state.alertText,
      callback: state.alertCallback,
      asyncCallback: state.alertAsyncCallback,
      sleep: state.alertSleep,
      vHtml: state.vHtml,
      isActions: state.alertIsActions,
      isPersistant: state.alertIsPersistant,
      isCancelable: state.alertIsCancelable,
      isVCard: state.alertIsVCard,
    };
  },
  getAsideState(state) {
    return state.asideState;
  },
  getSiteSettings(state) {
    return state.siteSettings;
  },
  getVideoTutorial(state) {
    return state.videoTutorial;
  },
};

const actions = {
  [SET_QR_CREATION_STATE](ctx, { state }) {
    ctx.state.qrCreationState = state;
  },
  [ALERT](ctx, payload) {
    const el = document.getElementById("custom-alert");
    if (!!el && el.childElementCount <= 0 && ctx.state.alertModel) {
      ctx.state.alertModel = false;
      setTimeout(() => {
        ctx.dispatch("util/alert", payload);
      }, 50);
      return;
    }

    ctx.state.alertTitle = payload.title;
    ctx.state.alertText = payload.text;
    if (!!payload.callback) ctx.state.alertCallback = payload.callback;
    if (!!payload.asyncCallback) ctx.state.alertAsyncCallback = payload.asyncCallback;
    if (!!payload.sleep) ctx.state.alertSleep = payload.sleep;
    if (!!payload.vHtml) ctx.state.vHtml = payload.vHtml;
    if (!!payload.isActions) ctx.state.alertIsActions = payload.isActions;
    if ("isPersistant" in payload) ctx.state.alertIsPersistant = payload.isPersistant;
    if (!!payload.isCancelable) ctx.state.alertIsCancelable = payload.isCancelable;
    ctx.state.alertIsVCard = payload.hasOwnProperty("isVCard") ? payload.isVCard : true;
    ctx.state.alertModel = true;
  },
  [CLOSE_ALERT](ctx) {
    ctx.state.alertModel = false;
    ctx.state.alertTitle = "";
    ctx.state.alertText = "";
    ctx.state.alertCallback = null;
    ctx.state.alertSleep = null;
    ctx.state.alertAsyncCallback = null;
    ctx.state.isActions = true;
    ctx.state.vHtml = null;
    ctx.state.alertIsPersistant = true;
  },
  [ANIMATE](context, payload) {
    const animateCSS = (element, animation, prefix = "animate__") =>
      // We create a Promise and return it
      new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`;
        const node = document.getElementById(element);

        node.classList.add(`${prefix}animated`, animationName);

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd(event) {
          event.stopPropagation();
          node.classList.remove(`${prefix}animated`, animationName);
          resolve("Animation ended");
        }

        node.addEventListener("animationend", handleAnimationEnd, {
          once: true,
        });
      });
    animateCSS(payload.element, payload.animation);
  },
  [SET_ASIDE_STATE](ctx, payload) {
    if (document.body.classList.contains("aside-minimize")) ctx.state.asideState = "minimized";
    else ctx.state.asideState = "fixed";
  },
  [GET_MORE_INFO](ctx, { type }) {
    let alert;
    switch (type) {
      case "title":
        alert = {
          title: i18n.t("information"),
          isPersistant: false,
          vHtml: i18n.t("POPUP.getInfoAboutTitle"),
        };
        ctx.dispatch("util/alert", alert);
        break;
      default:
        break;
    }
  },
  async [FETCH_VIDEO_TUTORIAL](ctx, payload) {
    try {
      const fd = new FormData();
      fd.set("code", payload);
      const res = await Api().post("/api/videoTutorial", fd);
      ctx.commit("SET_VIDEO_TUTORIAL", { fetchedResponse: res.data.data, qrCode: payload });
      return res.data.data;
    } catch (err) {
      throw err;
      // ErrorHandler(err)
    }
  },
};

const mutations = {
  [SET_FOOTER](state, payload) {
    state.isFooter = payload;
  },
  SET_VIDEO_TUTORIAL(state, payload) {
    state.videoTutorial[payload.qrCode] = payload.fetchedResponse;
  },
  [PURGE](state) {
    const defaultState = {
      isFooter: true,
      qrCreationState: "",
      alertModel: false,
      alertTitle: "Error",
      alertText: "En error occured",
      alertCallback: null,
      alertAsyncCallback: null,
      alertSleep: null,
      alertIsActions: true,
      alertIsPersistant: true,
      vHtml: null,
      asideState: "fixed",
      siteSettings: {
        tutorials: false,
      },
      videoTutorial: {},
    };

    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
