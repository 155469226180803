// TURKIYE
export const locale = {
  greet: ({ named }) => `Merhaba <span class="font-weight-bold">${named("userName")}!</span>`,
  turkish: "Turkçe",
  english: "İngilizce",
  true: "Evet",
  false: "Hayır",
  date: "Tarih",
  time: "Saat",
  dynamic: "Dinamik",
  static: "Statik",
  hi: "merhaba",
  create: "oluştur",
  update: "Güncelle",
  change: "Değiştir",
  template: "tasarım",
  qr_list: "qr listesi",
  QR_TITLE: "Kare Kod Başlığı",
  qr_code_list: "qr kod listesi",
  qrcode_document: "Döküman",
  qrcode_document_subtext: "Dökümanlarınızı paylaşın",
  qrcode_website: "Web Sitesi (Link)",
  qrcode_website_subtext: "Linklerinizi yönlendirin",
  qrcode_vcard: "vcard plus",
  qrcode_vcard_subtext: "Kişi bilgileri içeren v-card oluşturun",
  qrcode_smsdynamic: "sms",
  qrcode_smsdynamic_subtext: "SMS Göndericisi Oluştur",
  qrcode_sms: "sms",
  qrcode_sms_subtext: "Sms mesajı oluşturun",
  qrcode_business: "Şirket Kartı",
  qrcode_business_subtext: "Kurumsal sayfanızı oluşturun",
  qrcode_socialmedia: "sosyal medya",
  qrcode_socialmedia_subtext: "Sosyal medya hesaplarınıza yönlendirin",
  qrcode_pdf: "pdf",
  qrcode_pdf_subtext: "PDF görüntüleyici oluşturun",
  qrcode_restaurant: "restoran menü",
  qrcode_restaurant_subtext: "Restoranınıza gelişmiş özel menü oluşturun!",
  qrcode_pdfmenu: "PDF Katalog / menü",
  qrcode_pdfmenu_subtext: "Çoklu PDF yükleyerek katalog yada menü oluşturun",
  qrcode_catalog: "Online Katalog / Menü",
  qrcode_catalog_subtext: "Seçtiğiniz PDF'ten katalog oluşturun",
  qrcode_equipment: "ekipman kartı",
  qrcode_equipment_subtext: "Ekipmanlarınızın durumunu takip edin",
  qrcode_staffcard: "çalışan kartı",
  qrcode_staffcard_subtext: "Çalışanlarınız için personel kartı",
  qrcode_emergency: "acil durum",
  qrcode_emergency_subtext: "Acil durumlar için bilgilendirme kartı",
  qrcode_event: "etkinlik",
  qrcode_event_subtext: "Konser, fuar vb etkinlikler için davetiye uygulaması",
  qrcode_location: "lokasyon",
  qrcode_location_subtext: "Organizasyonunuza ait adreslere tarif oluşturun",
  qrcode_music: "müzik",
  qrcode_music_subtext: "Şarkı ya da ses kaydı yükleyip oynatın",
  qrcode_pet: "evcil hayvan",
  qrcode_pet_subtext: "Evcil hayvanınıza ait bilgilerle takip kartı oluşturun",
  qrcode_museum: "müze",
  qrcode_museum_subtext: "Tarihi eserlere, mekanlara ait bilgi kartı oluşturun",
  qrcode_coupon: "kupon",
  qrcode_coupon_subtext: "Promosyonlarınıza özel kupon dağıtma uygulaması",
  qrcode_rate: "oylama",
  qrcode_rate_subtext: "Kullanıcıların ürününüzü puanlayıp, yorum bıraktığı uygulama",
  qrcode_store: "uygulamalar",
  qrcode_store_subtext: "Uygulamalarınıza ait Apple Store, Google Play linkleri oluşturun",
  qrcode_call: "arama",
  qrcode_call_subtext: "Telefon ile arama kartı oluşturun",
  qrcode_text: "Metin",
  qrcode_text_subtext: "İstediğiniz bir yazıyı paylaşıp, kopyalayabildiğiniz kart",
  qrcode_mail: "e-posta",
  qrcode_mail_subtext: "E-posta iletişim kartı oluşturun",
  qrcode_whatsapp: "whatsapp",
  qrcode_whatsapp_subtext: "Whatsapp iletişim linki oluşturun",
  qrcode_instagram: "instagram",
  qrcode_instagram_subtext: "Instagram iletişim linki oluşturun",
  qrcode_facebook: "facebook",
  qrcode_facebook_subtext: "Facebook iletişim linki oluşturun",
  qrcode_twitter: "X / Twitter",
  qrcode_twitter_subtext: "X / Twitter iletişim linki oluşturun",
  qrcode_linkedin: "linkedin",
  qrcode_linkedin_subtext: "Linkedin iletişim linki oluşturun",
  qrcode_youtube: "youtube",
  qrcode_youtube_subtext: "Youtube videosu paylaşın",
  qrcode_pinterest: "pinterest",
  qrcode_pinterest_subtext: "Pinterest iletişim linki oluşturun",
  qrcode_paypal: "paypal",
  qrcode_paypal_subtext: "Paypal ödeme ya da bağış linki oluşturun",
  qrcode_wifidynamic: "wifi",
  qrcode_wifidynamic_subtext: "Wifi bağlantısına ait bilgileri paylaşın",
  qrcode_websitestatic: "web sitesi (statik)",
  qrcode_websitestatic_subtext: "Websitenizin URL'sini içeren statik kare kod",
  qrcode_vcardstatic: "vcard (statik)",
  qrcode_vcardstatic_subtext: "Telefona .vcf olarak kaydedilebilen kare kod",
  qrcode_phone: "telefon",
  qrcode_phonestatic: "telefon (statik)",
  qrcode_phone_subtext: "telefon",
  qrcode_textstatic: "Metin (statik)",
  qrcode_textstatic_subtext: "Metin (statik)",
  qrcode_smsstatic: "sms (statik)",
  qrcode_smsstatic_subtext: "sms (statik)",
  qrcode_wifistatic: "wifi (statik)",
  qrcode_wifistatic_subtext: "wifi (statik)",
  qrcode_multiplelink: "çoklu link",
  qrcode_multiplelink_subtext: "Rastgele yönlendirme seçeneği ile birden çok link ekleyin",
  qrcode_gallery: "Resim Galerisi",
  qrcode_gallery_subtext: "Resim Galerisi",
  qrcode_customerservice: "Departman İletişim",
  qrcode_customerservice_subtext: "Departman iletişim linkleri oluşturun",
  qrcode_title_wifi: "Wifi",
  qrcode_title_vcard: "Vcard Plus",
  qrcode_title_sms: "Sms",
  qrcode_title_gallery: "Galeri",
  qrcode_title_multiplelink: "Çoklu Link",
  qrcode_title_music: "Muzik",
  qrcode_title_website: "Website",
  qrcode_title_whatsapp: "Whatsapp",
  qrcode_title_email: "E-posta",
  qrcode_title_pdfmenu: "PDF Katalog / Menü",
  qrcode_title_catalog: "Online Katalog / Menü",
  qrcode_title_text: "Metin",
  qrcode_title_coupon: "Kupon",
  qrcode_title_pinterest: "Pinterest",
  qrcode_title_instagram: "Instagram",
  qrcode_title_twitter: "Twitter",
  qrcode_title_rate: "Puanlama",
  qrcode_title_business: "Şirket Kartı",
  qrcode_title_phone: "Telefon",
  qrcode_title_staffcard: "Çalışan Kartı",
  qrcode_title_paypal: "Paypal",
  qrcode_title_store: "Uygulamalar",
  qrcode_title_call: "Arama",
  qrcode_title_location: "Lokasyon",
  qrcode_title_pdf: "PDF",
  qrcode_title_museum: "Müze",
  qrcode_title_linkedin: "LinkedIn",
  qrcode_title_youtube: "Youtube",
  qrcode_title_pet: "Pet",
  qrcode_title_facebook: "Facebook",
  qrcode_title_event: "Etkinlik",
  qrcode_title_equipment: "Ekipman Kartı",
  qrcode_title_emergency: "Acil Durum Kartı",
  qrcode_title_restaurant: "Restorant",
  qrcode_title_socialmedia: "Sosyal Medya",
  qrcode_all: "- Tümünü Göster -",
  page_not_found_text: "Hay Aksi! Aradığınız içerik bulunamadı",
  go_home: "Ana Sayfa",
  link_is_copied_to_clipboard: "Bağlantı kopyalandı!",
  select_pdf_by_clicking: "PDF Dosyasının Üzerine Tıklayarak Aktif Ediniz",
  select_content_by_clicking: "Dosyanın Üzerine Tıklayarak Aktif Ediniz",
  clear_all_fields: "Formu Temizle",
  search_type: "Kart Tipi Seç",
  empty: "Boş",
  click_lock_to_unlock: "Bu alanı değiştirmek için kilit ikonuna basın",
  user_folder: "Kullanıcı Klasörü",
  this_field_is_required: "Bu Alan Gereklidir",
  you_dont_have_any_saved_design: "Tasarımı Kaydedin Ve Sonraki QR Code'lar da Kullanın",
  you_can_save_your_current_design_by_clicking_button_below:
    "Daha sonra oluşturduğunuz QR Code'lar da aynı tasarımı tekrar renkleri, şekilleri seçmeden tek tıklama ile uygulayabilirsiniz. ",
  you_have_to_update_save_this_qrcode_to_be_able_to_use_this_feature:
    "Bu özelliği kullanabilmek için bu qrcode'u kaydetmelisiniz.",
  SAVE_CURRENT_DESIGN: " + MEVCUT TASARIMI KAYDET",
  you_have_to_update_current_changes_first: "Önce mevcut değişiklikleri güncellemelisiniz",
  scan_result_good: "Okunma İyi",
  scan_result_bad: "Okunma Zayıf",
  LANGUAGE: "Dil Seçin",
  YES: "EVET",
  ACTIVE_LANGUAGE: "Aktif Dil",
  ADD_SOME_INFO: "Bilgileri girin",
  CONTACT_INFO: "İletişim Bilgisi",
  FULL_NAME: "Tam adı",
  FIRSTNAME: "Adı",
  LASTNAME: "Soyadı",
  COMPANY_NAME: "Firma",
  COMPANY_ADDRESS: "Şirket Adresi",
  JOB_TITLE: "Görevi",
  JOBTITLE: "Görevi",
  DEPARTMENT: "Departman",
  BLOOD_GROUP: "Kan Grubu",
  FOLDER_NO: "Klasör numarası",
  DATE_OF_START: "Başlangıç tarihi",
  COMPANY: "Şirket",
  COUNTRY: "Ülke",
  STATE: "İlçe",
  MAIL: "E-posta",
  WEBSITE: "Link",
  PHONE: "Telefon",
  SMS: "Sms",
  HOME: "Ana Sayfa",
  WHO_ARE_WE: "Biz Kimiz",
  icon: "Ikon",
  show_more: "Daha fazla",
  remove_this_song: "Bu Şarkıyı Kaldır",
  select_cover_image: "Kapak Resmi Seç",
  select_audio_file: "Ses Dosyasını Seç",
  delete_all: "Tümünü Sil",
  spotify_settings: "Spotify Ayarları",
  light_theme: "Açık Tema",
  dark_theme: "Karanlık Tema",
  you_can_paste_single_song_or_playlist:
    "Tek bir şarkıyı veya çalma listesini yapıştırabilirsiniz. Doğru bağlantı şöyle görünmelidir:",
  add_songs_from_local_files: "Yerel Dosyalardan şarkı ekle",
  add_songs_from_spotify: "Spotify'dan şarkı ekle",
  SETTINGS: "Ayarlar",
  APPLY: "UYGULA",


  crops_image: "Resmi Seçilen Karta Aktar",
  rotate_right: "Sağa Çevir",
  rotate_left: "Sola Çevir",
  upload_image: "Bilgisayarınızdan Resim Yükleyin",
  reset: "Formu Temizle",
  upload: "PC'den Yükle",
  transfer: "Resmi Göster",
  
  adress_icon: "Adres İkonu",
  area_icon: "Alan İkonu",

  card_owner: "Kart Sahibi",
  expires_at: "Tarih",
  mounth: "AY",
  years: "YY",
  full_name: "Ad Soyad",

  type_city_name: "İlçe ve Şehir Giriniz",
  your_zip_code: "Posta Kodu",
  id_number: "TC Kimlik No",
  tax_number: "Vergi Numarası",
  tax_office: "Vergi Dairesi",

  qr_code_type: "QR Code tipini değiştirebilirsiniz. (Önemli!)",

  WHO_ARE_WE_1: `Tam donanımlı, yazılım teknolojileri firmamız, “yapılacak çok iş, yazılacak çok kod var” diyerek 1997 yılında kuruldu. Kurucumuz, 
  müşterilerine hizmet verebileceği, gerçek değerler sunan ve ölçülebilir sonuçlar veren bir yazılım şirketi hayal etti. Bugün hayalimizin çok daha 
  üstünde işlere imza atarak, sizlere hizmet vermeye devam ediyoruz.`,

  WHO_ARE_WE_2: `Bu zamana kadar çok yol kat ettik. Yıllar içinde yetenekli yazılım dehalarına kapılarımızı açtık ve birlikte daha da güçlendik. 
  Biriktirdiğimiz bilgileri bugünkü teknoloji ile harmanlayıp, işimizi severek yapmanın bize kazandırdıklarıyla bu yolculuğumuza devam ediyoruz.`,

  WHO_ARE_WE_3: `Sektördeki bilgi ve tecrübemizle, süreçlere bütünsel yaklaşıp, sürdürülebilir yazılım teknolojileri ile dijitalde gücünüze güç katacağız. 
  Marka Yönetimi, Web Tasarım ve Yazılımı, Dijital Pazarlama Stratejileri, Sosyal Medya Tasarımları, SEM, SEO, E-ticaret Sistemleri ve Özel Yazılım Projeleri 
  hizmetlerimiz ile, markalaşma sürecinde birlikte çalıştığımız firmaları, üretimden satışa, finanstan pazarlamaya, AR-GE’ den satın almaya kadar tüm süreçleriyle 
  sahipleniyoruz.`,

  WHO_ARE_WE_4: `Sunduğumuz hizmetlerin ötesinde, büyüklüğü veya değeri ne olursa olsun her projeye gösterdiğimiz özen ve dikkat aynıdır. İtibarımız, 
  tamamladığımız projeler sayesinde işletmelerinin büyüdüğünü gördüğümüz, memnun müşteriler üzerine kurulmuştur.`,

  WHO_ARE_WE_5: `Herhangi bir yeni projenin ilk adımının dinlemek olduğunu biliyoruz. Neye ihtiyacınız olduğunu, karşılaştığınız sorunları ve geleceğe 
  yönelik planlarınızı tam olarak anlıyoruz. İşe yarayan kapsamlı stratejileri bu şekilde planlıyoruz.`,

  WHO_ARE_WE_6: `Uzun yıllara dayanan tecrübemizle, projenizin ölçeği veya şirketinizin büyüklüğü ne olursa olsun size yardımcı olabileceğimizi biliyoruz. 
  Yol gösterici ilkelerimiz; sürekli gelişim, empati, ayrıntılara dikkat, dürüstlük ve özendir. Kuruluşumuzdan bu yana çalışmaya devam ettiğimiz müşterilerimizin 
  bizimle kalmasının nedeni budur.`,

  WHO_ARE_WE_7: `Ama bizden bu kadar ! Şimdi sizin hayallerinizi, hedeflerinizi, projelerinizi ve yapmak istedikleriniz duymak istiyoruz! Bizimle çevrimiçi işiniz 
  hakkında konuşmak isterseniz, tüm ekip sizi bekliyoruz ve harika fikirlerle doluyuz. Dijital iletişimi seviyor olabiliriz, ancak telefon konuşmalarını da sevmiyor 
  değiliz. info@medyax.com adresine e-posta gönderebilirsiniz.`,

  PHONEHOME: "Ev Telefon",
  PHONEMOBILE: "Mobile Telefon",
  PHONEOFFICE: "Ofis telefonu",
  PHONEHOMECOUNTRYCODE: "Ev telefonu ülke kodu",
  PHONEMOBILECOUNTRYCODE: "Mobil telefon ülke kodu",
  PHONEOFFICECOUNTRYCODE: "Ofis telefonu ülke kodu",
  ABOUT: "Hakkımızda",
  ABOUTUS: "Hakkımızda",
  TEAM: "Takım",
  CONTACT: "İletişim",
  PREVIEW: "QR Code Ön İzleme",
  SUPPORT: "Yardım",
  ADDRESS: "Adres",
  ENTER_LOCATION: "+ Lütfen Adresinizi Yazınız (Çoklu Adres Ekleyebilirsiniz)",
  ENTER_LOCATION_HELP:
    "Lütfen aşağıya adresinizi yazın. Ardından açılan pencereden adresinizi seçiniz. Daha sonra, kırmızı ikonu taşıyarak daha detaylı yerinizi işaretleyebilirsiniz.",
  PLEASE_CHOOSE_LOCATION: "Lütfen önce lokasyon seçin",
  MANAGER_FULL_NAME: "Müdür ismi",
  MANAGER_PHONE: "Müdür telefonu",
  MANAGER_JOBTITLE: "Müdür pozisyonu",
  MANAGER_MAIL: "Müdür email",
  ART_NAME: "Eser ismi",
  ART_ACCESSION_NUMBER: "Keşif numarası",
  ART_ERA: "Dönemi",
  ART_MEASUREMENT: "Ölçüleri",
  ART_DISCOVERY_LOCATION: "Bulunduğu yer",
  ART_ACCESSION_DATE: "Bulunduğu tarih",
  ART_DESCRIPTION: "Eser açıklaması",
  RESTAURANT_ABOUT_US: "",
  RESTAURANT_ADDRESS: "",
  RESTAURANT_PHONE: "",
  RESTAURANT_EMAIL: "",
  NAME: "Ad Soyad",
  BLOODGROUP: "Kan grubu",
  MEDICATION: "Kullandığı ilaçlar",
  PLATENUMBER: "Plakası",
  ALLERGICTO: "Alerji",
  DESCRIPTION: "Açıklama",
  MEDICALRECORD: "Tıbbi kayıt",
  NUMBER: "Numara",
  SERIALNUMBER: "Seri Numarası",
  NEXTMAINTENANCE: "Sonraki Bakım Tarihi",
  LASTMAINTENANCE: "Son Yapılan Bakım Tarihi",
  REPAIRCOMPANY: "Bakım/Kalibrasyon Firması",
  LOCATION: "Lokasyon",
  SHOWREMAINING: "Kalan günleri göster",
  FREQUENCY: "Bakım/Kalibrasyon Periyodu",
  STARTHOUR: "Başlangıç Saati",
  ENDHOUR: "Bitiş Saati",
  ENDDATE: "Bitiş Tarihi",
  STARTDATE: "Başlangıç Tarihi",
  TITLE: "Başlık",
  TITLE1: "İlk Başlık",
  TITLE2: "İkinci Başlık",
  SUBTITLE: "Alt Başlık",
  EMAIL: "Mail",
  YOUTUBE: "Youtube Link",
  GMAP: "Google Maps",
  URL: "Link",
  TEXT2: "İkinci Metin",
  TEXT1: "Text",
  TEXT: "Text",
  BUTTONTEXT: "Button yazısı",
  NOPERIOD: "Periyon Yok",
  LAST_30_DAYS: "Son 30 Gün",
  LAST_90_DAYS: "Son 90 Gün",
  LAST_180_DAYS: "Son 180 Gün",
  SELECTED_TIME: "Tüm Zamanlar",
  OWNER: "Sahip",
  VETADDRESS: "Vet Adres",
  VETPHONE: "Vet Telefon",
  VETEMAIL: "Vet Email",
  VETNAME: "Vet Adı",
  GOOGLELINK: "Google Link",
  VIDEOLIST: "Çoklu Youtube Listesi",
  IOSLINK: "App Store Link",
  HUAWEILINK: "Huawei Gallery Link",
  COUPONDISCOUNT: "İndirim Kuponu",
  COUPONTITLE: "Kupon Başlığı",
  COUPONCODE: "Kupon Kodu",
  PRODUCT: "Ürün",
  WIFINAME: "Wifi Adı",
  CONTENTLIST: "İçerik Listesi",
  CONTENTIMAGES: "İçerik Resimleri",
  PASSWORD: "Şifre",
  USER: "Kullanıcılar",
  BTNTEXT: "Buton Yazısı",
  TEXT: "Metin",
  FILE: "File",
  ["AD SOYAD"]: "Ad Soyad",
  BÖLÜMÜ: "Bölümü",
  GÖREVI: "Görevi",
  TELEFONU: "Telefonu",
  ["KAN GRUBU"]: "Kan grubu",
  ["İŞE GIRIŞ TARIHI"]: "İşe giriş tarihi",
  ["SICIL NO"]: "Sicil no",
  RESPONSIBLESTAFFPHONE: "Sorumlu Personel",
  CITY: "İlçe ve Şehir",
  FAX: "Fax",
  ZIP: "Posta Kodu",
  ZIPCODE: "Posta Kodu",
  FAXCOUNTRYCODE: "Fax Ülke Kodu",
  MESSAGE: "Mesaj",
  SSID: "SSID",
  ISHIDDEN: "Gizli",
  NETWORKTYPE: "Network Tipi",
  PW: "Parola",
  SECURITY: "Gizlilik",
  TOPICON: "Üst İkon",
  MIDDLEICON: "Orta Icon",
  GOOGLE: "Google Play Link",
  APPLE: "App Store Link",
  HUAWEI: "Huawei Gallery Link",
  COUNTRYCODE: "Ülke Kodu",
  ISWIFIHIDDEN: "Wifi Görünürlük",
  NEW: "Yeni!",
  NO_TITLE: "Başlık Yok",
  ACTIVE: "Aktif",
  active: "Aktif",
  PASSIVE: "Pasif",
  passive: "Pasif",
  status: "Durum",
  UNIQUE_SCAN: "Eşsiz Tarama",
  DOWNLOAD: "İndirmeler",
  SCAN: "Toplam Tarama",
  PRINT: "Yazdırma",
  VIEW: "Görüntüleme",
  ISTATISTICS: "İstatistikleri AKTİF ederek detaylı istatistik bilgileri alabilirsiniz.",
  DELETE: "Sil",
  ACTIVE: "Pasif yada Aktif yaparak istediğiniz zaman QR Code'nuzu durdurup çalıştırabilirsiniz.",
  RENAME: "İsim Değiştir",
  SEE_PACKAGE: "Paket Detayı",
  CANCEL: "İptal",
  LIVE: "Online",
  OK: "Tamam",
  ARE_YOU_SURE: "Emin misin?",
  ERROR: "Hata",
  ATTENTION: "Dikkat!",
  SUCCESS: "İşlem Başarılıdır!",
  DOWNLOAD_ERROR: "Yüklenirken hata oluştu",
  CLOSE: "Kapat",
  SORT_BY: "Sıralama",
  MOST_RECENT: "En Yeni",
  LEAST_RECENT: "En Son",
  RECENTLY_UPDATE: "Son Güncelleme",
  LEAST_SCANNED: "En Az Taranan",
  MOST_SCANNED: "En Çok Taranan",
  SEARCH: "Arama",
  UPLOAD: "Dosya Yükleme",
  BACK: "Geri",
  NEW_FOLDER: "Yeni Klasör",
  IMAGE_QUALITY: "QR Code Okuma Kalitesi",
  LOW: "Düşük",
  MEDIUM: "Orta",
  GOOD: "İyi",
  HIGH: "Yüksek",
  BACK_TO_DEFAULTS: "Klasik QR Kod'a Dön",
  SAVE_QR_CODE: "QR Kodu Kaydet",
  SAVE: "Kaydet",
  RESET: "Sil",
  SHORT: "Kısa",
  URL: "Link",
  SEND: "Kaydet",
  SCAN_QR: "Okumayı Test Et",
  SAVED_DESIGNS: "Şablon Olarak Kaydet",
  LOGOS: "Logolar",
  SHOW_ALL: "Tümünü Gör",
  ANIMATION: "Animasyonlu",
  STATIC: "Statik",
  EYE_BALL: "İç Referans",
  BODY: "Gövde Şekilleri",
  EYE_FRAME: "Dış Referans",
  FRAME: "Çerçeve",
  TEMPLATES: "QR Code Tasarımları",
  ADVENCED_LOGO_SETTING: "Gelişmiş Logo Ayarları",
  GO_BACK: "Geri Dön",
  SELECT: "Seçiniz",
  SINGLE_COLOR: "Tek Renk",
  CUSTOMIZE_INDIVIDUALLY: "Özelleştir",
  CHANGE_REFERANCE_POINT: "Referans Noktalarını Değiştir",
  NEED_HELP: "Yardıma ihtiyacınız mı var?",
  FRAME_SETTINGS: "Çerçeve Ayarları",
  DEFAULT_LANGUAGE: "Varsayılan Dil",
  DELETE_ALL: "Tümünü Sil",
  ACTIVATE_ALL: "Tüm Aktifler",
  DE_ACTIVATE_ALL: "Tüm Pasifler",
  OPTION: "Özellikler",
  DISAGREE: "Sayfada Kal",
  AGREE: "Kabul Et",
  FIRSTSECTIONTITLE: "Alan Başlığı",
  you_have_unsaved_changes: "Kaydedilmemiş değişiklikleriniz mevcut",
  you_have_unsaved_changes_desc: "Hala sayfadan ayrılmak istiyor musun?",
  approve_comment: "Yorumu Onayla",
  disapprove_comment: "Yorumu Reddet",
  warning: "Uyarı!",
  add_new_languages: "Yeni Dil Ekle",
  get_more_information_about: "Dil ayarları hakkında daha fazla bilgi",
  copy_content_from_description: "Burada seçtiğiniz dilin içeriği eklenecek yeni dillere uygulanacaktır",
  copy_content_from: "İçeriği kopyalanacak dil",
  you_are_about_to_delete_the_category: "Kategoriyi silmek üzeresiniz",
  are_you_sure_you_want_to_continue:
    "Devam ederseniz, seçtiğiniz PDF ile birlikte bu kategori verilerini de kaybedeceksiniz. Devam etmek istediğinizden emin misiniz?",
  you_at_least_need_to_have_1_category: "En az 1 kategoriniz olması gerekiyor",
  dark_mode: "Karanlık Mod",
  pdf_is_processing: "Pdf Yükleniyor..",
  image: "Resim",
  browse_my_media: "Medyalarımı incele",
  my_media: "Medyalarım",
  my_media_subtext: "Medyalarını yönet",
  my_domains: "Domain'lerim",
  my_domains_subtext: "Domain'lerini yönet",
  default_domain: "VARSAYILAN DOMAIN",
  not_verify_domain: "DOĞRULANMAMIŞ DOMAIN",
  verify_domain: "DOMAIN DOĞRULA",
  make_deafult_domain: "VARSAYILAN YAP",
  default_status: "Varsayılan Durum",
  domain_add: "DOMAIN EKLE",
  animation: "Animasyon",
  background_color: "arkaplan resmi",
  choose_design: "Tasarım Seç",
  select_from_my_media: "medyalarımdan resim / logo seç",
  select_from_animation: "animasyonlardan seç",
  age_restriction:
    "Uygulamanız için, kullanıcılarımızın güvenliğini ve deneyimini sağlamak amacıyla yaş kısıtlaması uygulayabilirsiniz. Uygulamamıza erişmek için, kullanıcıların yaşlarını doğrulamaları gerekmektedir. Bu, uygulamamızın belirli yaş gruplarına yönelik içerikler sunması ve yetişkinlere özel özellikleri sınırlaması anlamına gelmektedir.",
  age_restriction_text: "Yaş Kısıtlaması",
  age_restriction_tooltip: "Belli yaşın altındaki kullanıcılar için uyarı",
  analytics: "analizler",
  expiration_date: "bitiş tarihi",
  device: "cihaz",
  personal_info: "Kişisel Bilgiler",
  preparing_template: "",
  RESERVATIONEMAIL: "Rezervasyon mail'i",
  locked: "Kapalı",
  RESTORE: "Geri Al",
  current_password: "Güncel Parola",
  new_password: "Yeni Parola",
  verify_password: "Parolayı Onayla",
  time_limit: "Zaman Sınırlaması",
  pdf_is_processing: "Pdf işleniyor",
  field_required: "Bu alan gerekli",
  start_typing: "Yazmaya başlayın...",
  processing_pdf: "PDF dosyası işleniyor",
  general_settings: "Genel Ayarlar",
  REMINDER: "Hatırlatıcı",
  WEBURL: "Website",
  NONE: "Yok",
  MINUTES: "dakika kala",
  HOURS: "saat kala",
  DAYS: "gün kala",
  WEEKS: "hafta kala",
  COMPLETE: "Tamamla",
  type_your_first_name: "Adını yaz",
  type_your_last_name: "Soyadını yaz",
  type_your_address: "Adresini yaz",
  your_company_info: "Şirket bilgileriniz",
  order_summary: "Sipariş Özeti",
  order_desc: "Seçilen paket bilgileri özetidir.",
  annual: "Yıllık",
  subtotal: "Ara Toplam",
  tax: "Vergi",
  coupon_discount: "Kupon indirimi",
  total: "Toplam Tutar",
  package_price: "Paket Fiyatı",
  package_discount: "Paket İndirimi",
  type_your_discount_code: "İndirim kodunu gir",
  coupon_code: "İndirim kodu",
  use_coupon_code: "Kodu kullan",
  cancel_coupon: "Kodu iptal et",
  i_agree_to: "Sözleşmeyi Okudum Kabul Ediyorum",
  go_back: "GERİ GİT",
  month: "Ay",
  year: "Yıl",
  day: "Gün",
  creditcard_number: "Kart Numarası",
  creditcard_name: "Kart İsmi",
  creditcard_expiration_date: "Son Kullanma Tarihi",
  creditcard_cvv: "CVC",
  terms_of_use: "Kullanım Şartları",
  current_plan: "Geçerli Plan",
  active_package: "Aktif Paket",
  started_at: "Başlangıç",
  ends_at: "Bitiş",
  remaining_time: "Kalan Süre",
  package_content: "Paket İçeriği",
  newspaper: "Newspaper",
  campaign_start: "Başlangıcı",
  campaign: "Baskı Bilgisi",
  campaign_end: "Bitişi",
  start_date: "Başlangıç",
  end_date: "Bitiş",
  are_you_sure: "Emin misin",
  campaign_info: "Kampanya Bilgisi",
  print_style: "Baskı Tipi",
  print_amount: "Baskı Adedi",
  advanced_statistics: "Gelişmiş İstatistikler",
  advanced_statistics_desc: "Gelişmiş istatistikler aktif değil. Etkinleştirmek ister misiniz?",
  change_range: "Filtre",
  select_start_and_end_dates: "Başlangıç ve bitiş tarihlerini seçin",
  language_settings: "Dil Ayarları",
  pdf_info: "Bu seçenek seçildiğinde kullanıcılar herhangi bir sayfa görmeyecek ve pdf dosyasına yönlendirilecektir.",
  pdf_download: "Aktif olması için pdf yüklemeniz gerekir.",
  general_settings_link: "Daha fazla bilgi almak için buraya tıklayın.",
  email_address_to_be_sent: "İletişim formunun gönderilecek mail adresi",
  general_settings_desc:
    "Qr kod başlığı, sahip olduğunuz diğer qr kodlardan ayırt etmek için sadece size gösterilir. Kullanıcılar bunu görmeyecek ve bunu değiştirmek sayfanızı değiştirmeyecektir.",
  submit: "Gönder",
  invalid_email: "Geçersiz email",
  new_qr_code: "Yeni Qr Kod Oluştur",
  days_remaining: "Kalan Gün",
  full_name: "Ad Soyad",
  phone: "Telefon",
  email: "Eposta",
  text: "Yazı",
  link: "Bağlantı",
  desc_message: "Lüften açıklama yazınız...",
  how_many_qrcodes_you_need: "Kaç adet QR koduna ihtiyacınız var",
  request_custom_package: "Özel paket talebi",
  communication: "İletişim",
  account_privacy: "Hesap Silme",
  deactivate_your_account: "Hesabınızı devre dışı bırakın",
  default_language: "Bu, varsayılan diliniz olacak",
  save_changes: "Kaydet",
  cancel: "Vazgeç",
  avatar: "Profil Resmi",
  update_your_personal_information: "Kişisel bilgilerinizi güncelleyin",
  allowed_file_types: "Kabul edilen uzantılar",
  change_password: "Şifre Değiştir",
  account_holder: "Hesap Sahibi",
  account_information: "Hesap Bilgileri",
  FIRSTBTNTEXT: "İlk Buton",
  SECONDBTNTEXT: "İkinci Buton",
  request_price: "Özel Teklif İsteyin",
  make_request: "TEKLİF İSTE",
  gender: "Cinsiyet",
  male: "Erkek",
  female: "Kadın",
  cell_phone: "Cep telefonu",
  invalid_phone: "Geçersiz telefon",
  invalid_mail: "Geçersiz mail adresi",
  invalid_website: "Geçersiz adres",
  invalid_name: "Geçersiz isim",
  dinamik_qr_kod_fiyatları: "Dinamik QR Kod Fiyatları",
  restaurant_menü_qr_kod_fiyatları: "Restaurant Menu QR Kod Fiyatları",
  note: "Not",
  user_profile: "Kullanıcı Profili",
  sign_out: "Çıkış yap",
  signing_out: "Çıkış yapılıyor",
  account_settings_and_more: "Hesap ayarları ve fazlası",
  my_profile: "Profilim",
  active_subscriptions: "Aktif Paketlerim",
  active_subscriptions_subtext: "Aktif kullanılan paketlerim",
  SELECT_FROM_THE_LIST: "Listeden Kart Tipi Seçiniz",
  package_name: "Paket Adı",
  device_list: "Cihaz Listesi",
  details: "Detaylar",
  detailed_chart: "Ayrıntılı Grafik",
  browser_list: "Tarayıcı Listesi",
  city_list: "Şehir Listesi",
  country_list: "Ülke Listesi",
  os_list: "İşletim Sistemleri",
  age_profile: "Yaş Listesi",
  ip_list: "Ip Listesi",
  gender_profile: "Cinsiyet Profili",
  device_resolution_profile: "Cihaz Çözünürlük Profili",
  last_day: "Günlük",
  last_week: "Haftalık",
  last_month: "Aylık",
  last_year: "Yıllık",
  page_view: "Sayfa Görüntüleme",
  unique_visitor: "Tekil Ziyaretçi",
  total_view: "Toplam Görüntüleme",
  total_visit: "Toplam Ziyaret",
  delete_staticstics: "İstatistik verilerini silmek istediğinizden emin misiniz?",
  avarage_duration_visitors_stay: "Ziyaretçilerin kaldığı ortalama süre",
  total_number: "Toplam İstatistikler",
  sorry_im_leaving: "Üzgünüm Ayrılıyorum!",
  sorry_im_leaving_desc:
    "Hesabınızı silmek üzeresiniz. Devam ederseniz, verileriniz kalıcı olarak silinecek ve onları geri kurtaramayacaksınız. Hala devam etmek istiyor musun?",
  last_30_days: "Son 30 gün",
  loading: "Yükleniyor",
  klasor_name: "Klasör adı",
  delete_comment: "Yorumu Sil",
  are_you_sure_you_want_to_delete_this_comment: " Bu yorumu silmek istediğinden emin misin?",
  if_you_delete_this_comment: "Bu yorumu silerseniz daha sonra kurtaramazsınız",
  CREATE: "Oluştur",
  Upgrade: "Yükselt",
  no_content: "'YENİ KLASÖR' oluşturun yada 'DOSYA' yükleyin.",
  size: "Boyut",
  scan: "Tarama",
  limit: "Limit",
  subject: "Başlık",
  message: "Mesaj",
  qrcode: "Kare kod",
  allow_this_to_be_shared: "Bunun diğer kullanıcılarla paylaşılmasına izin veriyorum",
  package_limit: "Paket Limiti",
  rows_per_page: "Sayfa başına satır",
  coupon_discount: "Kupon İndirimi",
  rows_per_page: "Sayfa satır sayısı",
  your_subscription_has_expired: "Paketinizin süresi doldu",
  two_ways_to_keep_using: "Kullanmaya devam etmek için",
  contact_us: "Bize Ulaş",
  upgrade_now: "Şimdi Yükselt",
  or: "YA DA",
  no_data: "Gösterilecek veri yok",
  list_is_empty: "Liste boş",
  recaptcha_error: "Lütfen robot olmadığınızı kanıtlayın",
  if_you_delete_this_qr_code: "Bu qr kodunu silerseniz, onunla birlikte verileri de kaybedersiniz.",
  delete_all_childs_alert_text: ({ named }) =>
    `Bu geri alınamaz işlem master altındaki ${named(
      "amount"
    )} adet kare kodunuzu silecek. Lütfen devam etmeden önce bu kare kodları silmek istediğinize emin olun.`,
  qr_codes_that_are_not_recovered: "20 gün içinde geri alınmayan QR Code'lar tamamen silinir.",
  changing_URL_will_create_a_new_qr_code:
    "URL'yi değiştirmek yeni bir qr kodu oluşturacak ve devam ederseniz bu ön değişikliğin basılı versiyonuna erişilemeyecek.",
  attention: "Önemli! Dikkat!",
  valid_in_dynamic: "Dinamikte Geçerli",
  you_can_not_add_more_than_connections: ({ named }) => `${named("amount")}'den fazla bağlantı ekleyemezsiniz.`,
  If_you_delete_this_language_you_will_lose_all_the:
    "Bu dili silerseniz, onunla ilişkili tüm verileri kaybedersiniz. Devam etmek istediğine emin misin?",
  loading_items: "Veriler yükleniyor",
  your_profile_data_has_been_successfuly_updated: "Profil verileriniz başarıyla güncellendi!",
  your_package_will_be_processed_after_your_payment_has_been_received:
    "Ödemeniz gerçekleştikten sonra paketiniz işleme alınacaktır.",
  verify_password_does_not_match: "Parola eşleşmiyor, doğrulayın",
  please_fill_all_fields: "Lütfen tüm alanları doldurun",
  text_must_be_less_than_2500_characters: "Metin 2500 karakterden az olmalıdır",
  title_must_be_less_than_80_characters: "Başlık 80 karakterden az olmalıdır",
  must_be_less_than_80_characters: "must be less than 80 characters",
  must_be_less_than_200_characters: "200 karakterden az olmalıdır",
  must_be_less_than_100_characters: "100 karakterden az olmalıdır",
  you_need_to_chose_a_logo_or_toggle_off_in_media_settings:
    "Pc'den resim seçebilir, seçilen resmi kırmızı butona tıklıyarak kartta gösterimini sağlayabilirsiniz. 'Medya' ayarlarında bir resim seçmeniz veya fotoğraf sekmesini kapatmanız gerekiyor",
  is_required: " gereklidir",
  btnText: "Buton Yazısı",
  title_is_required: "Başlık Gereklidir",
  this_url_is_invalid: "Bu URL geçersizdir",
  please_input_a_valid_phone_number: "Lütfen geçerli bir telefon numarası girin",
  you_need_to_chose_start_or_end_date: "Başlangıç veya bitiş tarihini seçmeniz gerekiyor",
  you_need_to_fill_the_required_fields: "Gerekli alanları doldurmanız gerekiyor",
  selected_file: "PDF Seçiniz",
  no_file: "Dosya Yok",
  no_link: "Link Bağla",
  add_more_button_text: "Yeni Alan Ekle",
  go_to_main_page: "Anasayfaya Dön",
  new_address: "Yeni Addres",
  information: "Bilgilendirme",
  description_for_the_address: "Adres  Açıklaması",
  you_can_not_add_more_than_10_emergency_number: "10'dan fazla acil durum numarası ekleyemezsiniz",
  verify_password_does_not_match: "Parola eşleşmiyor, doğrulayın",
  please_fill_all_fields: "Lütfen tüm alanları doldurun",
  text_must_be_less_than_2500_characters: "Metin 2500 karakterden az olmalıdır",
  title_must_be_less_than_80_characters: "Başlık 80 karakterden az olmalıdır",
  must_be_less_than_200_characters: "Metin 200 karakterden az olmalıdır",
  must_be_less_than_500_characters: "Metin 500 karakterden az olmalıdır",
  you_need_to_chose_a_logo_or_toggle_off_in_media_settings:
    "Pc'den resim seçebilir, seçilen resmi kırmızı butona tıklıyarak kartta gösterimini sağlayabilirsiniz. 'Medya' ayarlarında bir resim seçmeniz veya fotoğraf sekmesini kapatmanız gerekiyor.",
  is_required: " gereklidir",
  this_is_required: "Bu alan gereklidir",
  invalid_url: "Geçersiz URL",
  continue: "ŞİMDİ OLUŞTUR",
  direct_download: "Direct Download (Kare kod okutulduktan sonra doğrudan kullanıcının telefonuna indirir)",
  REMAININGCOUNTERVISIBLEFORLASTAMOUNTDAYS: "Sayaç __ günden itibaren gösterilsin",
  select_pdf_for_each_language:
    "'PDF Seçiniz' buttonuna tıklayın. Yeni Klasör oluşturun ve dosyanızı yükleyin, yüklenen dosyayının üzerine tıklayın PDF işlenecektir. Her dil için PDF dosyası eklemeniz gerekiyor. Bu dillerde PDF dosyası eklenmemiş: ",
  missing_locations_for_languages:
    "Lütfen ADRESİNİZİ kopyala-yapıştır şekilde kullanmayın! Doğru ADRES seçebilmek için adresinizi YAZARAK ARAYIN ve AÇILAN MENÜDEN SEÇİN!",

  drag_drop: "Sürükleyerek Sıralama Değiştir",
  change_order: "Sıralama Değiştir",
  caption: "Alt Başlık",
  image: "Fotoğraf",
  add_new: "Yeni Ekle",
  delete_selected: "Seçilenleri Sil",

  your_own: "Kendi",
  logo: "Logonu",
  add: "Ekle",

  qrcode_static_subtext: "Statik kodunuzu oluşturmak için seçiniz.",

  type_something_here: "Buraya açıklama yazabilirsiniz",

  start_program: "Başlangıç",
  end_program: "Bitiş",

  billed_annually: "Yıllık Ödenir",
  no_folder: "Klasör Yok",

  I_WANT_TO_GET_INFORMATION_ABOUT_THE_PRODUCTS: "ÜRÜNLER HAKKINDA BİLGİ ALMAK İSTİYORUM",

  keep_track:
    "Bu kare kodu kaç kullanıcının taradığını takip edin ve <strong>Gelişmiş İstatistikler</strong></br></br>'i kullanarak sitenizi karşılaştırın. <strong>Gelişmiş İstatistikler</strong>'e gitmek için tıklayın kilidi açtıktan sonra sağ üstteki düğme.</br></br><img src='https://i.imgur.com/fOk5J81.png' /> ",

  view_user_ratings: "Kullanıcı puanlarını görüntüle, düzenle, sil",

  not_a_valid_linkedin_link: "Geçerli bir Linkedin bağlantısı değil",
  not_a_valid_facebook_link: "Geçerli bir Facebook bağlantısı değil",
  not_a_valid_twitter_link: "Geçerli bir Twitter bağlantısı değil",
  not_a_valid_instagram_link: "Geçerli bir Instagram bağlantısı değil",
  not_a_valid_whatsapp_link: "Geçerli bir Whatsapp bağlantısı değil",
  not_a_valid_youtube_link: "Geçerli bir Youtube bağlantısı değil",
  not_a_valid_reddit_link: "Geçerli bir Reddit bağlantısı değil",
  not_a_valid_telegram_link: "Geçerli bir Telegram bağlantısı değil",
  not_a_valid_snapchat_link: "Geçerli bir Snapchat bağlantısı değil",
  not_a_valid_pinterest_link: "Geçerli bir Pinterest bağlantısı değil",
  not_a_valid_yelp_link: "Geçerli bir Yelp bağlantısı değil",
  not_a_valid_flicker_link: "Geçerli bir Flicker bağlantısı değil",
  not_a_valid_vimeo_link: "Geçerli bir Vimeo bağlantısı değil",
  not_a_valid_vk_link: "Geçerli bir VK bağlantısı değil",
  not_a_valid_soundcloud_link: "Geçerli bir Soundcloud bağlantısı değil",
  not_a_valid_github_link: "Geçerli bir Github bağlantısı değil",
  not_a_valid_tumblr_link: "Geçerli bir Tumblr bağlantısı değil",
  not_a_valid_skype_link: "Geçerli bir Skype bağlantısı değil",

  give_us_feedback: "Lütfen Bize Geri Bildirimde Bulunun",
  please_do_let_us_know: "Lütfen bize bildirin:",
  your_feedback_is_important_to_us: "Görüşleriniz bizim için önemli!",
  if_you_would_like_to_let_us_know:
    "Ürünlerimizi veya hizmetlerimizi nasıl iyileştirebileceğimizi bize bildirmek veya bir iltifat paylaşmak isterseniz, sizden haber almak isteriz!",
  how_you_use_our_products: "Ürünlerimizi nasıl kullanıyorsunuz",
  how_we_can_improve_our_products_or_service: "Ürünlerimizi veya hizmetimizi nasıl iyileştirebiliriz",
  which_conferences_you_think_we_should_attend: "Hangi konferanslara katılmamız gerektiğini düşünüyorsun",
  any_publications_citing_the_use_of_our_products: "Ürünlerimizin kullanımına atıfta bulunan tüm yayınlar",
  we_look_forward_to_hearing_from_you: "Sizden haber bekliyoruz!",
  feedback_form: "Geri Bildirim Formu",
  thank_you: "Teşekkür Ederiz!",
  rate_us: "Bizi Puanlayın!",
  we_have_recieved_your_feedback: "Mesajınız bize ulaştı!",
  i_allow_for_this_message_to_be_shared_the_other_users:
    "Bu mesajın diğer kullanıcılar tarafından paylaşılmasına izin veriyorum",

  if_you_delete_this_file:
    "Bu dosyayı silerseniz, bu ortamı aktif olarak kullanan qr kodlarınız bozulabilir. Devam etmek istediğinizden emin misiniz?",
  if_you_delete_selected_files:
    "Bu seçili dosyaları silerseniz, bu ortamları aktif olarak kullanan qr kodlarınız bozulabilir. Devam etmek istediğinizden emin misiniz?",
  if_you_delete_this_folder:
    "Bu klasörü silerseniz, bu ortamı aktif olarak kullanan qr kodlarınızı bozulabilir. Devam etmek istediğinizden emin misiniz?",

  you_need_to_select_pdf_for_each_language_missing_in: "Her dil için pdf eklemeniz gerekiyor. Eksik:",
  you_can_not_delete_default_language: "Varsayılan Dili Silemezsiniz",
  you_can_not_delete_active_language: "Aktif Dili Silemezsiniz",
  "Set as a default": "Varsayılan Olarak Ayarla",
  Delete: "Sil",
  Activate: "Dili aktif et",
  a_new_verification_mail_has_been_sent_to_your_email_address:
    "E-posta adresinize yeni bir doğrulama postası gönderildi.",
  email_verification: "E-posta Doğrulama",
  did_not_recieve_an_email: "E-posta alınmadınız mı?",
  re_send_verification_mail: "Doğrulama postasını yeniden gönder",
  just_for_you_to_join_us_one_step_left: "Bize katılmanız için bir adım kaldı",
  an_activation_email_has_been_sent:
    "E-posta adresinize aktivasyon e-postası gönderilmiştir. Linke tıklayarak üyeliğinizi aktif hale getirebilirsiniz.",
  locks_your_app_and_asks_users_to_enter_this_password:
    "Uygulamanıza erişim sağlamak için güvenlik amacıyla bir şifre sistemi uygulanmaktadır. Kullanıcılar, uygulamayı ziyaret etmek veya özel içeriğe erişmek için önceden belirlenen bir şifreyi doğru bir şekilde girmek zorundadır. Bu şifre, kullanıcı kimlik doğrulamasını sağlayarak yetkisiz erişimi önlemekte ve kullanıcı verilerinin güvenliğini artırmaktadır.",
  paste_given_code_if_you_would_like_to_track_your_statictics:
    "İstatistiklerinizi kendinizde detaylı takip etmek istiyorsanız, Yandex Metrika yada Google Analitik kodunuzu tam şekilde yapıştırın.",
  if_you_fill_out_the_below_fiels_qr_code_will_be_unavailable_before_start_date_and_after_end_date:
    "Aşağıdaki alanları doldurursanız başlangıç tarihinden önce ve bitiş tarihinden sonra qr kodu kullanılamayacaktır.",
  the_content_will_only_be_avialable_to_the_selected_deviceList:
    "İçerik, yalnızca seçilen cihaz Listesi için kullanılabilir olacaktır.",
  this_must_be_less_than_5_characters: "Bu, 5 karakterden az olmalıdır",
  click_here_to_save_qrcode: "<strong>KAYDEDİLMEDİ!</strong> edit sayfasına gidip kaydetmelisiniz",
  type_changer_disclaimer: ({ named }) =>
    `Aktif olan <strong>${named(
      "currentType"
    )}</strong> 'tan farklı bir kare koda geçmek üzeresiniz. Eğer devam ederseniz bu kare koda ait girilen tüm veriler silinecek ve ilgili sayfaya, gerekli alanları doldurmak üzere yönlendirileceksiniz. Lütfen bu işlemin geri alınamaz olduğunu unutmayın.`,
  btnText: "Buton Yazısı",
  your_short_url_will_look_like_this: "Kısa url'niz şöyle görünecek (Kendi domainiz değilse): q-r.cc/adc256",
  please_input_a_valid_phone_number: "Lütfen geçerli bir telefon numarası girin",
  qr_code_warning_text:
    "Not: Alan adınızı veya değerini değiştirirseniz, QR kodunun içeriği değişecektir. Eğer bastırdığınız bir kod ise, QR kodunuz çalışmayacaktır.",
  your_profile_photo_has_been_successfuly_updated: "Profil fotoğrafınız başarıyla güncellendi!",
  your_profile_photo_has_been_successfuly_removed: "Profil fotoğrafınız başarıyla kaldırıldı!",
  you_at_least_need_to_have_1_address: "En az bir adresiniz olması gerekiyor!",

  pricing_guide: `
  <div style="padding:30px;background:#ffffff">
            <h1 class="text-center">Fiyat Rehberi</h1>
                        <ul>
              <li>Müşterilerimizin ihtiyaçlarına uygun çözümler sunarken, şeffaf ve adil bir fiyatlandırma politikası benimsiyoruz. QR kod çözümlerimizde farklı paket seçenekleri sunuyoruz: 
Starter Paket, Advanced Paket ve Business Paket. Ayrıca, özel ihtiyaçları olan müşterilerimiz için özel teklifler de sunmaktayız.</li>

                <li>Paketlerimizde kodlar adet ile satılmaktadır ve her bir paket belirli özellikler ve hizmet düzeyleri sunmaktadır. Bu paketler arasında yapılan seçim, müşterilerimizin ihtiyaçlarına, bütçelerine ve kullanım senaryolarına göre değişebilir.</li>

                <li>Starter Paket, küçük ölçekli işletmeler ve kişisel kullanıcılar için mükemmel bir başlangıç noktası sunar. Advanced Paket, orta ölçekli işletmeler ve ihtiyaçları daha fazla olan kullanıcılar için daha geniş bir özellik yelpazesi sunar. Business Paket ise büyük kurumsal müşterilerimizin daha karmaşık gereksinimlerini karşılamak üzere tasarlanmıştır.</li>

                <li>Özel tekliflerimiz, belirli projeler veya benzersiz gereksinimler için özelleştirilmiş çözümler ve paketler dışındaki adetlerde kodlar sunar. Müşterilerimizin spesifik ihtiyaçlarına göre esnek fiyatlandırma seçenekleri sunarak, en uygun çözümü bulmalarına yardımcı oluyoruz.</li>

                <li>Fiyatlandırma detayları ve paket özellikleri hakkında daha fazla bilgi almak için lütfen bize ulaşın.</li>
              </ul
            <p class="text-end">QR Code Matic</p>
          </div>
  `,
  how_it_works: "Nasıl Çalışır",
  how_it_works_button_text: "Nasıl çalışır",
  your_subscription_expired: "Paketiniz bitmiştir. ÜCRETSİZ Statik QR Code Oluşturabilirsiniz.",
  qrcode_will_be_deleted_in_24: "Bu kare kod 1 gün içinde silinecek. Sebebini öğrenmek için tıkla",

  //domain list
  manage_your_own_domains: "Kendine ait domain'lerini yönet",
  domain_list: "Domain Listesi",
  domain_name: "Domain Adı",
  short_link: "Kısa Link",
  options: "Seçenekler",
  delete: "Sil",
  domain_delete: "Domain Kaldır",
  domain_delete_desc: "Silmek istediğiniz domain  ",
  domain_delete_desc_2: "Devam etmek istediğinize emin misiniz ?",
  verified_status: "Doğrulama Durum",
  how_to_setup: "Nasıl Kurulur?",
  how_to_setup_desc:
    "Kendi kısaltıcı alan adınızı etkinleştirmek için kendi alan adınızda bir alt alan adı ayarlamanız gerekir. Alt alan adı, alan adımız q-r.cc'ya bir CNAME Kaydı ile işaret etmelidir. Lütfen önce alan adını ayarladığınızdan emin olun, bazen sistemimizin alt alan adınızdaki değişiklikleri tanıması 24 saate kadar sürebilir.",
  what_is_a_short_URL: "Kısa URL nedir?",
  what_is_a_short_URL_desc:
    "Dinamik Kodlar, istenen açılış sayfasına yönlendiren kısa bir URL kullanır. Bu, izleme verilerini almak için gereklidir ve Kodları düzenlemeye olanak tanır. Bu etki alanını örneğin şu şekilde değiştirebilirsiniz",

  create_template_button_text: ({ named }) =>
    `<span class="font-weight-bold mr-1">Tasarım</span><span class="font-weight-light">Oluştur</span>`,
  queue_processing_title: ({ named }) => `${named("type")} işleminiz sürdürülüyor!`,
  queue_done_title: ({ named }) => `${named("type")} işleminiz tamamlandı!`,
  queue_done_text: ({ named }) =>
    `${named(
      "type"
    )} master toplu işleminiz bitti. Artık master QR Code'larınız ve onlara ait alt QR Code'larınız üzerinde değişiklik yapabilirsiniz.`,
  queue_processing_text: ({ named }) =>
    `${named(
      "type"
    )} için toplu isteğiniz sıraya alındı. İsteğiniz tamamlanana kadar beklemelisiniz. İsteğiniz tamamlandıktan kısa bir süre sonra bir e-posta alacaksınız.`,
  waiting_for_process: "İsteğiniz Tamamlanması Bekleniyor...",
  select_all: "Tümü Seç",
  deselect_all: "Seçileni İptal Et",
  add_more_child_from_previous_qrcodes: "+ Diğer QR Code'ları Altına Ekle",
  add_more_child_from_previous_qrcodes_tooltip:
    "Diğer QR Code'ları bu QR Code'un altına ekleyip yada taşıyıp, toplu halde ve hızlı şekilde güncelleyebilirsiniz.",
  convert_to_master: "Master'a Çevir",
  convert_to_master_tooltip: "Bu QR Code'un altına QR Code eklemek yada taşımak için çeviriniz.",
  add_more_child_dialog_title: "Varolan QR Code'Ları bu Master'ın Altına Ekle",
  add_more_child_dialog_button_text: "Aktarım Yap",
  add_more_child_is_successful: "İşlem Başarılı",
  add_more_child_dialog_p1:
    () => `Bu menu daha önceden yarattığınız QR Code'larınızı <strong>toplu işlem</strong> yapabilmek için Master QR
  Code içine almanızı sağlar. Böylelikle seçtiğiniz QR Code'lar üzerinde toplu işlem yapabilirsiniz.`,
  add_more_child_dialog_p2: ({ named }) => `Aşağıdaki listeden seçeceğiniz QR Kodlarınız <strong>${named(
    "title"
  )}</strong> başlık /
  <strong>${named("vars")}</strong> vars Master QR Code altına alınacak. Sonrasında
  <strong>${named("title")}</strong> üzerinden bu QR Code'larla ilgili toplu düzenleme işlemleri
  yapabilirsiniz`,
  add_more_child_dialog_p3: () => `Listedeki QR Code'ların yanlarında gösterilen uyarı ikonları şunları ifade eder;
  <ul>
    <li><span style="background-color:#1abcb5;padding:2px 5px;border-radius:2px;color:#ffffff;">1 alt QR Kodu var</span> Bu QR Code'un altında başka QR Code'lar var. Eğer bu ikona sahip olanları işaretlerseniz, seçtiğiniz QR Code ile birlikte altındaki QR Code'ları da aktarılır.</li>
    <li><i class="fa-solid fa-circle-info orange--text mr-2"></i>Bu QR Code master özelliğine sahip. Eğer bunu seçerseniz master olma özelliğini kaybedip işlem yapılacak olan QR Code'un altına alınır.</li>
  </ul>`,
  are_you_sure_convert_child_to_master: ({ named }) =>
    `${named(
      "title"
    )} başlıklı QR Code'u ait olduğu master'ın altından çıkarıp, kendisini master yapmak üzeresin. Devam etmek istediğine emin misin ?`,
  add_more_child_count: ({ named }) => `${named("count")} adet alt QR Code'u var`,
  gradient_on_eyes: "Gradient On Eyes",
  update_prices: "Menü Fiyatlarını Güncelle",
  confirm_delete_all: "Tüm bu QR Kodları silmek istediğinize emin misiniz ?",
  unsaved_changes_will_be_lost: "Kaydedilmemiş değişiklikler kaybolacak. Ayrılmak istediğinize emin misiniz ?",
  show_media: "Linkleri Kopyalanacak Medyaları Göster",
  $LOGIN: {
    welcome_to: "QR Code Matic'e Hoşgeldin!",
    just_one_step: "Dinamik yada Statik QR Code oluşturmak için sadece bir adım kaldı!",
    you_are_almost_done: "Neredeyse bitiriyorsun!",
    please_choose_appropriate_options:
      "Lütfen size uygun olan seçeneği seçiniz! En alttan 'DİĞER' seçeneği yada en üstteki 'KİŞİSEL KULLANIM' seçeneğini hızlıca seçebilirsiniz.",
    please_chose_one_of_the_options: "Lütfen Seçim Yapınız",
    disclaimer:
      "Deneme sürümü içerisinde kesinlikle KREDİ KARTI BİLGİLERİNİZ İSTENMEZ. Sadece doğru ülkeyi seçtiğinize emin olunuz.",
    new_here: "Yeni misin",
    create_an_account: "Hesap Oluştur",
    password: "Şifre",
    password_re: "Şifreyi Doğrula",
    forgot_password: "Şifremi Unuttum",
    sign_in: "Giriş yap",
    sign_in_with_google: "Google ile giriş yap",
    sign_in_with_facebook: "Facebook ile giriş yap",
    sign_in_with_twitter: "Twitter ile giriş yap",
    sign_up: "Üye ol",
    subheader: "Kayıt için gerekli bilgileri doldurun",
    your_organization: "Sektörünüz",
    your_country: "Ülkeniz",
    fullname: "Ad soyad",
    confirm_password: "Tekrar şifre girin",
    agree_terms: "Gizlilik ve kullanım şartlarını kabul ediyorum",
    did_you_forget_password: "Şifreni mi unuttun ?",
    forgot_password_subheader: "Şifrenizi resetlemek için email adresinizi girin",
    submit: "Gönder",
    cancel: "İptal",
    terms: "Gizlilik ve Kullanım Şartnamesi",
    contact_us: "İletişim",
    enter_new_password: "Yeni şifre yazınız",
    your_password_has_been_reset: "Şifreniz sıfırlandı",
    you_will_be_redirected_to_login: "Giriş sayfasına yönlendirileceksiniz...",
    reset_link_is_sent_to_your_mail: "Sıfırlama bağlantısı mailinize gönderilmiştir.",
    check_your_mail_box: "Posta kutunu kontrol et",
    login: "GİRİŞ",
    your_country: "Ülke Seçiniz",
    your_language: "Dilinizi Seçiniz",
  },
  $TICKET: {
    mail_support: "Destek E-postası",
    choose_a_file: "Dosya seçin veya buraya bırakın...",
    enter_mail: "Maili Giriniz...",
    mail: "Eposta",
    subject: "Konu",
    message: "Mesaj",
    select_topic: "Başlık Seçin",
    qenerals_quetion: "Genel Soru",
    billing: "Ödeme",
    technical_question: "Teknik Soru",
    login_issue: "Giriş Hatası",
    other: "Diğer",
  },
  $ORDERS: {
    order_number: "Sipariş Numarası",
    order_status: "Sipariş Durumu",
    product_name: "Ürün Adı",
    currency: "Para Birimi",
    order_date: "Sipariş Tarihi",
    active_package: "Aktif Paket",
    started_at: "Başlangıç Süresi",
    expires_at: "Bitiş Süresi",
    remaining_days: "Kalan Süre",
    actions: "İşlemler",
    order_title: "Sİperişler",
    payment_confirmed: "Onaylandı",
    incorrect_payment: "Hatalı ödeme",
    awaiting_payment: "Ödeme bekleniyor",
    order_canceled: "İptal edildi",
    cancel_order: "Siparişi İptal Et",
    status: "Sipariş Durum",
    invalid_package: "Geçersiz Paket",
    payment_type: "Ödeme Tipi",
    account_number: "Hesap Numarası",
    payment_amount: "Ödeme Tutarı",
    payment_desc: "Ödeme Açıklaması",
    bank_transfer: "Havale",
    creditcard: "Kredi Kartı",
  },
  $SELECT_SECTOR: {
    personal_use: "Kişisel Kullanım",
    tourism: "Turizm",
    restaurants: "Restoranlar",
    software: "Yazılım",
    photo_video: "Fotoğraf ve Video",
    cities_culture: "Şehirler ve Kültür",
    event_management: "Etkinlik Yönetimi",
    home_repair: "Ev ve Onarım",
    finance: "Finans",
    publisher: "Yayıncılık",
    nonprofit: "Kar Amacı Gütmeyen",
    retail: "Perakende",
    real_estate: "Gayrimenkul",
    gym: "Spor Salonları ve Wellness",
    hotel: "Otel ve Tatil Köyleri",
    products: "Ürünler",
    education: "Eğitim",
    goverment: "Hükümet",
    doctors: "Doktorlar ve Sağlık Hizmetleri",
    agency: "Ajans Hizmetleri",
    electronics: "Elektrik ve Elektronik",
    paper: "Mobilya, Kağıt ve Kağıt Ürünleri",
    glass: "Cam, Çimento ve Toprak",
    environment: "Çevre",
    energy: "Enerji",
    foods: "Gıda",
    construction: "İnşaat",
    business: "İşletme ve Yönetim",
    chemistry: "Kimya, Petrol, Kauçuk ve Plastik",
    culture: "Kültür, Sanat ve Tasarım",
    mines: "Madencilik",
    machines: "Makinalar",
    media: "Medya, İletişim ve Yayıncılık",
    metal: "Metal",
    automotive: "Otomotiv",
    social: "Sosyal Hizmetler",
    sports: "Spor ve Rekreasyon",
    agriculture: "Tarım, Avcılık ve Balıkçılık",
    textile: "Tekstil, Hazır Giyim, Deri",
    trade: "Ticaret (Satış ve Pazarlama)",
    community: "Toplum ve Kişisel Hizmetler",
    beverage: "Yiyecek ve İçecek Hizmetleri",
    transport: "Taşımacılık ve Lojistik",
    communications: "İletişim",
    other: "Diğerleri",
  },
  $SUBSCRIPTION: {
    selected_package_name: "Seçilen paket",
    choose_payment_method: "Ödeme yöntemi seç",
    choose_payment_method_description: "Sana en uygun olan ödeme yöntemini seç",
    add_payment_info: "Ödeme Bilgileri",
    payment_is_safe: "Ödeme işlemi güvenlidir",
    billing_info: "Fatura Bilgileri",
    complete_payment: "Ödemeyi Tamamla",
    bank_transfer_info: "Havale Bilgileri",
    about_pricing: " Fiyatlandırmamız hakkında daha fazla bilgiye ihtiyacınız varsa, lütfen kontrol edin",
    choose_your_plan: "Plan Seçiniz",
    pricing_policy: "Fiyat Politikası",
    private_or_business: "Lütfen bunun bireysel mi yoksa ticari bir satın alma mı olduğunu seçiniz",
    company_invoice: "Şirket Faturası",
    individual_invoice: "Bireysel Fatura",
    select: "SATIN AL",
    discountMessage: ({ named }) => `${named("amount")}$'lık indirim uygulandı`,
  },
  validations: {
    field_required: "Bu alan gerekli",
    email_required: "Email gerekli",
    password_required: "Şifre gerekli",
    agree_terms: "Şartları kabul etmen gerekli",
    invalid_zip: "Posta kodu geçerli değil",
    invalid_email: "Email geçersiz",
    invalid_password: "Posta kodu geçerli değil",
    invalid_youtube: "Geçersiz youtube bağlantısı",
    not_a_valid_email: "Geçerli bir e-posta değil",
    not_a_valid_phone_number: "Geçerli bir telefon değil",
    you_must_accept_the_terms: "Şartları kabul etmelisiniz",
    your_password_does_not_match: "Parolanız eşleşmiyor",
    you_must_enter_valid_email: "Geçerli bir e-posta adresi girmelisiniz",
    you_must_enter_password: "Bir parola girmelisiniz",
    you_must_enter_your_name: "Adınızı girmelisiniz",
    you_must_choose_country: "Bir ülke seçmelisiniz",
    you_must_choose_organization: "Bir kuruluş seçmelisiniz",
    must_contain_numbers: "En az bir rakam içermeli",
    must_contain_letters: "En az bir harf içermeli",
    short_link_is_required: "Gerekli",
    short_link_special_char: "Yanlızca rakam ve hece olmalı",
    short_link_between: "3-30 karakter arası olmalı",
    less_than: ({ named }) => `${named("amount")} karakterden az olmalı`,
    more_than: ({ named }) => `En az ${named("amount")} karakter olmalı`,
  },
  authTemplate: {
    confirm: "Onayla",
    legal_disclaimer_title: () => `<h1><span>Yasal</span> Uyarı</h1>`,
    legal_disclaimer:
      "Bu siteyi görüntülemek için en az 11 yaşında olmalısın. 11 yaşın altındaysan bu siteyi görüntüleme hakkına sahip değilsin.",
    login_area_title: () => `<h1><span>Giriş</span> Alanı</h1>`,
    login_button_text: "Giriş",
    login_disclaimer: "Ulaşmak istediğiniz içerik gizlenmiş. Parola girerek sayfaya erişebilirsiniz.",
  },
  ALERT: {
    alert_msg_1:
      "Statik qr kod verilerini düzenlemek üzeresiniz. Bu, qr kodunun kendisini değiştirecektir. Yapacağınız değişiklikler basılı veya dağıtılan qr kod için geçerli olmayacaktır. devam etmek istediğinden emin misin?",
  },
  FAQ_MAIN: {
    faqs_and_guides_about_qr_code: "QR Code Hakkında Sıkça Sorulan Sorular ve Kılavuzlar",
    search_for_topics: "Konular içinde ara...",
    faq: "SSS",
    the_most_frequently_asked_question: "En sık sorulan sorular",
    articles_in_this_collection: "Bu koleksiyondaki makaleler",
    written_by_QR_Code_Matic: "QR Code Matic tarafından yazıldı",
    setting_up_your_account: "Hesabınızı Kurma",
    why_qr_code: "Neden QR Kodu?",
    pricing_and_billing: "Fiyatlandırma ve Faturalandırma",
    information_about_prices_and_invoices: "Fiyatlar ve faturalar hakkındaki bilgiler",
    find_out_everything_you_need_to_know_about_qr_codes_here:
      "QR Kodları hakkında bilmeniz gereken her şeyi burada öğrenin",
    analytic_title: "Verileri Anlamak için Analitik: İşinizdeki Önemi ve Uygulamaları",
    get_to_know_our_pricing_terms_and_billing: "Fiyatlandırma, koşullar ve faturalandırmamızı öğrenin",
    analytic: "Analitik",
  },
  FAQ: {
    static_dynamic_title: "Statik ve Dinamik QR Code arasındaki fark nedir ?",
    static_dynamic: `Statik QR kodları bir kez oluşturulduktan ve basıldıktan sonra değiştirilemez. 
    Eğer bağlantı bozulursa, yeniden basmak dışında yapabileceğiniz bir şey yoktur. Bununla birlikte, 
    dinamik QR kodları düzenlenebilir ve gerçek zamanlı olarak düzenlenebilir. 
    Dinamik QR kodu, bir yer tutucu URL içerdiği için aynı QR kodu tekrar tekrar kullanmak mümkündür, 
    bu da zaman, çaba ve maliyet tasarrufu sağlar.`,

    better_static_dynamic_title: "Hangisi daha iyi: statik veya dinamik QR Kodu?",
    better_static_dynamic: `Eğer gelecekte düzenleme ve takip gerektirmeyen tek seferlik bir 
    pazarlama kampanyası arıyorsanız, statik QR kodları sizin için daha iyi olabilir.
    Eğer kampanyalarınızda daha fazla esneklik, QR tarama analitiği, çevrimiçi yeniden 
    hedefleme, daha hızlı taramalar vb. ihtiyacınız varsa, dinamik QR kodları, 
    statik olanlara göre çok daha önerilir.`,

    how_change_dynamic_title: "Bir statik QR Kodunu nasıl dinamik bir QR Koda dönüştürebilirim?",
    how_change_dynamic: `Bir statik QR Kodunu dinamik bir QR Koda dönüştüremezsiniz, çünkü statik QR Kodları oluşturulduktan sonra düzenlenemez.`,

    how_qrcode_free_title: "Nasıl ücretsiz bir QR Kodu oluşturabilirim?",
    how_qrcode_free: `QR Code Matic'in QR Kodu oluşturucusuna gidin ve QR Kodunuzu ücretsiz olarak oluşturmaya başlayın. 
    İstediğiniz formatta indirebilir ve isterseniz özelleştirebilirsiniz. Not: Statik QR Kodları süresiz olarak ücretsizdir.
    Bununla birlikte, dinamik QR Kodları oluşturmak için 14 günlük ücretsiz deneme sürecini
    başlatmanız veya bir plan satın almanız gerekmektedir.`,

    what_is_qr_code_title: "Kare Kod Nedir?",
    what_is_qr_code: `Qr Kod ismi İngilizce karşılığı olan Quick Response(Hızlı Tepki) kelimesinin baş harflerinden meydana gelmektedir. 
    Kare Kod , Denso Wave firması tarafından geliştirilen ve içerisinde yüzlerce karakter veriyi şifrelemeye olanak sunan 2 
    boyutlu bir barkod türüdür. Standart olarak siyah ve beyaz piksellerden meydana gelmektedir. 
    Kod üzerinde şifrelenen veri daha sonra akıllı telefon veya tablet ile oldukça hızlı çözülebilir ve görüntülenebilir.`,

    how_can_i_generate_qr_code_title: "Kare Kodu Nasıl Oluşturabilirim ?",
    how_can_i_generate_qr_code: `Qr Kod ismi İngilizce karşılığı olan Quick Response(Hızlı Tepki) kelimesinin baş harflerinden meydana gelmektedir. 
    Kare Kod , Denso Wave firması tarafından geliştirilen ve içerisinde yüzlerce karakter veriyi şifrelemeye olanak sunan 2 
    boyutlu bir barkod türüdür. Standart olarak siyah ve beyaz piksellerden meydana gelmektedir. 
    Kod üzerinde şifrelenen veri daha sonra akıllı telefon veya tablet ile oldukça hızlı çözülebilir ve görüntülenebilir.`,

    what_should_I_consider_when_printing_title: "Yazdırırken Nelere Dikkat Etmeliyim ?",
    what_should_I_consider_when_printing: `Basılı dokümanlar üzerinde kullanılacak ise yüksek çözünürlüğe sahip çıktı formatlarının kullanılması gerekir. 
    QR Code Matic Qr Kod'larınızı PNG nin yanı sıra Aİ, EPS ve SVG gibi vektör formatlarında çıktı alabilirsiniz. 
    Bunlar, görüntü kalitesinde kayıp olmadan genişletilebilmektedir. Vektörel formattaki çıktılar özellikle büyük ölçekli baskı uygulamaları için uygundur.
     Kodları yayınlamadan önce, farklı akıllı telefonlar ve uygulamalar ile bir uygulama testi her zaman yapılmalıdır.`,

    how_can_QR_codes_be_scanned_title: "QR Kodlar Nasıl Taranabilir ?",
    how_can_QR_codes_be_scanned: `Basılı dokümanlar üzerinde kullanılacak ise yüksek çözünürlüğe sahip çıktı formatlarının kullanılması gerekir. 
    QR Code Matic Qr Kod'larınızı PNG nin yanı sıra Aİ, EPS ve SVG gibi vektör formatlarında çıktı alabilirsiniz. 
    Bunlar, görüntü kalitesinde kayıp olmadan genişletilebilmektedir. Vektörel formattaki çıktılar özellikle büyük ölçekli baskı uygulamaları için uygundur.
     Kodları yayınlamadan önce, farklı akıllı telefonlar ve uygulamalar ile bir uygulama testi her zaman yapılmalıdır.`,

    how_to_use_QR_codes_title: "QR Kodlar Nasıl Kullanılır ?",
    how_to_use_QR_codes: `Akıllı telefon kullanımının yaygınlaşması ile QR Kodların önemi artmıştır. 
    Kullanılan kampanyalarda QR Kodların kilit rol oynaması önümüzdeki günlerde vazgeçilmez hale gelerek daha geniş kitlelere ulaşım imkanı sağlayacaktır. 
    QR kodlar günümüzde daha çok mobil pazarlama amaçlı kullanılmaktadır. El ilanları, posterler, kataloglar veya kartvizitler gibi basılı materyaller, 
    web siteleri, videolar, PDF'ler veya iletişim verileri gibi dijital içeriklerle desteklenebilir. Farklı uygulamaların çeşitliliği neredeyse sınırsızdır.`,

    can_I_customize_QR_codes_visually_title: "QR Kodları Görsel Olarak Özelleştirebilir Miyim?",
    can_I_customize_QR_codes_visually: `Yüksek oranda hata toleransı sayesinde, okunabilirlik için gerekli kod yapılarını değiştirmeden kodu bir 
    dereceye kadar düzenlemek mümkündür. Örneğin, ön plan ve arka plan renkleri gradient,radial olarak belirlenebilir, logunuzu Qr Kodunuza ekleyebilir 
    veya referans noktaları ile gövde desenlerini değişitirebilirsiniz. Ancak, kodunuzu görsel olarak ayarlarken her zaman okunabilirliği test edin.`,

    can_I_view_scan_statistics_of_QR_codes_title: "QR Kodların Tarama İstatistiklerini Görüntüleyebilir Miyim ?",
    can_I_view_scan_statistics_of_QR_codes: `Dinamik QR kodlarınızın gerçek zamanlı tarama istatistik bilgilerini görüntülemeniz mümkündür. Hangi ülkelerden, 
    şehirlerden, tarayıcıdan, cihaz, yaş, cinsiyet, cihaz çözünürlük, indirmeler gibi detaylı istatistikler alabilirsiniz.`,

    what_file_formats_and_size_should_i_use_for_printing_title:
      "Yazdırma İçin Hangi Dosya Biçimlerini ve Boyutunu Kullanmalıyım ?",
    what_file_formats_and_size_should_i_use_for_printing: `Büyük ölçekli baskı uygulamalarında görüntü kalitesinde kayıpların yaşanmaması için vektörel çıktılar tavsiye 
    edilmektedir. Okunabilirliğin sağlanması için dosya tipinin yanı sıra QR Kod boyutlarına da dikkat edilmelidir. 
    Özellikle statik QR Kod'lar içerdiği veriler ile kodun içeriğini yoğunlaştıracağı için daha büyük boyutlar kullanılmalıdır. 
    Yani daha fazla içerik şifrelenirse, gereken alan da artar. Rahat bir kullanıcı etkileşimi sağlanması için kodun okunması kolay olmalıdır. 
    Uygulamada kullanılacak kodun boyutu en az 2 x 2 cm olmalıdır. Okutma mesafesi ile kod boyutu arasında en az 1/10 luk bir oran olması gerekmektedir.
    Örneğin kodun 10 metreden okutulabilmesini istiyorsa Qr Kodunuz en az 1 metre x 1 metre olmalıdır. Düz olmayan yüzeylerde yerleştirme yapılmamalıdır. 
    Broşür veya sayfa kırışıklıkları bir kodun okunabilirliğini olumsuz yönde etkileyebilir.`,

    where_and_how_to_use_qr_code_title: "QR Kod Nerede ve Nasıl Kullanılır ?",
    where_and_how_to_use_qr_code: `QR kodların kullanım alanları sınırsızdır. QR Kodlar kişisel bilgilerinizi paylaşmaktan tutun da şirket pazarlama 
    ve lojistik sorunlarınızın çözümünü sağlayan sınırsız olanaklar sunar.`,
  },
  WHY_QR_CODE: {
    unlimited_title: "Sınırsızdır",
    unlimited:
      "QRCodeMatic.com QR Kod'ları ile sınırsız video,pdf, mesaj, bilgi ve her türlü içeriğe kullanıcılarınızın ulaşımını sağlayın.",

    attractive_title: "İlgi Çekicidir",
    attractive:
      "Kullandığınız QR Kod’un ne olduğu insanın doğal yapısına uygun olarak ilgi çeker ve merak uyandırır. Doğru kurgu ile merak seviyesini yükseltip geri dönüşleri arttırmak mümkündür. Ayrıca sonuç odaklı olduğu için müşteri memnuniyetiniz gözle görülür bir yükseliş gösterecektir.",

    everywhere_is_a_showcase_for_you_title: "Sizin İçin Her Yer Vitrindir",
    everywhere_is_a_showcase_for_you:
      "Ürünlerinizi kayıt ettiğiniz kare kodların olduğu her yer sizin vitrininizdir. Diğer kullanıcılarda görülüp beğenilen ürünleriniz den , broşür, kartvizit ile ikram edilen atıştırmalıklara kadar her yer sizin yaşayan vitrininizdir.",

    add_on_your_product_title: "Cihaz/Ürün Bilgi Broşürünüzü Ürününüzün Üzerine Ekleyin",
    add_on_your_product:
      "Sayfalarca yer kaplayan ve kolay şekilde yıpranıp okunmaz hale gelen el aletleri, cihazlar, tüketilebilir ürünler ve tüm ekipmanlarınızın kullanım kılavuzlarını, detaylarını, talimatlarını ve dokümanlarını(video,pdf,dosya) üzerine yapıştırabilirsiniz. Ayrıca ürünleriniz için aldığınız özel izin ve standartları kolay şekilde kullanıcılarınıza ulaştırarak güvenirliğinizi arttırın.",

    your_successful_sales_representative_title: "Başarılı Satış Temsilcinizdir",
    your_successful_sales_representative:
      "Vitrininizdeki ürünleri 7/24 tavsiye edilebilir hale getirir ve ürünlere kolay ulaşım sağlayarak kullanıcılarınıza eğlenceli, ilgi çekici bir alışveriş sağlar.",

    makes_you_easy_to_reach_title: "Size Kolay Ulaşılmasını Sağlar",
    makes_you_easy_to_reach:
      "Tüm şirket profilinizi , Google Maps uygulaması ile entegre yapısı sayesinde haritadaki yeriniz gibi bir çok bilgiyi içerebildiği için tek bir kod ile müşterilerinizin sizi aramasını,e-mail, mesaj göndermesini, sosyal medya adreslerinize ulaşmasını, sizi kişilerine ve adres bilgilerine ekleyebilmesini ya da konum bilginizi almasını sağlayabilirsiniz.",

    it_is_social_title: "Sosyaldir Sosyaldir",
    it_is_social:
      "Tüm Sosyal Medya hesaplarınızın görüntülenmesini, markanızı takip etmesini , sayfanızı takip etmesini, beğenmesini, yorum yapmasını , şikayet ve memnuniyetleri bildirmesini sağlayabilir.",

    lets_you_show_your_creativity_title: "Yaratıcılığınızı Göstermenizi Sağlar",
    lets_you_show_your_creativity:
      "Özel etkinlikler, çekiliş, kampanya ve indirimleriniz için kullanıcı dostu bir pazarlama platformudur. Digital olarak oluşturacağınız hediye çeklerinizi, etkinliklerinizi ve kampanyalı ürünlerinizi müşterilerinize sunabileceğiniz merak uyandırıcı, marka bilinilirliğinizi arttırıcı uygulamalar oluşturabilirsiniz. Bu uygulamaların sınırı sadece sizin yaratıcılığınız ve hayal gücünüzdür.",

    low_cost_title: "Düşük Maliyetlidir",
    low_cost:
      "Kazanımları göz önünde bulundurulduğunda çok düşük maliyetli bir tanıtım ve pazarlama enstrümanıdır. Paketlerimizi Qrcodematic.com adresinden detayları inceleyebilirsiniz.",

    durable_title: "Dayanıklıdır",
    durable:
      "Kazanımları göz önünde bulundurulduğunda çok düşük maliyetli bir tanıtım ve pazarlama enstrümanıdır. Paketlerimizi Qrcodematic.com adresinden detayları inceleyebilirsiniz.",
  },
  BILLING: {
    when_can_i_use_my_package_title: "Paketimi ne zaman kullanabilirim ?",
    when_can_i_use_my_package:
      "Ödemenizi girdikten ve siparişinizi verdikten sonra, Planınızın tüm özelliklerinin kilidi anında açılır ve doğrudan yeni QR Kodları oluşturmaya veya Ücretsiz Deneme Kodlarınızı yeniden yönetmeye başlayabilirsiniz.",

    can_i_upgrade_to_a_higher_plan_title: "Üst pakete geçebilir miyim ?",
    can_i_upgrade_to_a_higher_plan:
      "Evet. Dinamik QR Kodları sınırına ulaştıysanız veya daha fazla özelliğin kilidini açmak istiyorsanız. İstediğiniz zaman daha yüksek bir Plana yükseltebilirsiniz. Daha sonra size orantılı bir geri ödeme sağlayacağız.",

    how_long_is_the_contract_period_title: "Sözleşme süresi ne kadar?",
    how_long_is_the_contract_period:
      "Sözleşme feshedilmezse seçmiş olduğunuz sözleşme süresi kadar otomatik olarak yenilenir.",

    how_do_i_cancel_my_subscription_title: "Aboneliğimi nasıl iptal ederim?",
    how_do_i_cancel_my_subscription:
      "Sözleşmenizin yenilenmesini istemiyorsanız, bunun için resmi olmayan bir e-posta kesinlikle yeterli olacaktır. İptalinizi asıl sözleşme süresinin bitiminden en az 30 gün öncesine kadar almamız gerekir.",
  },
  ANALYTIC: {
    what_kind_of_analytics_reporting_do_you_offer_title: `Ne tür analitik raporlaması sunuyorsunuz?`,
    what_kind_of_analytics_reporting_do_you_offer: `URL kısaltıcı hizmetimizi kullanan yeniden yönlendirilen URL'ler için analiz verileri, hesap kontrol panelinizde, her bir tarama olayı için tarih, saat, cihaz türü ve konumu gösteren aranabilir tarih biçiminde ve gerçek zamanlı olarak sunulur.
      Hem tablo hem de grafik biçiminde sunulan veriler, Excel biçiminde veya indirilebilir bir PDF raporu olarak da indirilebilir. Her benzersiz dinamik QR kodu için raporlama mevcuttur.
      Bir ekran görüntüsü görmek veya örnek bir PDF raporu indirmek için buraya tıklayın.`,

    can_analytics_be_used_with_all_your_QR_codes_title: `Analitikler, tüm QR kodlarınızla kullanılabilir mi?`,
    can_analytics_be_used_with_all_your_QR_codes: `Hayır. Analitik verileri yalnızca, kullanıcının kodlanmış kısa URL'si aracılığıyla web sitemize geri dönmesi ve bu sırada gerekli verileri toplamamıza izin vermesi için dinamik bir QR kodu oluşturduğunuzda toplanabilir. Diğer birçok QR kodu oluşturucu web sitesi, isteseniz de istemeseniz de sizi dinamik bir QR kodu oluşturmaya zorlar, ancak QR kodu tarandığında bizim değil, gerçek web sitenizin URL'sinin görüntülenmesini sağlamak için bunu isteğe bağlı yapmaya karar verdik. Kısa url.
      Bunun dezavantajı, QR kodunun kendisinde bir izleme mekanizması olmadığı için, statik bir QR kodu oluşturursanız, ziyaretçi QR kodunu tarar ve ardından, önce sunucumuzdan geçmeden doğrudan hedef URL'ye veya kodlanmış içeriğe gider. Bu, bize o yola müdahale etme ve sizin adınıza izleme verilerini toplama fırsatı vermediğinden, kodun işaret ettiği URL'de Google Analytics gibi bir şey kullanmanız gerekecek. Blog gönderimiz Google Analytics'i QR Kodlarıyla Kullanmanın nasıl olduğunu açıklıyor.
      Özellikle statik bir QR kodu oluşturmayı seçerseniz, bunun için analiz raporlaması kullanılamaz ve buna daha sonra eklenemez - statik ve dinamik arasındaki seçim tek seferlik bir şeydir ve daha sonra değiştirilemez.`,

    how_can_I_access_my_analytics_reports_title: `Analitik raporlarıma nasıl erişebilirim?`,
    how_can_I_access_my_analytics_reports: `Abone hesabınıza giriş yaptığınızda hesap kontrol panelinize sayfanın sağ üst köşesindeki link ile ulaşılır. Geçmiş raporunda, her bir QR kodu kaydının sağındaki "yönet" sekmesi, o QR kodu hakkında genişletilmiş bilgileri açar.
      Oluşturduğunuz herhangi bir dinamik QR kodu, o QR kodu için ayrıntılı bilgi listesinin sağ tarafında bir "Analytics" bağlantısı görüntüler. "Analytics" seçeneği görünmüyorsa, oluşturduğunuz QR kodu statik bir QR kodudur ve bunun için analitik raporlaması mevcut değildir.`,

    are_phone_numbers_and_email_addresses_included_in_analytics_reports_title: `Analitik raporlarına telefon numaraları ve e-posta adresleri dahil mi?`,
    are_phone_numbers_and_email_addresses_included_in_analytics_reports: `Hayır. Telefonda saklanan ve QR kodunu taramak için kullanılan tanımlanabilir kullanıcı bilgilerine (telefon numaraları ve e-posta adresleri gibi) telefonun kendisinde yerleşik olan gizlilik ve güvenlik protokolleri nedeniyle hiçbir şekilde erişilemez.`,
  },

  login_note: `<div style="font-weight: 900">ÖNEMLİ!!!</div> <span class="demo-time"> Deneme Süresi Bittiğinde</span> içerideki özellikleri kullanarak
  <span class="font-weight-bolder"> ÜCRETSİZ Statik QR Code</span> oluşturmaya ve <span class="font-weight-bolder">ÜYELİĞİNİZİ</span> kullanmaya devam edebilirsiniz.`,
  MENU: {
    NEW: "Yeni",
    ACTIONS: "Eylem",
    CREATE_POST: "Yeni Gönderi Oluştur",
    PAGES: "Sayfalar",
    FEATURES: "Özellikler",
    APPS: "Uygulamaları",
    DASHBOARD: "Panel",
    MARKET: "Market",
    MYQRCODES: "QR Code'larım",
    ALLQRCODES: "Tüm QR Code'lar",
    ACTIVEQRCODES: "Aktif QR Code'lar",
    PASSIVEQRCODES: "Pasif QR Code'lar",
    DELETEDQRCODES: "Silinmiş QR Code'lar",
    MASTERQRCODES: "Toplu Eklenen QR Code'lar",
    SUBSCRIPTIONS: "Fiyatlar ve Paketler",
    MYFOLDERS: "Klasörlerim",
    QRFOLDERS: "QR Code Klasörleri",
    MYMEDIA: "Medyalarım",
    ADDNEWFOLDER: "Yeni Klasör Ekle",
    CREATENEWQRCODE: "Yeni QR Code Oluştur",
    MYACCOUNT: "Hesabım",
    PAIDPLAN: "Ödeme Planı",
    ORDERHISTORY: "Sipariş Geçmişi",
    SUBMITTICKET: "Destek",
    HELP: "Yardım",
    PACKAGE_HISTORY: "Paket Geçmişi",
    DAY_REMANING: "Kalan Gün",
    SCAN: "Tarama",
    STATIC: "Statik",
    DYNAMIC: "Dinamik",
    PACKAGE_TITLE: "PAKET DETAYI",
    CORPARATE: "Kurumsal",
    TITLE: "Menü",
    BULKPRICE: "Menü fiyatlarını hızlı şekilde güncellemeye yarar",
  },
  QRLIST: {
    STATIC: "Statik",
    PREVIEW: "Oluşturmuş olduğunuz QR Kodlarınızın ön izlemesini yapabilirsiniz.",
    DYNAMIC: "Dinamik",
    MULTIPLY_QRCODE: "Kare kodu çoğalt",
    BULK_CREATE:
      "Bu QR Code'un altına yeni Qr Code'lar ekleyebilir, altındaki QR Code'ların içeriklerini ve tasarımlarını toplu halde güncelleyebilirsiniz.",
    BULK_UPLOAD: "Toplu Yükle",
    BULK_UPDATE: "Toplu Güncelle",
    BULK_SETTINGS: "Toplu Yükleme/Güncelleme Ayarları",
    CLICK_TO_DOWNLOAD: "İndirmek İçin Tılayın",
    CLICK_TO_UPLOAD: "Excel dosyanızı yüklemek için tıklayın",
    CLICK_TO_UPDATE: "Editlemek için tıklayın",
    SELECT_FILE: "Dosya Seçiniz",
    DOWNLOAD_SAMPLE_FILE: "Örnek dosyayı indir",
    DOWNLOAD_SUCCESSFUL: "İndirme başarılı!",
    CLICK_HERE_TO_CUSTOMIZE: "QR kodunuzun renk ve tasarımlarını özelleştirmek için buraya tıklayın!",
    CLICK_HERE_TO_FOLDER_CUSTOMIZE:
      "Sol menüdeki yeni kasör ekle alanında klasör oluşturup, kampanyalarınıza ait istediğiniz QR kod tiplerini filtreleyebilirsiniz.",
    CLICK_HERE_TO_EDIT: "Kartınızın içerik bilgilerinizi düzenlemek için başlığı tıklayın!",
    NON_TRACKABLE: "Statik / İzlenemez",
    MY_QR_CODE: "Kimliği ile My Qr Card'ı silmek üzeresiniz",
    DOWNLOAD_IN_PDF_FORMAT: "PDF formatında indir",
    DOWNLOAD_IN_PNG_FORMAT: "PNG formatında indir",
    DOWNLOAD_IN_SVG_FORMAT: "SVG formatında indir",
    DOWNLOAD_IN_EPS_FORMAT: "EPS formatında indir",
    DESC: `Lütfen QR Kodunuzu indirmeden önce farklı cihazlardan test ediniz. Baskı için .svg .eps .pdf web siteniz için ise .png 
    kullanabilirsiniz. Bu sayede çok büyük boyutlu afişler dahil olmak üzere tüm dokümanlarınızda basılı halde veya tasarım amaçlı 
    kullanabilirsiniz. Kontrollernizi tamamladığınızda kare kodlarınız baskıya hazır hale gelecektir.`,
    NOTE: "Not: Radial ve Gradient QR Kod çıktılarını .svg uzantılı olarak indirip açabilirsiniz.",
    BULK_UPLOAD_DESC:
      "Birden fazla qr kodu aynı anda oluşturabilmek için örnek excel dosyasını indirmeniz ve alanları personel bilgilerinizi doğru bir şekilde doldurmanız gerekmektedir. Her veri satırı yeni bir qr kodu oluşturacaktır. Örnek dosyayı indirmek için aşağıdaki excel ikonuna tıklayın ve dosyanızı göndermek için yükle butonunu kullanın.",
    BULK_CREATE_DESC: "Tüm qr kodlarınızı tek seferde indirin ve içeriklerini düzenleyin.",
    BULK_UPDATE_DESC: "Tüm qr kodlarınızı tek seferde indirin ve içeriklerini düzenleyin.",
    UPLOAD_YOUR_FILE: "Dosya yükle",
  },
  CREATEQR: {
    MENU: {
      DYNAMIC: {
        TITLE: "Dinamik QR Code",
        ITEMS: {
          WEBSITE: "Website",
          RESTAURANT: "Restoran",
          FEEDBACK: "Geri Bildirim",
        },
      },
      STATIC: {
        TITLE: "Statik QR Code",
        ITEMS: {},
      },
    },
    WIZARD: {
      BASIC_INFO: {
        TITLE: "İçerik Bilgilerinizi Ekleyin",
        CATEGORIES: "Kategoriler",
        CATEGORY_IMAGE: "Kategori Resmi",
        SELECT_IMAGE: "Resim Seçiniz",
        LOADER_TEXT: "",
        THIS_URL_IS_INVALID: "Bu URL Geçersiz",
        FIELDS: {
          key: "Alan",
          value: "Değeri",
          start: "Başlangıç",
          end: "Bitiş",
          video: "Video",
          title: "Başlık",
          image: "Resim",
          text: "Yazı",
          color: "Renk",
          description: "Açıklama",
          name: "İsim",
          number: "Numara",
          phone: "Telefon",
          buttonText: "Buton Yazısı",
          url: "URL",
          BASIC_INFORMATION: "Genel Bilgiler",
          SETTINGS: "Ayarlar",
          LANGUAGE: "Dil Seçin",
          ACTIVE_LANGUAGE: "Aktif Dil",
          ADD_SOME_INFO: "Gerekli bilgileri girin",
          FULL_NAME: "Tam Adı",
          COMPANY_NAME: "Firma Adı",
          JOB_TITLE: "İş Ünvanı",
          DEPARTMENT: "Bölümü",
          BLOOD_GROUP: "Kan Grubu",
          FOLDER_NO: "Sicil No",
          DATE_OF_START: "İşe Giriş Tarihi",
          COMPANY: "Şirket",
          MAIL: "E-posta",
          WEBSITE: "Website",
          PHONE: "Telefon",
          ADDRESS: "Adres",
          ENTER_LOCATION: "Lokasyon seçin",
          PLEASE_CHOOSE_LOCATION: "Önce Lokasyon seçin",
          MANAGER_FULL_NAME: "Sorumlu Adı",
          MANAGER_PHONE: "Sorumlu telefon",
          MANAGER_JOBTITLE: "Sorumlu görevi",
          MANAGER_MAIL: "Sorumlu E-mail",
          ART_NAME: "Eser adı",
          ART_ACCESSION_NUMBER: "Envanter Numarası",
          ART_ERA: "Dönemi",
          ART_MEASUREMENT: "Ölçüleri",
          ART_DISCOVERY_LOCATION: "Bulunduğu Yer",
          ART_ACCESSION_DATE: "Envantere Giriş Tarihi",
          ART_DESCRIPTION: "Eser Açıklaması",
          RESTAURANT_ABOUT_US: "",
          RESTAURANT_ADDRESS: "",
          RESTAURANT_PHONE: "",
          RESTAURANT_EMAIL: "",
          BLOODGROUP: "Kan grubu",
          MEDICATION: "İlaç tedavisi",
          PLATENUMBER: "Plaka numarası",
          ALLERGICTO: "Alerjisi olmak",
          DESCRIPTION: "Açıklama",
          RESPONSIBLESTAFFPHONE: "Yetkili Kişi",
          CITY: "İlçe/Şehir",
          FAX: "Faks",
          ZIP: "Posta Kodu",
          ADD_A_NEW_DOCUMENT: "Yeni Belge Ekle",
          QR_CODE_TITLE: "Qr Kod Başlığı",
          general_info: "Genel Bilgiler",
          BASIC_INFORMATION: "Genel Bilgiler",
          SHOW_CONTACT_FROM: "İletişim Formunu Göster",
          SHOW_GOOGLE_MAPS: "Google Haritayı Göster",
          SHOW_ADD_TO_CONTACTS: "Kişilere ekle opsiyonunu göster",
          SHOW_ADDRESS_FIELD: "Adres alanını göster",
          ADDITIONAL_INFO: "Ek Bilgi",
          SHOW_PHONE_ICON: "Telefon İkonu",
          SHOW_MAIL_ICON: "Mail İkonu",
          CONTACT_INFO: "İletişim Bilgisi",
          FIELDS: "Alan",
          YOU_HAVE_TO_FILL_THE_FIELDS_BEFORE_APPLY: "Başvurmadan önce alanları doldurmanız gerekmektedir.",
          SELECT_PDF: "PDF Seçiniz.",
          FDF_MENU_IF_YOU:
            "Her restoran için benzersiz menüler kullanmak istiyorsanız, buraya daha fazlasını ekleyebilirsiniz. Her restoranın kendi qr kodu olacaktır.",
          BASIC_INFORMATION: "Genel Bilgiler",
          DESCRIPTION_FIELD: "Alan için açıklamayı buraya yazabilirsiniz",
          CURRENT_RESTAURANTS: "Güncel Restoranlar",
          NO_TITLE: "Başlık Yok",
          NEW_RESTAURANT: "Yeni Restoran",
          BOOK_TITLE: "PDF Başlığı",
          PERSONNEL_INFO: "Personel Bilgisi",
          COMPANY_INFO: "Firma Bilgisi",
          ENABLE_DOCUMENTS_PAGE: "Belgeleri Etkinleştir",
          ENABLE_REMINDER: "Email Hatırlatmasını Aç/Kapa",
          ENABLE_COUNTER: "Sayacı Aç/Kapa",
          CUSTOM_FIELDS: "Özel Alanlar",
          CALL_LIST: "Arama Listesi",
          PERSON: "Personel",
          EMERGENCY_CALLS: "Acil Çağrılar",
          DOCUMENTS_LIST: "Doküman Listesi",
          GROUPS: "Grup",
          TITLE: "Başlık",
          SCHEDULE_INFO: "Program Bilgisi",
          DAY: "Gün",
          SHOW_SCHEDULE_CALENDER: "Program takvimini göster",
          MANAGER_INFO: "Yönetici Bilgisi",
          MAPS_STYLE: "Harita Stili",
          TEXT_ADDRESS: "Metin",
          TITLE_ADDRESS: "Başlık",
          SONG: "Müzik",
          ADD_MORE: "Yeni Ekle",
          ARTIST: "Sanatçı",
          NO_RESTRICTION: "Kısıtlama Yok",
          ARTIFACT_INFORMATION: "Eser Bilgileri",
          ARTIFACT_INFO: "Yapı Bilgisi",
          STORE_INFORMATION: "Magaza Bilgisi",
          PLATFORM: "Platform",
          OTHER_FIELDS: "Diğer Alanlar",
          WIFINAME: "Wifi Adı",
          RANDOM_LINK: "Random Link",
          SHOW_RANDOM_LINK: "Listeden rastgele bir öğe gösterir.",
          SHOW_ALL_RANDOM_LINK: "Listedeki tüm öğeleri gösterir.",
          MULTIPLE_LINK: "Çoklu Link",
          LINKS: "Linkler",
          PRODUCT_NAME: "Ürün Adı",
          GO_TO_WEBSITE: "Siteye Git",
          SELECT_ICON: "İkon Seçiniz",
          brochure_image: "Banner Resmi",
          SELECT: "Seçiniz",
          SELECT_IMAGE: "Resim Seçiniz",
          SELECT_RELATED_CATEGORY: "İlgili kategoriyi seçin",
          ABOUT_US_PAGE_INFO: "Hakkımızda Bilgisi",
          CONTACT_PAGE_INFO: "İletişim Bilgisi",
          RESERVATION_PAGE_INFO: "Rezervasyon Bilgisi",
          ABOUT_US_PAGE: "Hakkımızda Sayfası",
          CONTACT_PAGE: "İletişim Sayfası",
          RESERVATION_PAGE: "Rezervasyon Sayfası",
          BROCHURES: "Broşür",
          BANNER: "Banner",
          type: "Türü",
          REDIRECT: "Yönlendirme",
          SONGURL: "Şarkı bağlantısını yapıştırın...",
          isActive: "Aktif/Pasif",
          proximity: "Yakınlık",
          sort_order: "Sıralama",
          SHOW_MIDDLE_SOCIAL_MEDIA_BUTTONS: "Sosyal medya butonlarını göster",
        },
      },
      VALIDATION: {
        VAILD_PHONE_NUMBER: "Lütfen geçerli bir telefon numarası girin",
      },
      CUSTOMIZE: {
        TITLE: "Tasarım & Özelleştirme",
        FIELDS: {
          CUSTOMIZE_TEXT: "Yazılarını kişiselleştir",
          CUSTOMIZE_YOUR_PAGE: "Renk seçerek sayfanızı kişiselleştrin",
          SINGLE_COLOR: "Tek Renk",
          FIRST_COLOR: "Birinci Renk",
          SECOND_COLOR: "İkinci Renk",
          BG_COLOR: "Arkaplan Rengi",
          GRADIENT: "Gradyan Renk",
          COLOR: "Renk",
          PRIMARY: "Ana Renk",
          TEXT_COLOR: "Yazı Rengi",
          BUTTONS: "Butonlar",
          CHOOSE_BACKGROUND: "Arkaplan yada Renk Seçiniz",
          IMAGE: "Resim",
          CHOOSE_FILE_DROP: "Bir dosya seçin ya da buraya sürükleyin",
          BODY_OPTION: "Gövde Seçenekleri",
          LINEAR: "Linear",
          RADIAL: "Radial",
          TRANSPARENT_BACKGROUND: "Transparan Arkaplan",
          CUSTOMIZE_YOUR_PAGE_LAYOUT:
            "Aşağıdaki açma/kapama seçeneklerini değiştirerek sayfa düzeninizi özelleştirebilirsiniz.",
          CONTACT_EMAIL_LABEL: "İletişim Formunun gönderileceği mail adresi",
        },
      },
      SOCIAL_MEDIA_CHANNELS: {
        TITLE: "Sosya Medya Bağlantıları",
        FIELDS: {
          LINK_YOUR_SOCIAL: "Sosyal Medya Bağlantılarınızı ekleyin",
          YOU_CAN_REMOVE: "Çarpıya tıklayarak istediğiniz bağlantıyı silebilirsiniz",
          ADD_MORE: "Daha fazla ekle",
        },
      },
      MEDIA: {
        TITLE: "Resim & Video Ekleyin",
        FIELDS: {
          UPLOAD_PROFILE_PHOTO: "Kendine ait profil fotosu yükle",
          PHOTO: "Fotograf",
          STORY: "Hikaye",
          NOTE: "En iyi sonuç almak için 1:1 oranda fotograf tercih edin(ör: 300px/300px)",
          CHOOSE_MEDIA: "Yerel deponuzdan medya içeriği seçin",
          VIDEO: "Video",
          CHOOSE_STORY_VIDEO: "Hikayenizde göstermek için video yükleyin",
          YOUTUBE_VIDEO: "Youtube video linkini yapıştırın",
          PHOTOS: "Fotograflar",
          CHOOSE_PHOTO: "Hikayeleriniz için fotograf(lar) seçin",
        },
      },
      CATEGORY: {
        TITLE: "Kategoriler & Ürünler",
      },
      WELCOME_SCREEN: {
        TITLE: "Karşılama Ekranı Özelleştir",
        FIELDS: {
          LOADING_SCREEN_LOGO: "Karşılama Ekranı Logosu",
          SELECT_FROM_MEDIA: "Medyalarımdan Seçmek için Tıkla",
          SELECT_FROM_ANIMATIONS: "Kendi Logonu Ekle",
          LOADING_SCREEN_BACKGROUND: "Karşılama Ekranı Arkaplanı",
          SINGLE_COLOR: "Tek Renk",
          GRADIENT: "Gradyan Renk",
          YOUR_INFORMATION: "Bilgiler",
          FIRST_FIELD: "Yükleme Yazısı",
          SECOND_FIELD: "İkinci Alan",
        },
      },
      ADVANCED_SETTINGS: {
        TITLE: "Gelişmiş Ayarlar",
        FIELDS: {
          PASSWORD: "Şifre",
          AGE_BELOW: "Yaş Kısıtlaması",
          PASS_CODE_HERE: "Kodu Buraya Yapıştırın...",
          TIME_ZONE: "Saat Dilimi",
          PICK_START_DATE: "Başlangıç Tarihi",
          CANCEL: "Vazgeç",
          OK: "Tamam",
          STARTING_DATE: "Başlangıç Saati",
          ENDING_DATE: "Bitiş Saati",
          PICK_END_DATE: "Bitiş Tarihi",
        },
      },
      CREATEQR: "QR Code Oluştur",
      UPDATE_CHANGES: "Bilgileri Güncelle",
    },
    FOLLOWALBE: "Takip Edilebilir (Ücretli)",
    NOT_TRACKED: "Takip Edilemez (Ömür Boyu Ücretsiz)",
  },
  RESTAURANT: {
    title: "Başlık: ",
    description: "Açıklama: ",
    cover_image: "Görsel: ",
    category_icon: "Kategori İkonu: ",
    categories: "Kategoriler",
    category: "Kategori",
    product: "Ürün",
    products: "Ürünler: ",
    product_name: "Ürün adı",
    preparation: "Hazırlama Süresi: ",
    calorie: "Kalori",
    ingredients: "İçindekiler: ",
    price: "Fiyat: ",
    price_label: "Fiyat başlığı",
    likes: "Beğeniler: ",
    current_categories: "Aktif Kategoriler",
    add_new_field: "Yeni Alan Ekle",
    add_new_category: "Yeni Kategori Ekle",
    add_new_product: "Yeni Ürün Ekle",
    add_new_price: "Ekle",
    please_add_product: "Lütfen ürün ekleyin",
    brochure_slider: "Bannerlar",
    delete_product: "ÜRÜNÜ SİL",
    delete_category: "KATEGORİYİ SİL",
    new_category_title: "Yeni Kategori",
    new_category_description: "Yeni kategoriye ait açıklama",
    new_product_title: "Yeni Ürün",
    new_product_description: "Yeni ürüne ait açıklama",
    status_desc: "Pasif olan kategoriler yayından kaldırılır.",
    status: "Durumu",
    detailed_title: ({ named }) => `${named("value")} Detayı`,
  },
  ERRORS: {
    err_1: "Hata; Üzgünüz, ancak bilinmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
    err_2: "Hata; Üzgünüz, avatar yüklenirken beklenmeyen bir hata meydana geldi. Lütfen tekrar deneyin.",
    err_3: "Hata; Üzgünüz, bağlantı adresi hatalı. Lütfen geçerli bir bağlantı adresi kullanın. ",
    err_4: "Hata; Üzgünüz, bu e-mail zaten onaylandı. Başka bir e-mail kullanmayı deneyin.",
    err_6: "Hata; Üzgünüz, bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
    err_7: "Hata; Üzgünüz, çok fazla sorgu oluştu. 1 dakika sonra tekrar deneyin.",
    err_8: "Hata; Mail onaylı değil",
    err_9: "Hata; Üzgünüz, şifre hatalı. Lütfen doğru şifreyi girin.",
    err_10: "Hata; Üzgünüz, bu adresle kayıtlı bir kullanıcı bulunamadı.",
    err_11: "Hata; Üzgünüz, kullanıcı sözleşmesini kabul etmediniz. Lütfen sözleşmeyi onaylayın.",
    err_12: "Hata; Üzgünüz, kullanıcı silinmiş. Lütfen destek ekibimiz ile iletişime geçin.",
    err_13: "Hata; Üzgünüz, kullanıcı hesabı askıya alınmış. Lütfen destek ekibimiz ile iletişime geçin.",
    err_14: "Hata; Üzgünüz, hesap durumu pasif durumda. Lütfen destek ekibimiz ile iletişime geçin.",
    err_15:
      "Hata; Mevcut paketinizin bitiş süresine 1 yıldan daha fazla süre var. Lütfen destek ekibimiz ile iletişime geçin.",
    err_16:
      "Uyarı!!!; Beklenen bir havale işleminiz bulunmaktadır. Farklı bir sipariş verebilmek için öncelikle mevcut siparişinizi iptal etmeniz gerekmektedir. İptal etmek ister misiniz?",
    err_17: ({ named }) =>
      `Hata; Üzgünüz, ${named(
        "value"
      )} mb'lık disk alanı dolu. Yeni bir dosya yüklemek için yer açmanız gerekiyor. Dilerseniz destek ekibimizle iletişime geçebilirsiniz.`,
    err_18: "Hata; Bu kod zaten kullanıldı. Lütfen başka bir kod deneyin.",
    err_19: "Hata; Geçersiz kod. Lütfen doğru bir kod girin.",
    err_20: "Hata; Bu kodun süresi doldu. Lütfen yeni bir kod almayı deneyin.",
    err_21: "Hata; Alt pakete geçiş yapılamamaktadır.",
    err_22: "Hata; Geçersiz kod. Lütfen doğru bir kod girin.",
    err_23: "Hata; Geçersiz anahtar. Lütfen doğru bir anahtar girin.",
    err_24: "Hata; Bu posta adresiyle kayıtlı bir kullanıcı bulunamadı. Lütfen doğru bir posta adresi kullanın.",
    err_25: "Hata; Dosya adını boş bırakmayınız.",
    err_26: "Hata; Bu dosya adını daha önceden kullandınız. Lütfen farklı bir dosya adı belirleyiniz.",
    err_100: "Hata; E-posta alanı zorunludur",
    err_101: "Hata; E-posta alanı metin tipinde olmalıdır.",
    err_102: "Hata; Lütfen geçerli bir e-posta adresi giriniz.",
    err_103: "Hata; E-posta alanı en fazla 255 karakter olmalıdır.",
    err_104: "Hata; Bu e-posta adresi zaten kayıtlıdır.",
    err_105: "Hata; E-posta adresi kabul edilmemektedir. Lütfen kabul edilen bir e-posta uzantısı giriniz.",
    err_106: "Hata; İsim alanı zorunludur.",
    err_107: "Hata; İsim alanı metin tipinde olmalıdır.",
    err_108: "Hata; İsim alanı en fazla 255 karakter olmalıdır.",
    err_109: "Hata; Şifre alanı zorunludur.",
    err_110: "Hata; Şifre en az 6 karakter içermelidir.",
    err_111: "Hata; Şifre en az bir harf içermelidir.",
    err_112: "Hata; Şifre en az bir rakam içermelidir.",
    err_113: "Hata; Ülke alanı zorunludur.",
    err_114: "Hata; Geçersiz bir ülke seçtiniz.",
    err_115: "Hata; Organizasyon alanı zorunludur.",
    err_116: "Hata; Kullanım koşullarını kabul etmelisiniz.",
    err_404: "Hata; Aradığınız içerik bulunamadı. Lütfen doğru bir sayfa veya içerik adı girin.",
    err_500:
      "Hata; Üzgünüz, Bilinmeyen bir hata oluştu. Lütfen sayfayı yenileyerek tekrar deneyiniz.Eğer hata devam ederse lütfen bizimle iletişime geçin.",
    err_1001: "Hata; Üzgünüz, bu eylem için yetkiniz bulunmamaktadır. Lütfen yetkili biriyle iletişime geçin.",
    err_1002: "Hata; Üzgünüz, paketinizin süresi doldu. Yeni bir paket satın almayı deneyin.",
    err_1003: "Hata; Üzgünüz, paketinizin maksimum sınırına ulaştınız. Daha fazla içerik ekleyemezsiniz.",
    err_1004: "Hata; Bu qr kodu türüne sahip değilsiniz. Qr kod türünü içeren paketlerimize geçiş sağlayabilirsiniz.",
    err_1005: "Hata; Maksimum sayı olan 100'ü aştınız. Daha fazla eklemeye izninir bulunmamaktadır. ",
    err_1006: "Hata; Bağlantı doğrulamalarınız başarısız oldu. Lütfen doğru bağlantı bilgilerini kullanın.",
    err_1007: ({ named }) =>
      `Hata; Aktif paketiniz olmadığı için ${named("value")}'den fazla statik kare kod yaratamazsınız`,
    err_1008: ({ named }) => `Hata; Paketiniz bittiği için en fazla ${named("value")} klasör yaratabilirsiniz. `,
    err_2001: "Hata; Geçersiz karakter. Lütfen geçerli bir karakter kullanın.",
    err_2002: "Hata; Bu URL mevcut. Lütfen farklı bir URL deneyin.",
    err_2003: "Hata; Bilinmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
    err_2004: "Hata; Değerler boş olamaz. Lütfen geçerli bir vars değeri girin.",
    err_2005: "Hata; Vars değeri en az 3 karakter uzunluğunda olmalıdır. Lütfen daha uzun bir değer girin. ",
    err_2006: "Hata; Vars değeri 30 karakterden az olmalıdır. Lütfen daha kısa bir değer girin. ",
    err_2007: "Hata; Resim seçmeniz gerekiyor. Lütfen bir resim seçin. ",
    err_2008: "Hata; Özel karakter kabul edilmemektedir. Sadece sayı ve rakam kullanabilirsiniz.",
    err_2009: "Hata; Dosya seçmeniz gerekiyor. Lütfen bir dosya seçin.",
    err_2010: "Hata; Sadece dosya kabul edilmektedir. Lütfen geçerli formatta veri yükleyiniz",
    err_2011: "Hata; En fazla 25mb dosya yüklenebilmektedir. Lütfen farklı bir dosya yükleyiniz.",
    err_2012: "Hata; Bu isimde bir dosyayı daha önce yüklendiniz. Lütfen farklı isimde bir dosya yükleyiniz.",
    err_2013:
      "Hata; Devam eden işleminiz bulunmaktadır. İşleminizin bittiği size mail olarak iletilecektir. Daha sonra tekrar deneyebilirsiniz.",
    err_2014:
      "Hata; Deneme paketinde toplu olarak en fazla 10 qr kod yükleyebilirsiniz. Daha fazlası için lütfen paketinizi güncelleyin.",
    err_2015:
      "Hata; Excel dosyanız tek seferde yükleme için en fazla 400 satır olması gerekmektedir. Dosyanızı güncelleyerek tekrar deneyiniz.",
    err_2016: "Hata; Toplu indirme dosyalanız bulunmamaktadır. Yükleme işleminden sonra tekrar deneyiniz.",
    err_2017: "Hata; Toplu yükleme limitini aştınız. Lütfen bizimle irtibata geçiniz.",
    err_2017: "Hata; Yeni bir excel dosyası yükleyebilmek için lütfen eski dosyalarınızı en az 1 tanesini silin",
    err_2019:
      "Hata; Verileriniz arasında uygunsuz veya yönlendirme olan linkler tespit edilmiştir. Lütfen bunları değiştirerek tekrar deneyiniz.",
    err_2020:
      "Hata;  Sadece .xslx uzantılı excel dosyaları yüklenebilmektedir. Dosya türünüzü kontrol ederek tekrar deneyiniz.",
    err_2021: "Hata; En fazla 2mb büyüklüğünde excel dosyaları yüklenebilmektedir. Lütfen farklı bir dosya yükleyiniz.",
    err_2022: "Hata; Bu işlemi yapabilmek için en az 2 tane QR Code'unuzun olması gerekir.",
    err_2023: "Hata; Lütfen en az 1 alt qr kod seçiniz.",
    err_2024:
      "Hata; Bu işlemi gerçekleştirebilmek için en az 1 tane ana QR kodunuzun olması gerekir. Lütfen en az 1 alt qr kod seçiniz.",
    err_2025: ({ named }) =>
      `Hata; En fazla ${named("value")} mb'lık dosya yüklenebilmektedir. Lütfen daha küçük bir dosya yükleyiniz.`,
    err_2026: `Hata; En fazla 1000 px genişliğinde resim yüklenebilmektedir. Lütfen resminizi genişliği ve/veya yüksekliği en fazla 1000px olacak şekilde resminizi tekrar boyutlandırınız.`,
    err_2027: "Hata; Alan adı formatı hatalı.",
    err_2028: "Hata; Bu alan adı zaten eklendi.",
    err_2029: "Hata; CNAME kaydı doğrulanamadı.",
  },
  PAYMENT: {
    unknown_reason: "Bilinmeyen sebep",
    payment: "Ödeme",
    successful: "BAŞARILI",
    failed: "BAŞARISIZ",
    awaiting: "BEKLİYOR",
    reason: "Sebep",
    payment_successful_desc: `Sayın Müşterimiz,
    Siparişiniz başarıyla alınmıştır, Paketiniz hesabınıza yasıtılmıştır.    
    Herhangi bir sorunuz veya endişeniz varsa, lütfen çekinmeden bizimle iletişime geçin. Size yardımcı olmaktan mutluluk duyarız.
    İlginiz ve güveniniz için teşekkür ederiz!`,
    payment_failed_desc: `Sayın Müşterimiz,
    Üzgünüz, ödemenizde bir hata olduğunu tespit ettik ve konu ile ilgili bizden destek alabilirsiniz.
    Size yardımcı olmaktan mutluluk duyarız.`,
    payment_awaiting_desc: `Sayın Müşterimiz,
    Siparişiniz için teşekkür ederiz! Siparişinizin işleme alınması için gereken son adım ödemenizin tamamlanmasıdır. 
    Ancak, sistemimizde yapılan kontrolde, henüz ödemenizin tamamlanmadığını tespit ettik.
    Lütfen ödemenizi tamamlamak için ödemenizi banka hesap numaralarımıza yapabilirsiniz.`,
    title_success: () => `<strong>Teşekkür Ederiz</strong>, Siparişiniz Alındı!`,
    title_fail: () => `<strong>Üzgünüz</strong>, Ödeme Başarısız!`,
    title_awaiting: () => `<strong>Teşekkürler</strong>, Siparişiniz Alınmıştır!`,
    err_105: "Ödeme kullanıcı tarafından iptal edildi",
    err_000: "Ödeme işlemi başarısız",
  },
  SNACKBAR: {
    qrcode_updated: "Kare kod güncellendi!",
    qrcode_created: "Kare kod oluşturuldu!",
    verify_email: "Email'in onaylanması gerekiyor",
    login_successful: "Giriş başarılı",
    logged_out: "Çıkış yapıldı!",
    registeration_successful: "Kayıt başarılı",
  },
  DIALOG: {
    STEP_BY_STEP_TOUR_TITLE: "Adım Adım Tura Hoş Geldiniz",
    STEP_BY_STEP_TOUR_DESC:
      "Merhaba kullanıcı! Buraya ilk gelişiniz olduğunu anladık. Bu tur size bölümler arasında rehberlik edecek ve işlerin nasıl yürüdüğünü açıklayacaktır. Eğer atlamak istersen turu tıklayarak yapabilirsiniz Aşağıdaki atla düğmesine tıklayınız.",
    STEP_BY_STEP_TOUR_SKIP: "Atla",
    STEP_BY_STEP_TOUR_START: "Başlat",
    STEP_BY_STEP_TOUR_CREATE_QR: "QR Code Oluştur",
    STEP_BY_STEP_TOUR_GET_STARTED_CREATE_QR_CODE: "Qr kod oluşturmak için yukarıdaki butonu kullanabilirsiniz.",
  },
  POPUP: {
    error_title: "Hata!",
    CHOOSE_ONE: "QR Code Tipi Seçiniz",
    TRANSPARENT_QR_CODE: "Transparan QR Code",
    STANDART_QR_CODE: "Standart QR Code",
    BANNER_QR_CODE: "Banner QR Code",
    GO_BACK: "Geriye Dön",
    QR_CODE_BACKGROUND_PICTURE: "QR koduma istediğim arka plan resmini koyabilmek istiyorum",
    QR_CODE_COLOR: "QR Code şeklini ve renklerini özelleştirebilmek istiyorum",
    QR_CODE_BANNER: "Bir banner oluşturmak QR Code boyutunu özelleştirmek istiyorum",
    upgradeContent: ({ named }) =>
      `<div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <img width="200px" src="https://vue.qrcodematic.com/backgroundImages/popup01/istockphoto-1176277785-612x612_preview-1686842223.jpg">
      <h1 class="text-h5"><span style="font-weight: 900;" class="button_green--text text-h4">Daha fazla</span> <span style="font-weight: 200; color:#919191">oluşturmak için</span> <strong class="text-h4 button_green--text">Yükselt</strong></h1>
      <p style="font-size: 1rem; max-width: 360px; text-align: justify;">Paketinin limiti olan <strong class="orange--text">${named(
        "total"
      )}</strong> kare kod sayısına ulaştın</strong>. Daha fazla kare kod oluşturmak için premium paketlerimizi incelen</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Yükselt <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>`,
    packageController: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5">Paketinizin süresi <strong style="color: tomato">doldu!</strong></h1>
      <p style="width: 300px; text-align: justify"><strong class="button_green--text"><i class="fa-solid fa-rectangle-pro fa-lg"></i> Paket</strong> satın alıp QR Code Matic'i tekrar kullanmaya devam edebilirsin.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Yükselt <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    upgradeToPro: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5">Bu Özellik Sadece <strong style="color: tomato">PRO</strong>'ya Özel</h1>
      <p style="width: 300px; text-align: justify"><strong class="button_green--text"><i class="fa-solid fa-rectangle-pro fa-lg"></i> Paket</strong> satın alıp QR Code Matic'i tekrar kullanmaya devam edebilirsin.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Yükselt <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    allowedTypeController: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5"><strong class="orange--text">${named(
        "typeName"
      )}</strong> kullanmaya devam etmek için yükselt</h1>
      <p>Kullandığınız paket bu kare kod tipini kapsamıyor.<strong>Pro</strong> plana geçerseniz bu ve diğer premium kare kodları bir sınır olmadan kullanmaya başlayabilirsiniz.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Yükselt <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    getMoreInformation: ({ named }) => `
    <div>
     <p>Dil ayarı, seçtiğiniz her dil için farklı bilgiler girmenize olanak sağlar. QR kodunun son kullanıcısı, birden fazla dil eklerseniz canlı sürümde sağ üst köşede dil menüsüne sahip olacaktır.</p>
     <p>Kullanımı şu şekilde;</p>
     <ol>
      <li>Önce <span class="font-weight-bold">"+"</span> butonuna tıkla</li>
      <li>Eklemek istediğiniz dilleri seçin. Birden fazla dil seçebilirsiniz. Ardından içeriğin kopyalanacağı dil için bir dil seçin. (Bu seçenek, bu QR kodu türü için mevcut olmayabilir.) Ardından <span class="font-weight-bold">"TAMAM"</span> a tıklayın</li>
      <li>Şimdi eklenen yeni dil bayrak simgelerine tıklayabilir ve <span class="font-weight-bold">"Aktif"</span> düğmesine tıklayabilirsiniz. Bunu yaparak, bu belirli dil için içerik bilgilerinizi değiştirebilirsiniz.</li>
      <li>Herhangi bir eklenen dili kaldırmak için <span class="font-weight-bold">"Sil"</span> düğmesine tıklayın. Unutmayın, en az bir diliniz olması gerektiğini unutmayın.</li>
      <li><span class="font-weight-bold">"Varsayılan olarak ayarla"</span> düğmesine tıklamak, bu dili varsayılan olarak kaydetmenizi sağlar ve kullanıcılar sayfanızı ziyaret ettiklerinde bu dilde karşılanır.</li>
     </ol>
    </div>
    `,
    getInfoAboutTitle: ({ named }) => `
    <div>
   <p class="text--justify" style="font-size: 14px">QR kod başlığınız, qr kod listesi sayfasındaki resmin altında aşağıdaki gibi görünecektir. QR kodunuzu oluşturmayı tamamladıktan 
   sonra bu sayfaya yönlendirileceksiniz.<br>
   QR kod başlığını daha sonra düzenleyebilirsiniz
   </p>
   <div class="d-flex justify-content-center" style="width: 100%;">
      <img src="/media/tutorials/qrcode-title.png" style="max-width: 400px; object-fit: cover; border-radius: 5px; "/>
   </div>
  </div>
    `,
  },
  BULK: {
    create_new_child: "Yeni alt QR Code'lar oluşturmak istiyorum",
    update_childs_data: "Alt QR Kodlarıma ait detaylı bilgileri değiştirmek istiyorum",
    update_data: "Tüm alt QR Code'larımın genel bilgilerini güncellemek istiyorum",
    zip_request: "Tüm QR Code'larımı SVG formatında indirmek istiyorum",
    zip_downloaded: "Zip dosyası indirildi!",
    download_master: "Master Excel Dosyasını İndir",
    update_config_button_text: "Tüm alt QR Code'larımın renk, dizayn ve diğer ayarlarını güncellemek istiyorum.",
    update_data_description: () => `<p style="text-align: center">
    Master'ın altındaki kare kodların güncellenmesini istediğiniz alanları aşağıdaki
    kutulardan seçin. Master'ın altındaki tüm kare kodlar seçtiğiniz excel
    dosyasındaki(vcard-bulk-update) ayarlarla otomatik olarak güncellenecek.
  </p>
  <p style="text-align: center">
    Örneğin; <strong>avatar kutucuğunu</strong> seçer, <strong>Güncelle</strong> butonuna
    basarsanız, seçtiğiniz excel(vcard-bulk-update) içine yazdığınız
    <strong>avatar değeri</strong> master altındaki tüm kare kodlara uygulanmak üzereye
    yapılacak güncelleme işlemi sıraya alınır.
  </p>`,
    update_config_description: () => `<p>
  Bu işlem <strong>master</strong> üzerinde yaptığınız değişiklikleri tek seferde alt QR Code'larına uygulamak içindir. Master'a ait listede belirtilen tüm özellikler alt QR Code'lara uygulanır.
  <ul>
    <li>Tüm QR Code renk ayarları</li>
    <li>Tüm QR Code tasarımları</li>
  </ul>
</p>
<p>
  Güncellemeyi yaparsanız tüm alt QR Code'ların tasarımı master ile aynı olur.
</p>`,
    create_description: () => `Toplu QR Code oluşturmak için aşağıdaki yükleme alanına düzenlediğiniz excel dosyasını
sürükleyin ve açılan <strong class="green--text">YÜKLE</strong> butonuna tıklayın.`,
    update_options_title: "Güncelleme Seçenekleri",
    avatar: "Avatar",
    are_you_sure: "Emin Misin ?",
    see_qr_codes: "QR Code'larını Gör",
    see_qr_codes_tooltip: "Altındaki QR Code'ları görebilir, tekrar master'a çevirebilirsiniz.",
    background: "Arkaplan",
    settings: "Ayarlar",
    advanced_settings: "Gelişmiş Ayarlar",
    update_data_button_text: "Güncelle",
    update_config_button_text: "Alt QR Code'larının tasarımını ve renkleri güncelle",
    show_previously_uploaded: "Önceki Excel Yüklemelerimi Göster",
    reached_upload_limit: "Yükleme limitiniz dolu. Önce listeden bir ya da bir kaç tane dosya silmeniz gerekli.",
    upload_error_text:
      "Yüklediğiniz excel dosyasına girdiğiniz veriler doğru formatta değil. Lütfen aşağıda belirtilen hatalı alanlara ait verileri kontrol edip tekrardan yüklemeyi deneyin.",
    back_to_upload: "Yüklemeye geri dön",
    select_from_list: "Yapmak istediğiniz işlemi aşağıdan seçin",
  },
  NEWS: {
    firstVisitMessage: ({ named }) => `
    <p> Bugün sizleri heyecan verici bir haberle karşılıyoruz. QR kod sitemizi yeniledik! Sizlere daha iyi bir deneyim sunmak için bir dizi geliştirmeler yaptık.</p>
    <p>QR kodlar, hızlı ve kolay bilgi paylaşımı için vazgeçilmez bir araç haline geldi. Biz de bu teknolojiyi geliştirerek, sizlere en iyi hizmeti sunmak için çalışıyoruz.</p>
    `,
    firstVisitFullMessage: ({ named }) => `
    <p> Bugün sizleri heyecan verici bir haberle karşılıyoruz. QR kod sitemizi yeniledik! Sizlere daha iyi bir deneyim sunmak için bir dizi geliştirmeler yaptık.</p>

    <p>QR kodlar, hızlı ve kolay bilgi paylaşımı için vazgeçilmez bir araç haline geldi. Biz de bu teknolojiyi geliştirerek, sizlere en iyi hizmeti sunmak için çalışıyoruz.</p>

    <p> Yeni QR kod sitemiz, kullanıcı dostu bir tasarıma sahip. Artık sitemize giriş yapmak ve QR kod oluşturmak için daha az adım atmanız yeterli olacak. 
    Kullanıcı arayüzümüzü kolaylaştırdık ve sizi gereksiz tıklamalardan kurtardık. Hızlı, akıcı ve sorunsuz bir deneyim için tasarımımızı yeniden düzenledik.</p>

    <p>Ayrıca, QR kodlarımızın daha işlevsel ve esnek olmasını sağlamak için yeni özellikler ekledik. Artık oluşturduğunuz QR kodları daha fazla veriyle kişiselleştirebilirsiniz. 
    Web sitenizin veya sosyal medya profillerinizin linklerini QR kodunuza ekleyebilir, enfes tatlarınızın menülerini oluşturabilir, e-posta adresinizi veya telefon numaranızı 
    içeren bir vCard oluşturabilirsiniz. QR kodlarımızın kullanım alanınıgenişletmek için birçok seçenek sunuyoruz.</p>

    <p>Bu geniş kullanım alanındaki güvenliğiniz bizim önceliğimiz. Yeni QR kod sitemizde, verilerinizi ve bilgilerinizi korumak için ek güvenlik önlemleri aldık. 
    Kişisel ve hassas bilgilerinizin güvenliğinisağlamak için daha iyi bir temel oluşturduk.</p>

    <p> QR kodlarımızın hızı da artık daha iyi. Geliştirilmiş algoritma ve optimize edilmiş sistemler sayesinde, QR kodlarınızı daha hızlı taramanız ve işlemlerinizi daha kısa sürede tamamlamanız mümkün olacak. Zamanınızı
        daha verimli kullanabilmeniz için teknolojik altyapımızı güncelledik.</p>    

    <p> Siz değerli kullanıcılarımızın geri bildirimleri ve ihtiyaçları bizim için her zaman önemlidir. Bu nedenle,yenilenen QR kod sitemizde sizlere daha iyi bir deneyim sunabilmek için sürekli olarak çalışıyoruz.
        Sitemizin kullanımıyla ilgili herhangi bir sorunuz, öneriniz veya geri bildiriminiz varsa, lütfen bize bildirin. Sizin memnuniyetiniz, bizim başarımızdır. Hoşgeldiniz</p>
    
    `,

    HELLO: "Merhaba, yenilendik!",
    we_are_improving_to_provide_you_with_better_service:
      "Size daha iyi hizmet verebilmek için kendimizi geliştiriyoruz",
  },

  TERMS: {
    terms_text: ({ named }) =>
      `
      <h1>QR Code Matic Site Kullanım Şartları</h1>
      <hr>
      <p>Bu QR Kod oluşturma sitesini kullanan ve Qr Kod oluşturan müşterilerimiz aşağıdaki şartları kabul etmiş sayılmaktadır. Ayrıca sitemizdeki web sayfaları ve ona bağlı tüm sayfalar (‘Site’) FSM Bulvarı Fethiye Mah. Fazıl Sk. No:8 D:2 Nilüfer Bursa adresindeki Medyax Bilişim Hizmetleri firmasının (Firma) malıdır ve onun tarafından işletilir. Sizler (‘Kullanıcı’) sitede sunulan tüm hizmetleri kullanırken aşağıdaki şartlara tabi olduğunuzu, sitedeki hizmetlerden yararlanmakla ve kullanmaya devam etmekle; bağlı olduğunuz yasalara göre sözleşme imzalama hakkına, yetkisine ve hukuki ehliyetine sahip ve 18 yaşın üzerinde olduğunuzu, bu sözleşmeyi okuduğunuzu, anladığınızı ve sözleşmede yazan şartlarla bağlı olduğunuzu kabul etmiş sayılırsınız. İşbu sözleşme, taraflara sözleşme konusu site ile ilgili hak ve yükümlülükler yükler ve taraflar işbu sözleşmeyi kabul ettiklerinde bahsi geçen hak ve yükümlülükleri eksiksiz, doğru, zamanında, işbu sözleşmede talep edilen şartlar dâhilinde yerine getireceklerini beyan ederler.</p>
      <p>İşbu sözleşme taraflara sözleşme konusu site ile ilgili hak ve yükümlülükler yükler ve taraflar işbu sözleşmeyi kabul ettiklerinde bahsi geçen hak ve yükümlülükleri eksiksiz, doğru, zamanında, işbu sözleşmede talep edilen şartlar dâhilinde yerine getireceklerini beyan ederler.</p>
      <p>
      <h2>1. SORUMLULUKLAR</h2>
        <p> a. Firma, fiyatlar ve sunulan ürün ve hizmetler üzerinde değişiklik yapma hakkını her zaman saklı tutar.</p>
        <p> b. Firma, üyenin sözleşme konusu hizmetlerden, teknik arızalar dışında yararlandırılacağını kabul ve taahhüt eder.</p>
        <p> c. Kullanıcı, sitenin kullanımında tersine mühendislik yapmayacağını ya da bunların kaynak kodunu bulmak veya elde etmek amacına yönelik herhangi bir başka işlemde bulunmayacağını,aksi halde ve üçüncü kişiler nezdinde doğacak zararlardan sorumlu olacağını, hakkında hukuki ve cezai işlem yapılacağını peşinen kabul eder. </p>
        <p> d. Kullanıcı, site içindeki faaliyetlerinde, sitenin herhangi bir bölümünde veya iletişimlerinde genel ahlaka ve adaba aykırı, kanuna aykırı, üçüncü kişilerin haklarını zedeleyen, yanıltıcı, saldırgan, müstehcen, pornografik, kişilik haklarını zedeleyen, telif haklarına aykırı, yasa dışı faaliyetleri teşvik eden içerikler üretmeyeceğini, paylaşmayacağını kabul eder. Aksi halde oluşacak zarardan tamamen kendisi sorumludur ve bu durumda site yetkilileri, bu tür hesapları askıya alarak veya sona erdirerek yasal süreç başlatma hakkını saklı tutar. Bu sebeple yargı mercilerinden etkinlik veya kullanıcı hesapları ile ilgili bilgi talepleri geldiğinde paylaşma hakkını saklı tutacaktır.</p>
        <p> e. Sitenin üyelerinin birbirleri veya üçüncü şahıslarla olan ilişkileri kendi sorumluluğundadır.</p>
      </p>

      <h2>2. Fikri Mülkiyet Hakları</h2>
    <p> 2.1. İşbu sitede yer alan ünvan, işletme adı, marka, patent, , tasarım, sistem kodları, bilgi ve yöntem gibi tescilli veya tescilsiz tüm fikri mülkiyet hakları site işleteni ve sahibi firmaya veya belirtilen ilgilisine ait olup, ulusal ve uluslararası hukukun koruması altındadır. İşbu sitenin ziyaret edilmesi veya bu sitedeki hizmetlerden yararlanılması söz konusu fikri mülkiyet hakları konusunda hiçbir hak vermez.</p>
    <p>2.2. Sitede yer alan bilgiler hiçbir şekilde çoğaltılamaz, yayınlanamaz, kopyalanamaz, sunulamaz ve/veya aktarılamaz. Sitenin bütünü veya bir kısmı diğer bir internet sitesinde izinsiz olarak kullanılamaz.</p>


  <h2>3. Gizli Bilgi</h2>
    <p> 3.1. Firma, site üzerinden kullanıcıların ilettiği kişisel bilgileri üçüncü kişilere açıklamayacaktır. Bu kişisel bilgiler; kişi adı-soyadı, adresi, telefon numarası, cep telefonu, e-posta adresi gibi kullanıcıyı tanımlamaya yönelik her türlü diğer bilgiyi içermekte olup, kısaca ‘Gizli Bilgiler’ olarak anılacaktır.</p>
    <p> 3.2. Kullanıcı; sadece tanıtım, reklam, kampanya, promosyon, duyuru vb. pazarlama faaliyetleri kapsamında kullanılması ile sınırlı olmak üzere, sitenin sahibi olan firmanın kendisine ait iletişim, portföy durumu ve demografik bilgilerini iştirakleri ya da bağlı bulunduğu grup şirketleri ile paylaşmasına muvafakat ettiğini kabul ve beyan eder. Bu kişisel bilgiler firma bünyesinde müşteri profili belirlemek, müşteri profiline uygun promosyon ve kampanyalar sunmak ve istatistiksel çalışmalar yapmak amacıyla kullanılabilecektir.</p>
    <p>3.3. Gizli Bilgiler, ancak resmi makamlarca bu bilgilerin talep edilmesi halinde ve yürürlükteki emredici mevzuat hükümleri gereğince resmi makamlara açıklama yapılmasının zorunlu olduğu durumlarda resmi makamlara açıklanabilecektir. </p>


  <h2>4. Garanti Vermeme </h2>
    <p>İşbu sözleşme maddesi uygulanabilir kanunun izin verdiği azami ölçüde geçerli olacaktır. Firma tarafından sunulan hizmetler "Olduğu Gibi" ve "Mümkün Olduğu" temelde sunulmakta ve pazarlanabilirlik, belirli bir amaca uygunluk veya ihlal etmeme konusunda tüm zımni garantiler de dahil olmak üzere hizmetler uygulama ile ilgili olarak (Bunlarda yer alan tüm bilgiler dahil) sarih veta zımni, kanuni veya başka bir nitelikte hiçbir garantide bulunulmamaktadır. </p>

  <h2>5.Kayıt ve Güvenlik  </h2>
    <p> Kullanıcı; doğru, eksiksiz ve güncel kayıt bilgilerini vermek zorundadır. Aksi halde bu sözleşme ihlal edilmiş sayılacak ve kullanıcı bilgilendirilmeksizin hesap kapatılabilecektir. Kullanıcı, site ve üçüncü taraf sitelerdeki şifre ve hesap güvenliğinden kendisi sorumludur. Aksi halde oluşacak veri kayıplarından ve güvenlik ihlallerinden veya donanım ve cihazların zarar görmesinden firma sorumlu tutulamaz. Qr kodlarınızı baskıya göndermeden önce birkaç farklı cihazdan test etmeniz gerekmektedir. Hatalı baskı ve/veya oluşturma işlemlerinden firmamız sorumlu değildir. </p>

  <h2>6. Mücbir Sebep </h2>
    <p> Tarafların kontrolünde olmayan; tabii afetler, yangın, patlamalar, iç savaşlar, savaşlar, ayaklanmalar, halk hareketleri, seferberlik ilanı, grev, lokavt ve salgın hastalıklar, altyapı ve internet arızaları, elektrik kesintisi gibi sebeplerden (Aşağıda "Mücbir Sebep” Olarak Anılacaktır.) dolayı sözleşmeden doğan yükümlülükler taraflarca ifa edilemez hale gelirse, taraflar bundan sorumlu değildir. Bu sürede tarafların işbu sözleşmeden doğan hak ve yükümlülükleri askıya alınır.</p>

  <h2>7. Sözleşmenin Bütünlüğü ve Uygulanabilirlik </h2>
    <p> İşbu sözleşme şartlarından biri, kısmen veya tamamen geçersiz hale gelirse, sözleşmenin geri kalanı geçerliliğini korumaya devam eder.</p>

  <h2>8. Sözleşmede Yapılacak Değişiklikler </h2>
    <p> Firma, dilediği zaman sitede sunulan hizmetleri ve işbu sözleşme şartlarını kısmen veya tamamen değiştirebilir. Değişiklikler sitede yayınlandığı tarihten itibaren geçerli olacaktır. Değişiklikleri takip etmek kullanıcının sorumluluğundadır. Kullanıcı, sunulan hizmetlerden yararlanmaya devam ederek bu değişiklikleri de kabul etmiş sayılır.</p>

  <h2>9. Tebligat </h2>
    <p> İşbu sözleşme ile ilgili taraflara gönderilecek olan tüm bildirimler, firmanın bilinen e-posta adresi ve kullanıcının üyelik formunda belirttiği e-posta adresi vasıtasıyla yapılacaktır. Kullanıcı, üye olurken belirttiği adresin geçerli tebligat adresi olduğunu, değişmesi durumunda 5 gün içinde yazılı olarak diğer tarafa bildireceğini, aksi halde bu adrese yapılacak tebligatların geçerli sayılacağını kabul eder.</p>
  
    <h2>10. Delil Sözleşmesi </h2>
    <p> Taraflar arasında işbu sözleşme ile ilgili işlemler için çıkabilecek her türlü uyuşmazlıklarda tarafların defter, kayıt ve belgeleri ile bilgisayar kayıtları ve faks kayıtları 6100 sayılı Hukuk Muhakemeleri Kanunu uyarınca delil olarak kabul edilecek olup, kullanıcı bu kayıtlara itiraz etmeyeceğini kabul eder.</p>

  <h2>11. Uyuşmazlıkların Çözümü </h2>
    <p> İşbu sözleşmenin uygulanmasından veya yorumlanmasından doğacak her türlü uyuşmazlığın çözümünde Bursa (Merkez) Adliyesi Mahkemeleri ve İcra Daireleri yetkilidir.</p>

  <h2>12. Ücretlendirme </h2>
    <p> Sisteme ilk kayıt olduğunuzda, test edebilmeniz için Deneme Paketi üzerinden hesabınıza paket tanımlanır. Deneme Paketinin ve bu süreçte oluşturacağınız dijital kartvizitlerin geçerlilik süresi 5 gündür ve .png uzantılı çıktı alınabilmektedir. QR Kod oluştururken tipini final sayfasında size sunulan statik/dinamik seçeneğinden belirleyebilirsiniz. Qr Kod tip ve tür seçimi kullanıcının sorumluluğundadır. Vektörel çıktılar sadece kurumsal paketlerimiz için geçerlidir. Qrcodematic Deneme Paket sürelerinin değişikliklerini saklı tutar. Dilediği zaman değiştirme hakkına sahiptir. 5 günün sonunda ücretli paketlerden birini seçerek oluşturduğunuz QR Kodları kullanmaya devam edebilirsiniz. Sürenin bitiminde eğer paketinizi yükseltmediyseniz oluşturduğunuz QR Kodlar silinir. QR kodlarınızın kaybolmasını, silinmesini veya pasif olmasını istemiyorsanız mutlaka bir ay öncesinden paket yenilemenizi yaptırmalısınız. Yenilenmemesi durumunda oluşturduğunuz kodlar silinecektir ve bu konuda Qrcodematic herhangi bir mesuliyet kabul etmeyecektir. Unutmayınız, QR kodlarınız ve dijital kartvizitleriniz paketiniz devam ettiği sürece aktif kalmaktadır.</p>

  <h2>13. Hesap Silme </h2>
    <p> Hesabınızın silme işlemini kullanıcı girişi yaparak üst menüde yer alan Hesabım sekmesinden Hesabımı Sil alanındaki seçeneği Evet olarak işaretleyip Kaydet diyerek gerçekleştirebilirsiniz veya hesabınızın silinme talebini, kayıt olduğunuz ve silinecek mail adresi ile info@qrcodematic.com a sebebiyle birlikte göndererek tarafımızdan işleme alınmasını isteyebilirsiniz. Unutmamanız gereken konu hesabınız silindiğinde oluşturduğunuz tüm içerik ve QR Kod lar geri döndürülemeyecek şekilde kaybolacaktır. Hesap silme işlemini onaylayarak bu gibi durumların hepsini kabul etmiş olursunuz.
Oluşturduğunuz QR Kodları baskıya göndermeden önce bir kaç cihazdan test ederek çalışır olduğuna emin olunuz. Test etmeden baskı işlemini gerçekleştirmeyiniz. Siteye giriş yaparak, hesap oluşturarak tüm bu şartları okumuş, anlamış ve kabul etmiş sayılırsınız.</p>
   
  `,
  },
  CROPPER: {
    confirm_selected_area: "Resmi Uygula",
    confirm_selection_tooltip: "Seçili alanı onaylar",
    cropper_success: "Başarı ile Uygulanmıştır",
  },
  STATISTICS: {
    show_all: "Tümünü Göster",
    last_x_days: ({ named }) => `Son ${named("days")} gün`,
    trial_warning:
      "<strong>DEMO: </strong> Aşağıdaki istatistikler <strong>Deneme Hesabında</strong> örnek olarak gösterilmektedir.",
  },
  HELP: {},
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Gönder",
      NO_ACCOUNT: "Hesabınız Yok?",
      SIGNUP_BUTTON: "Kayıt Ol",
      FORGOT_BUTTON: "Parolanızı mı unuttunuz",
      BACK_BUTTON: "Geri",
      PRIVACY: "Gizlilik",
      LEGAL: "Yasal",
      CONTACT: "İletişim",
    },
    LOGIN: {
      TITLE: "Giriş hesabı",
      BUTTON: "Kayıt Ol",
    },
    FORGOT: {
      TITLE: "Parolanızı mı unuttunuz?",
      DESC: "Parolanızı sıfırlamak için e-postanızı girin",
      SUCCESS: "Hesabınız başarıyla sıfırlandı.",
    },
    REGISTER: {
      TITLE: "Üye Ol",
      DESC: "Hesabınızı oluşturmak için bilgilerinizi girin",
      SUCCESS: "Hesabınız başarıyla kaydedildi.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Ad Soyad",
      PASSWORD: "Şifre",
      CONFIRM_PASSWORD: "Şifre Tekrarı",
      USERNAME: "Kullanıcı Adı",
    },
    VALIDATION: {
      INVALID: "{{name}} geçerli değil",
      REQUIRED: "{{name}} gerekir",
      MIN_LENGTH: "{{name}} minimum uzunluk {{min}}",
      AGREEMENT_REQUIRED: "Kabul edilen şartlar ve koşullar gereklidir",
      NOT_FOUND: "İstenen {{name}} bulunamadı",
      INVALID_LOGIN: "Giriş bilgileri yanlış",
      REQUIRED_FIELD: "Gerekli alan",
      MIN_LENGTH_FIELD: "Minimum alan uzunluğu:",
      MAX_LENGTH_FIELD: "Maximum alan uzunluğu:",
      INVALID_FIELD: "Alan geçerli değil",
    },
  },
  ICONSELECT: {
    animals: "hayvan",
    landscape: "manzara",
    people: "insan",
    vehicles: "araç",
    communication: "iletişim",
    technology: "teknoloji",
    business: "iKatalog / Menü",
    food: "yiyecek",
  },
};
