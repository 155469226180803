import axios from "axios";
import jwtService from "./jwt.service";

export default () => {
  axios.defaults.headers = {
    // "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${jwtService.getToken()}`,
  };
  return axios.create({
    baseURL: process.env.API_BASE_URL,
  });
};
