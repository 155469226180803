import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import content from "./products/content.module";
import breadcrumbs from "./breadcrumbs.module";
import snackbar from "./snackbar.module";
import admin from "./admin.module";
import qrcode from "./qrcode.module";
import menu from "./menu.module";
import log from "./log.module";
import userModule from "./user.module";
import device from "./device.module";
import util from "./util.module";
import cart from "./cart.module";
import payment from "./payment.module";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  namespaced: true,
  modules: {
    auth,
    htmlClass,
    config,
    content,
    breadcrumbs,
    snackbar,
    admin,
    qrcode,
    menu,
    log,
    userModule,
    device,
    cart,
    payment,
    util,
  },
  state: {
    search: "",
  },
  mutations: {
    SET_SEARCH(state, payload) {
      state.search = payload;
    },
  },
  actions: {
    SET_SEARCH({ commit }, payload) {
      commit("SET_SEARCH", payload);
    },
  },
});
