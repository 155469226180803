import QrcodeService from "@/core/services/qrcode.service";

/*================
   ACTIONS START
==================*/
export const FETCH_BODIES = "content/fetchBodies";
export const FETCH_EYEBALLS = "content/fetchEyeBalls";
export const FETCH_EYEFRAMES = "content/fetchEyeFrames";
export const FETCH_TEMPLATES = "content/fetchTemplates";
export const FETCH_LOGOS = "content/fetchLogos";
export const DEL_FOLDER = "content/delFolder";

/*===================
   MUTATIONS START
====================*/

export const SET_BODY = "content/setBody";
export const SET_EYEBALL = "content/setEyeball";
export const SET_EYEFRAME = "content/setEyeFrame";
export const SET_TEMPLATES = "content/setTemplates";
export const SET_LOGOS = "content/setLogos";
export const PURGE_DATA = "content/purgeData";

const state = {
  eyeball: [],
  eyeframe: [],
  body: [],
  logo: [],
  templates: [],
};

const getters = {
  PRODUCTBody(state) {
    return state.body;
  },
  PRODUCTEyeframe(state) {
    return state.eyeframe;
  },
  PRODUCTEyeball(state) {
    return state.eyeball;
  },
  PRODUCTLogo(state) {
    return state.logo;
  },
  PRODUCTTemplates(state) {
    return state.templates;
  },
};

const actions = {
  async [FETCH_BODIES](context) {
    try {
      const response = await QrcodeService.fetchBody();
      context.commit(SET_BODY, response.data);
      context.dispatch(
        "sendSnackbar",
        {
          message: "Body klasörü fetch edildi!",
          color: "green",
        },
        { root: true }
      );
    } catch (err) {}
  },
  async [FETCH_EYEBALLS](context) {
    try {
      const response = await QrcodeService.fetchEyeball();
      context.commit(SET_EYEBALL, response.data);
      context.dispatch(
        "sendSnackbar",
        {
          message: "Eyeball klasörü fetch edildi!",
          color: "green",
        },
        { root: true }
      );
    } catch (err) {}
  },
  async [FETCH_EYEFRAMES](context) {
    try {
      const response = await QrcodeService.fetchEyeframe();
      context.commit(SET_EYEFRAME, response.data);
      context.dispatch(
        "sendSnackbar",
        {
          message: "Eyeframe klasörü fetch edildi!",
          color: "green",
        },
        { root: true }
      );
    } catch (err) {}
  },
  async [FETCH_TEMPLATES](context) {
    try {
      const response = (await QrcodeService.fetchTemplates()).data.data;

      context.commit(SET_TEMPLATES, response);
      return response;
    } catch (err) {
      console.error(err);
    }
  },
  async [FETCH_LOGOS](context, type) {
    try {
      const response = (await QrcodeService.fetchLogos(type)).data.data;

      context.commit(SET_LOGOS, response);
      return response;
    } catch (err) {
      console.error(err.response);
    }
  },
  async [DEL_FOLDER](context, payload) {
    try {
      const response = await QrcodeService.deleteFolder(payload);
    } catch (err) {}
  },
};

const mutations = {
  [SET_BODY](state, payload) {
    state.body = payload;
  },
  [SET_EYEBALL](state, payload) {
    state.eyeball = payload;
  },
  [SET_EYEFRAME](state, payload) {
    state.eyeframe = payload;
  },
  [SET_TEMPLATES](state, payload) {
    state.templates = payload;
  },
  [SET_LOGOS](state, payload) {
    state.logo = payload;
  },
  [PURGE_DATA](state, payload) {
    if (payload == "all") {
      state.eyeball = [];
      state.eyeframe = [];
      state.body = [];
      return;
    }

    state[payload] = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
