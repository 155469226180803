import Api from "./Api";
import JwtService from "@/core/services/jwt.service";

export const token = JwtService.getToken();

export default {
  fetchUsers(page) {
    return Api().get("api/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: page,
      },
    });
  },
  fetchUser(id) {
    return Api().get(`api/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  addToFolder(folderId, list) {
    let fd = new FormData();

    list.forEach((e) => {
      fd.append("qrcodes[]", String(e));
    });

    return Api().post(`api/folder/${folderId}/sync`, fd, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  addToQrcodeFolders(folderId, qrId) {
    let fd = new FormData();
    fd.append("folders[]", String(folderId));

    return Api().post(`api/qrcode/${qrId}/folders`, fd, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  /**
   * @param {number} userId
   * @param {string} domain
   * @returns {unknown}
   */
  ADD_NEW_DOMAIN(domain, userId) {
    return Api().post(`api/domains/userDomainCreate/${userId}`, {
      domain: domain,
    });
  },
  /**
   * @param {number} userId
   * @param {number} domainId
   * @returns {unknown}
   */
  VERIFY_DOMAIN(userId, domainId) {
    return Api().post(`api/domains/userDomainVerify/${userId}`, {
      domain_id: domainId,
    });
  },

  /**
   * @param {number} userId
   * @returns {[{id: number, domain: string, user_id: number, verified: number, default: 0}] }
   */
  GET_USER_DOMAINS(userId) {
    return Api().get(`api/domains/userDomainList/${userId}`);
  },

  /**
   * @param {number} userId
   * @param {number} domainId
   * @returns {unknown}
   */
  DELETE_USER_DOMAIN(domainId) {
    return Api().delete(`api/domains/userDomainDelete/${domainId}`);
  },

  /**
   * Domain'i default yapmak için
   * @param {number} domainId
   * @returns 'OK'
   */
  UPDATE_USER_DOMAIN(domainId) {
    return Api().put(`api/domains/userDomainUpdate/${domainId}`);
  },

  /**
   * Diğer tüm domaninleri default: 0 yapıyor.
   */
  MAKE_CC_DOMAIN_DEFAULT() {
    return Api().put(`api/domains/userUnsetDomainsUpdate`);
  },
};
